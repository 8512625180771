import React from "react";
import './Videos.scss'
import { useNavigate  } from 'react-router-dom';
import { PlayCircleOutlined } from '@ant-design/icons'

const dataList = [
  {
    img: require('../image/videos-1.jpg'),
    src: 'https://www.youtube.com/embed/XxaWAWqjrRQ?list=PLr3zZd8LK9BLVjHeQnuf0JzIr-1aSY3Wi',
    title: 'The Path to Carbon Neutrality | MVGX Educates',
    type: '1'
  },
  {
    img: require('../image/videos-2.jpg'),
    src: 'https://www.youtube.com/embed/PcROHBlrMbg',
    title: 'What is a Carbon Neutrality Token? | MVGX Educates',
    type: '2'
  },
  {
    img: require('../image/videos-3.jpg'),
    src: 'https://www.youtube.com/embed/ioAEe-QREvQ',
    title: 'The Problems With The Voluntary Carbon Market | MVGX Educates',
    type: '3'
  },
]

function Videos() {
  const navigate = useNavigate();
  const toDetail = (data) => {
    navigate('/videos-detail', {state: data})
  }
  return (
    <div className='videos'>
      <h1>MVGX Educates</h1>
      <div className='flex-box'>
        {dataList.map((item, index) => (
          <div className='list-cell' key={index} onClick={() => toDetail(item)}>
            <div>
              <img src={item.img} alt="videos"/>
              <PlayCircleOutlined className='icon' style={{color: '#fff',fontSize: '30px', position: "absolute", bottom: '20px', right: '20px' }}/>
            </div>
            <h2>{item.title}</h2>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Videos;