import { ArrowRightOutlined } from '@ant-design/icons'
import { Carousel } from 'antd';
import React from "react";
import { useDispatch, } from 'react-redux';
import './Book1.scss'

const middleData = [
  {
    icon: require('../image/Group1.png'),
    title: 'Tech',
    path: 'tech',
    text: 'We develop trusted carbon software solutions to enable corporations, governments, ' +
      'and institutions to take action at every stage of their decarbonisation journeys. From carbon measurement,' +
      ' reporting and verification to carbon credit development and issuance, our proprietary technology ensures the integrity of decarbonisations efforts. '
  },
  {
    icon: require('../image/Group2.png'),
    title: 'Exchange',
    path: 'exchange',
    text: 'Our digital green asset marketplace is the first exchange to mandate carbon disclosure for both issuers' +
      ' and investors through its NASDAQ-powered trading platform. Regulated by the Monetary Authority of Singapore,' +
      ' MVGX Exchange provides a licenced, transparent and efficient platform for asset owners and investors. '
  },
  {
    icon: require('../image/Group3.png'),
    title: 'Securities',
    path: 'securities',
    text: 'MVGX’s licensed brokerage service allows clients to trade traditional financial ' +
      'securities as well as innovative green assets such as carbon credits and green asset backed securities, all from the same platform. '
  },
  {
    icon: require('../image/Group4.png'),
    title: 'Funds',
    path: 'http://www.asiagreenfund.com/html/en_index/',
    text: 'MVGX works to drive green impact investing throughout the region through its affiliated Asia Green Fund (AGF),' +
      ' a venture capital and private equity fund. With approximately USD 2.5 billion assets under management, ' +
      'AGF focuses on equity investment driven by green and digital tech, such as Clean Energy, Green Advanced Manufacturing,' +
      ' Green Low-carbon Transportation, and Energy Conservation.'
  },
  {
    icon: require('../image/Group5.png'),
    title: 'Knowledge',
    path: 'finanal',
    text: 'Our advanced decarbonisation knowledge center takes a leading role in advancing the understanding and promotion of ' +
      'sustainable practices and emissions reduction strategies. It provides a structured, transparent, and accessible space where' +
      ' valuable insights, innovative solutions, and sustainable wisdom is readily shared, facilitating collective progress towards a greener, more sustainable future.'
  }
]
function MiddleCell ({data}) {
  const dispatch = useDispatch();

  const routeTo = (data) => {
    let path = data.path;
    if (path.indexOf('http') > -1) {
      window.open(path, "_blank")
    } else {
      dispatch({type: 'INCREMENT', payload: path})
    }
  }
  return (
    <div className='middle-cell'>
      <div className='cell-title'>{data.title}</div>
      <div className='cell-content'>
        <div className='img-wrapper'>
          <img src={data.icon} alt="icon"/>
        </div>
        <div className='text-wrapper'>
          <p>{data.text}</p>
          <p onClick={() =>routeTo(data)}>Lean more <ArrowRightOutlined style={{marginLeft: '5px'}}/></p>
        </div>
      </div>
    </div>
  )
}

function Book () {
  return (
    <div className='book1-wrapper'>
      <div className='book-wrapper1'>
        <div className='top'>
          <div className='top-left'>
            <p>
              MVGX: Most Valuable Green eXpert for your Decarbonisation Journey
            </p>
          </div>
        </div>

        <div className='middle'>
          {middleData.map((item,index) => (
            <MiddleCell key={index} data={item}/>
          ))}
        </div>

        <div className='news-card' style={{display: 'none'}}>
          <h2>news</h2>
          <Carousel className='carousel' dots={false} dotPosition='left' autoplay>
            <div>
              <div className='card-cell'>
                <p><img src={require('../image/Vector@2x(3).png')} alt=""/>  <span className='news-name'>In City Chongqing Opens with over 200 Brands and Throng of S In City Chongqing Opens with over 200 Brands and Throng of Shoppers</span> <ArrowRightOutlined/></p>
                <p><img src={require('../image/Vector@2x(3).png')} alt=""/>  <span className='news-name'>In City Chongqing Opens with over 200 Brands and Throng of S In City Chongqing Opens with over 200 Brands and Throng of Shoppers</span> <ArrowRightOutlined/></p>
              </div>
            </div>
            <div>
              <div className='card-cell'>
                <p><img src={require('../image/Vector@2x(3).png')} alt=""/>  <span className='news-name'>In City Chongqing Opens with over 200 Brands and Throng of S In City Chongqing Opens with over 200 Brands and Throng of Shoppers</span> <ArrowRightOutlined/></p>
                <p><img src={require('../image/Vector@2x(3).png')} alt=""/>  <span className='news-name'>In City Chongqing Opens with over 200 Brands and Throng of S In City Chongqing Opens with over 200 Brands and Throng of Shoppers</span> <ArrowRightOutlined/></p>
              </div>
            </div>
            <div>
              <div className='card-cell'>
                <p><img src={require('../image/Vector@2x(3).png')} alt=""/>  <span className='news-name'>In City Chongqing Opens with over 200 Brands and Throng of S In City Chongqing Opens with over 200 Brands and Throng of Shoppers</span> <ArrowRightOutlined/></p>
                <p><img src={require('../image/Vector@2x(3).png')} alt=""/>  <span className='news-name'>In City Chongqing Opens with over 200 Brands and Throng of S In City Chongqing Opens with over 200 Brands and Throng of Shoppers</span> <ArrowRightOutlined/></p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Book;