import React from "react";
import {ArrowLeftOutlined} from "@ant-design/icons";
import { useNavigate  } from 'react-router-dom';

function Back() {
  const navigate = useNavigate();
  return (
    <button onClick={() => navigate(-1)} style={{fontSize: '16px',paddingBottom: '20px'}}>
      <ArrowLeftOutlined style={{marginRight: '8px'}}/>
      Back
    </button>
  )
}

export default Back;