import React, { useCallback,useState } from "react";
import './Knowledge.scss'
import { Pagination,ConfigProvider } from 'antd';

let leadershipData = [
  {
    title: 'Comment: To fix voluntary carbon markets we need a new infrastructure that works for all countries',
    url: 'https://www.reuters.com/sustainability/sustainable-finance-reporting/comment-fix-voluntary-carbon-markets-we-need-new-infrastructure-that-works-all-2023-06-19'
  }, {
    title: 'Michael Sheren, President and Chief Strategy Officer at MVGX on impact investing regulation ',
    url: 'https://community.ionanalytics.com/michael-sheren-president-and-chief-strategy-officer-at-mvgx-on-impact-investing-regulation'
  }, {
    title: 'Ensuring that sustainability efforts actually bear fruit',
    url: 'https://www.businesstimes.com.sg/opinion-features/columns/ensuring-sustainability-efforts-actually-bear-fruit-0'
  }, {
    title: 'Why blockchain is the cornerstone of investing in our planet ',
    url: 'https://forkast.news/why-blockchain-cornerstone-investing-planet'
  }, {
    title: 'Are critics missing the mark in blockchain\'s sustainability proposition?',
    url: 'https://www.theedgesingapore.com/digitaledge/focus/are-critics-missing-mark-blockchains-sustainability-proposition'
  }, {
    title: 'Singapore must become the carbon neutralizer for Asia ',
    url: 'https://asiatimes.com/2022/04/singapore-must-become-the-carbon-neutralizer-for-asia/'
  }, {
    title: 'What Singapore’s green ambitions could mean for the world ',
    url: 'https://unravel.ink/what-singapores-green-ambitions-could-mean-for-the-world/'
  }, {
    title: 'Money and Me: Using blockchain to establish cross-border carbon trading ',
    url: 'https://omny.fm/shows/moneyfm-midday-show/money-and-me-using-blockchain-to-establish-cross-b#.Ypb2TK2hbVQ.link'
  }, {
    title: 'How carbon in the metaverse can help solve the real-world climate crisis ',
    url: 'https://e27.co/how-carbon-in-the-metaverse-can-help-solve-the-real-world-climate-crisis-20220531/\n'
  }, {
    title: 'Transparency is needed in voluntary carbon markets in its pursuit of net-zero achievement ',
    url: 'https://omny.fm/shows/moneyfm-evening-show/transparency-is-needed-in-voluntary-carbon-markets\n'
  }, {
    title: 'Emerging Asia at risk from EU’s protectionist carbon border tax ',
    url: 'https://www.scmp.com/comment/opinion/article/3183711/emerging-asia-risk-eus-protectionist-carbon-border-tax?module=perpetual_scroll_0&pgtype=article&campaign=3183711'
  }, {
    title: 'Commodity AND Security ',
    url: 'https://www.esginvestor.net/86463-2/\n'
  }, {
    title: 'How will Indonesia balance economic growth with its green transition? ',
    url: 'https://www.thejakartapost.com/opinion/2022/11/08/how-will-indonesia-balance-economic-growth-with-its-green-transition.html'
  },
]

let reportData = [
  {
    title: 'China Carbon Market Weekly Update – 6 November 2023',
    url: require('./file/China Carbon Weekly 20231106_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 30 October 2023',
    url: require('./file/China Carbon Weekly 20231030_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 23 October 2023',
    url: require('./file/China Carbon Weekly 20231023_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 16 October 2023',
    url: require('./file/China Carbon Weekly 20231016_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 2 October 2023',
    url: require('./file/China Carbon Weekly 20231002_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 25 September 2023',
    url: require('./file/China Carbon Weekly 20230925_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 18 September 2023',
    url: require('./file/China Carbon Weekly 20230918_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 11 September 2023',
    url: require('./file/China Carbon Weekly 20230911_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 4 September 2023',
    url: require('./file/China Carbon Weekly 20230904_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 28 August 2023',
    url: require('./file/China Carbon Weekly 20230828_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 21 August 2023',
    url: require('./file/China Carbon Weekly 20230821_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 14 August 2023',
    url: require('./file/China Carbon Weekly 20230814_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 7 August 2023',
    url: require('./file/China Carbon Weekly 20230807_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 31 July 2023',
    url: require('./file/China Carbon Weekly 20230731_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 24 July 2023',
    url: require('./file/China Carbon Weekly 20230724_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 17 July 2023',
    url: require('./file/China Carbon Weekly 20230717_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 7 July 2023',
    url: require('./file/China Carbon Weekly 20230710_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 3 July 2023',
    url: require('./file/China Carbon Weekly 20230703_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 26 June 2023',
    url: require('./file/China Carbon Weekly 20230626_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 19 June 2023',
    url: require('./file/China Carbon Weekly 20230619_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 12 June 2023',
    url: require('./file/China Carbon Weekly 20230612_final.pdf')
  },{
    title: 'China Carbon Market Weekly Update – 5 June 2023',
    url: require('./file/China Carbon Weekly 20230605_final.pdf')
  },
]

let whiteData = [
  {
    title: 'Carbon Footprint of the Ether after the Ethereum Network Proof-of-Stake Merge',
    url: require('./file/Carbon Footprint of the Ether after the Ethereum Network Proof-of-Stake Merge.pdf')
  },{
    title: 'Methodology of Calculating Carbon Footprint of One Ether',
    url: require('./file/Methodology of Calculating Carbon Footprint of One Ether.pdf')
  },{
    title: 'Methodology of Calculating the Carbon Footprint of One Bitcoin – April 2021',
    url: require('./file/Methodology of Calculating the Carbon Footprint of One Bitcoin – April 2021.pdf')
  },{
    title: 'PAS 2060 Carbon Neutrality Report 2021',
    url: require('./file/PAS 2060 Carbon Neutrality Report 2021.pdf')
  },
]

/*function CellLeadership({data,index}) {
  return (
    <div>
      <div className={'link'}>
        <span className="link-number">{index >= 10 ? index: '0'+index}</span>
        <span onClick={() => window.open(data.url, '_blank')}>{data.title}</span>
      </div>
    </div>
  )
}*/

function CellReport({data, index}) {
  return (
    <div>
      <div className={'link'}>
        <span className="link-number">
          {/*{index >= 10 ? index: '0'+index}*/}
          {data.index}
          </span>
        <span onClick={() => window.open(data.url, '_blank')}>{data.title}</span>
      </div>
    </div>
  )
}
const menuList = [
  {
    title:"Thought Leadership",
    iconUrl:require('../image/knowledge/ThoughtLeadership.png'),
    type:'ThoughtLeadership',
  },
  {
    title:"China Carbon Reports",
    iconUrl:require('../image/knowledge/ChinaCarbonReports.png'),
    type:'ChinaCarbonReports'
  },
  {
    title:"White Papers",
    iconUrl:require('../image/knowledge/WhitePapers.png'),
    type:'WhitePapers'
  },
]
//let currentType = menuList[0].type
/*function ListContainer ({currentType}) {
  if(currentType === 'ThoughtLeadership'){
    return (
      <div>
        {
          leadershipData.map((item, index) => (
            <CellLeadership data={item} key={index} index={index+1} />
          ))
        }
      </div>
    )
  } else if(currentType === 'ChinaCarbonReports'){
    return (
  //{/!*<h1>China Carbon Reports</h1>*!/}
      <div>
        {
          reportData.map((item, index) => (
            <CellReport data={item} key={index} index={index+1} />
          ))
        }
      </div>
    )
  } else if(currentType === 'WhitePapers') {
    return (
    //{/!*<h1>White Papers</h1>*!/}
      <div>
        {
          whiteData.map((item, index) => (
            <CellReport data={item} key={index} index={index+1} />
          ))
        }
      </div>
    )
  } else {
    return null
  }
}*/
/**
 * 给数据加上index
 * @param {*} data 
 * @returns 
 */
function addIndex(data) {
  data.forEach((item, key) => {
      item.index = key >= 9 ? (key+1) : '0'+(key+1)
    })
    return data
}
function Knowledge() {
  
  let [currentType,setCurrentType] = useState(menuList[0].type)
  let [pageCurrent, setPageCurrent] = useState(1)
  let [pageTotal, setPageTotal] = useState(leadershipData.length)
  leadershipData = addIndex(leadershipData)
  reportData = addIndex(reportData)
  whiteData = addIndex(whiteData)
  function menuClick (type){
    setCurrentType(type)
    let list = leadershipData
    if(type === 'ThoughtLeadership'){
      list = leadershipData
    } else if(type === 'ChinaCarbonReports') {
      list = reportData
    } else if(type === 'WhitePapers'){
      list = whiteData
    }
    setPageCurrent(1)
    setPageTotal(list.length)
  }
  const setMenuClass = useCallback((type) => {
    return 'menu-item ' + (type === currentType ? 'current' :'')
  },[currentType])
  const currentList = useCallback(() => {
    let list = []
    let type=currentType
    if(type === 'ThoughtLeadership'){
      list = leadershipData
    } else if(type === 'ChinaCarbonReports') {
      list = reportData
    } else if(type === 'WhitePapers'){
      list = whiteData
    }
    return list.filter((item,key) => {
      return key >= ((pageCurrent - 1) *10) && key < (10 * pageCurrent)
    })
  },[pageCurrent,currentType])
  function pageChange (page){
    window.scrollTo(0,0)
    setPageCurrent(page)
  }
  return (
    <div className={'knowledge'}> 
      <div className="knowledge-header">
        <div className="menu-list">
          {
            menuList.map((item,index) => (
              <div onClick={() => menuClick(item.type)} className={setMenuClass(item.type)} key={index}>
                <div className="icon-box">
                    <img src={item.iconUrl} alt="name"></img>
                </div>
                <div className="title">{item.title}</div>
              </div>
            ))
          }            
        </div>
      </div>
      <div className="list-container">
        {/*<ListContainer currentType={currentType} />*/}
        <div>
          {
            currentList().map((item, index) => (
              <CellReport data={item} key={index} number={item.index} />
            ))
          }
        </div>
        <ConfigProvider
        theme={{
          components: {
            Pagination: {
              colorPrimary: 'rgba(2, 66, 50, 0.8)',
              algorithm: true, // 启用算法
            },
          },
        }}>
          <div className="page-footer">
            <Pagination defaultCurrent={pageCurrent} current={pageCurrent} total={pageTotal} onChange={pageChange} />
          </div>
      </ConfigProvider>
        </div>
    </div>
  )
}

export default Knowledge;