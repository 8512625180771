import React, { useEffect, useRef, useState } from 'react';
import './Foryou.scss'
import { Col, Row } from 'antd';

const flowList = [
  {
    class: 'first',
    title: 'Carbon Management System',
    text: 'The Carbon Footprint Computing Module is a software solution that efficiently calculates the carbon footprint of organizations and' +
      ' products based on ISO standards, provides data-driven insights for governments and organizations while facilitating the calculation of carbon emissions. ',
    icon: ''
  },
  {
    class: 'second',
    title: 'Carbon Rating System',
    text: 'The Decarbonisation Rating Module offers a ' +
      'comprehensive assessment of organisation’s decarbonisation practices. Jointly developed ' +
      'with a global certification body, TÜV SÜD, this assessment tool incorporates key provisions ' +
      'from ISO standards such as ISO 14064, ISO 14067, ISO 50001, and ISO 14044. It empowers organizations to ' +
      'measure and improve their decarbonisation performance, social responsibility, and economic viability, ' +
      'demonstrating a commitment to decarbonisation and responsible business practices. ',
    icon: '',
  },
  {
    class: 'third',
    title: 'Carbon Credit Development System',
    text: 'Carbon credit development system, this module manages the project that’s intended to create carbon credits.' +
      ' Project initation, project progression etc – monitoring an entire project. Purpose of those projects are to create carbon credits.',
    icon: ''
  },
  {
    class: 'four',
    title: 'Carbon Credit Registry System',
    text: '<div class="litte-title">Carbon Credit Development System includes:</div>' +
      '<div class="litte-title litte-title-se">a. Carbon Project Development</div> With its intuitive design and robust operational functionality, ' +
      'the Carbon Project Development module is an interactive tool providing carbon asset owners and project developers an' +
      ' end-to-end certifiable pathway taking the carbon assets and carbon emissions reduction initiatives and turning them' +
      ' into high-integrity carbon credits, based on internationally recognised carbon mitigation and crediting standards, that are globally accepted and tradable.'
      + '<div class="litte-title litte-title-se">b. Carbon Credit Issuance</div>The Carbon Credit Issuance module is a proprietary tool for carbon asset owners and project developers to efficiently ' +
      'manage the issuance of carbon credits to buyers such as large corporations and other entities seeking high-quality carbon credits ' +
      'to offset their hard to abate carbon emissions. Using advanced blockchain technology and our proprietary Non-Fungible Digital ' +
      'Twin (NFDT®) technology to record all activities, the entire process is rigorous, consistent, and transparent, effectively eliminating the ' +
      'risk of double counting plaquing carbon markets worldwide.',
    icon: ''
  },
]

function Box({data}) {
  const targetRef = useRef(null);
  const [distanceToBottom, setDistanceToBottom] = useState(null);
  const [moveClass, setMoveClass] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current) {
        const rect = targetRef.current.getBoundingClientRect();
        const distance = window.innerHeight - rect.bottom + rect.height;
        setDistanceToBottom(distance)
        if (distance > -10) {
          setMoveClass('cell-move');
        } else {
          setMoveClass('');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const name = `${data.class} ${moveClass} move-wrapper-for`
  let content;

  content = <div>
    <Row>
      <Col span={24} >
        <div className='item-title'>{data.title}</div>
      </Col>
      <Col span={24} >
        <div className='item-content' dangerouslySetInnerHTML={{ __html: data.text }} />
      </Col>
    </Row>
  </div>

  return (
    <div className='item' ref={targetRef} data-dis={distanceToBottom}>
      <div className={name}>
        {content}
      </div>
      <div className='placeholder-div'/>
    </div>
  )
}

function Foryou() {
  const customStyles = {
    padding: '12px 0',
  };
  const curBodyWidth = document.body.clientWidth;
  const [curWidth, setCurWidth] = useState(curBodyWidth);
  useEffect(() => {
    // 定义窗口大小改变时的处理函数
    const handleResize = () => {
      setCurWidth(document.body.clientWidth)
      // 在这里执行你想要的操作
    };
    // 添加窗口resize事件监听器
    window.addEventListener('resize', handleResize);

    // 在组件销毁时移除监听器
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // 依赖数组为空，表示仅在挂载和卸载时运行
  let content;
  if (curWidth >= 820) {
    content =
      <div>
        <Row>
          <Col span={10} className='placeholder-div'>
            <Box data={flowList[0]} />
          </Col>
          <Col span={14} className='white-color'>
            <Box data={flowList[1]} />
          </Col>
        </Row>
        <div style={customStyles} />
        <Row>
          <Col span={6}>
            <Box data={flowList[2]} />
          </Col>
          <Col span={18} className='placeholder-div-right'>
            <Box data={flowList[3]} />
          </Col>
        </Row>
      </div>
  } else {
    content =
      <div>
        <Row>
          <Col span={24}>
            <Box data={flowList[0]} curWidth={curWidth} />
          </Col>
        </Row>
        <div style={customStyles} className='white-color' />
        <Row>
          <Col span={24}>
            <Box data={flowList[1]} curWidth={curWidth} />
          </Col>
        </Row>
        <div style={customStyles} />
        <Row>
          <Col span={24}>
            <Box data={flowList[2]} curWidth={curWidth} />
          </Col>
        </Row>
        <div style={customStyles} />
        <Row>
          <Col span={24}>
            <Box data={flowList[3]} curWidth={curWidth} />
          </Col>
        </Row>
      </div>
  }
  return (
    <div className='foryou'>
      <div className='content'>
        <h1 className='main-title'>What We Can Do For You？</h1>
        {content}
      </div>
    </div>
  );
}

export default Foryou;