import {ExclamationCircleOutlined} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import ReactEchartsCore  from 'echarts-for-react';
import * as echarts from 'echarts';
import './Mmn.scss'
function Mmn() {
  const [open, setOpen] = useState(false);
  const chartRef = useRef(null);
  let list = [
    { value: 0, name: 'Interest Bearing Account (Banks)' },
    { value: 100, name: 'Interest Bearing Account (Security Houses)' },
    { value: 0, name: 'Money Market Funds' },
    { value: 0, name: 'US Treasury Products' }
  ]
  // ECharts 配置选项
  const [curWidth, setCurWidth] = useState(window.innerWidth > 980 ? 350 : window.innerWidth + 'px');
  const [curRadius, setCurRadius] = useState(['40%', '70%']);
  const option = {
    title: {
      text: 'Underlying Portfolio Composition',
      subtext: '',
      left: 'center',
      top: '10%',
    },
    tooltip: {
      trigger: 'item',
      show: false,
    },
    legend: {
      orient: 'vertical',
      bottom: '15%',
      icon: 'circle',
      itemHeight: 10,
      itemWidth: 10,
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        // radius: ['30%', '60%'],
        radius: curRadius,
        center: ['50%', '43%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
          normal: {
            color: function (colors) {
              let colorList = [
                '#00bba6',
                '#4d81f0',
                '#8a71e4',
                '#f6c472',
              ];
              return colorList[colors.dataIndex];
            }
          }
        },
        label: {
          show: false,
          position: 'center',
          normal: {
            textStyle: {
              fontSize: 12, // 改变标示文字的大小
              color: "#00142A",
            },
            formatter: "{d}%",
            position: "center",//控制文字显示的位置，center居中显示
            show: false,//由于要单独显示（已使用）的数值，所以得先隐藏label
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: list
      }
    ]
  };
  const toMce = () => {
    // window.open('https://www.mce.sg', '_blank')
    setOpen(true)
  }
  useEffect(() => {
    const handleResize = () => {
      setCurWidth(window.innerWidth > 980 ? 350 : window.innerWidth + 'px');
      chartRef.current.getEchartsInstance().resize();
      setCurRadius(['35', '36%'])
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function mailTo() {
    const email = 'contact_us@mce.sg';

    window.location.href = `mailto:${email}`;
  }
  return (
    <div className='mmn'>
      <div className='content-note'>
        <h2 >Secured Money Market Portfolio Linked Note</h2>
        <div className='note-wrapper'>
          <ul>
            <li>
              <div className='title'>PRODUCT CODE</div>
              <div className='value'>MMN</div>
            </li>
            <li>
              <div className='title'>PRODUCT ISSUER</div>
              <div className='value'>MetaVerse Green Exchange Pte. Ltd.</div>
            </li>
          </ul>
          <ul>
            <li>
              <div className='title'>ANNUALIZED INTEREST RATE</div>
              <div className='value'>~ 4.10 %</div>
            </li>
            <li>
              <div className='title'>DAILY RETURN RATE</div>
              <div className='value'>1.10 bps</div>
            </li>
          </ul>
          <div className='dif'><ExclamationCircleOutlined className='icon' /> Inception Date on 08 August 2023</div>
        </div>
      </div>

      <div className='overview'>
        <span className='title'>Overview</span>
        <Button className='mg-button' type={"primary"} onClick={toMce}>Subscription</Button>
        <div className='item-wrapper'>
          <div className='item left-item'>
            <div className='item-title'>Product Introduction</div>
            <div className='item-text'>The product intends to offer investors the ability to invest intraditional financial products, using Metaverse Green Exchange Pte Ltd.(“MVGX”) as a one-stop shop. In a context of high interest rates anduncertainties in the global financial market, our focus is to invest in shortand medium-term deposits and high-quality interest-yielding instruments, whichcan generate attractive returns for our clients.</div>
          </div>
          <div className='item right-item'>
            <div className='item-title'>Product Restrictions</div>
            <div className='item-text'>
              <p>
                Neither Metacomp nor its affiliates shall be obligated to offer this product to:-
              </p>
              <p>
                1. any legal or natural persons who are United States ("US") citizens or US registered entities;
              </p>
              <p>
                2. any legal or natural persons who are Mainland China citizens or Chinese-registered entities
                (excluding Hong Kong, Macau Special Administrative Region of the PRC and Taiwan);
              </p>
              <p>
                3. any legal or natural persons located in Singapore unless such persons qualify as an
                accredited investor, institutional investor or a professional investor according to Section 4A of the Securities and Futures Act 2001.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='chart-wrapper'>
        <div className='content-wrapper'>
          <div className='pie-wrapper'>
            <ReactEchartsCore ref={chartRef} echarts={echarts} style={{ height: '648px', width: curWidth }} option={option} />
          </div>
          <div className='list'>
            <div className='list-cell'>
              <span className='title'>Underlying Portfolio</span>
              <span className='value'>Money Market Portfolio</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Type of Security</span>
              <span className='value'>Lien</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Collateralized Assets</span>
              <span className='value'>Underlying Portfolio</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Interest Rate Type</span>
              <span className='value'>Compound</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Interest Accrual Frequency</span>
              <span className='value'>Daily</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Tenor</span>
              <span className='value'>Open-ended after minimum holding period</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Subscription Cut-off Time</span>
              <span className='value'>2:00 PM SGT</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Subscription Settlement Currency</span>
              <span className='value'>USD</span>
            </div>

            <div style={{fontSize: '12px'}}>
              <span className='value'>This advertisement has not been reviewed by the Monetary Authority of Singapore.</span>
            </div>
          </div>
          <div className='list'>
            <div className='list-cell'>
              <span className='title'>Minimum Subscription Amount</span>
              <span className='value'>USD 100,000.00</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Minimum Holding Period</span>
              <span className='value'>1 Business Day from the Subscription Date + 6 Calendar Days</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Interest Accrual Start Time</span>
              <span className='value'>1 calendar day before the date of subscription</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Redemption Cut-Off Time</span>
              <span className='value'>2:00 PM SGT</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Redemption Settlement Currency</span>
              <span className='value'>USD</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Earliest Redemption Date</span>
              <span className='value'>The next Business Day after the minimum holding period</span>
            </div>
            <div className='list-cell'>
              <span className='title'>Interest Accrual End Date</span>
              <span className='value'>1 calendar day before the date of redemption</span>
            </div>
            <div className='list-cell'>
              <div className='special'>
                <div>Note:</div>
                <div>Business Day : A day that is both a Singapore Business Day and a US Business Day.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Tips"
        centered
        footer={null}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={600}
      >
        <h3 className='tips-text'>For more information, you can email to <span onClick={() => mailTo()}>contact_us@mce.sg</span>.</h3>
      </Modal>
    </div>
  );
}

export default Mmn;