import './App.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Careers from "./pages/Careers/Careers";
import Jobs from './pages/Careers/Jobs'
import Mmn from "./pages/Mmn/Mmn"
import Tech from "./pages/Tech/Tech"
import AboutMvgx from './pages/AboutMvgx/AboutMvgx'
import Team from './pages/OurTeam/OurTeam1'
import TechServices from "./pages/TechServices/TechServices";
import CNCarbonReport from './pages/CNCarbonReport/CNCarbonReport'
import CNCarbonReportDetail from './pages/CNCarbonReport/CNCarbonReportDetail'
import PressRelease from './pages/PressRelease/PressRelease'
import PressReleasedDetail from './pages/PressRelease/PressReleaseDetail'
import ProductsAndServices from './pages/ProductsAndServices/ProductsAndServices'
import Videos from './pages/Videos/Videos'
import VideosDetail from './pages/Videos/VideosDetail'
import ComeSoon from "./pages/ComeSoon/ComeSoon";
import Presentation1 from "./pages/Aboutus/Presentation1";
import Terms from './pages/Terms/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import Knowledge from './pages/Knowledge/Knowledge'
import Disclosures from './pages/Disclosures/Disclosures'
import React, { useEffect, useRef } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      // 在DOM加载完成后设置window的scrollTop
      window.scrollTo(0, 5); // 设置为你想要的值
    }
  }, []);

  return (
    <div className="App" ref={containerRef}>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/careers" element={<Careers/>} />
          <Route path="/jobs" element={<Jobs/>} />
          <Route path="/mmn" element={<Mmn/>} />
          <Route path="/tech" element={<Tech/>} />
          <Route path="/about-mvgx" element={<AboutMvgx/>} />
          <Route path="/our-team" element={<Team/>} />
          <Route path="/tech-services" element={<TechServices/>} />
          <Route path="/CNCarbon-report" element={<CNCarbonReport/>} />
          <Route path="/CNCarbon-report-detail" element={<CNCarbonReportDetail/>} />
          <Route path="/press-release" element={<PressRelease/>} />
          <Route path="/press-release-detail" element={<PressReleasedDetail/>} />
          <Route path="/videos" element={<Videos/>} />
          <Route path="/videos-detail" element={<VideosDetail/>} />
          <Route path="/coming" element={<ComeSoon/>} />
          <Route path="/about-us" element={<Presentation1/>} />
          <Route path="/ProductsAndServices" element={<ProductsAndServices />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/knowledge" element={<Knowledge />} />
          <Route path="/disclosures" element={<Disclosures />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
