import React from 'react';
import Provide from './Provide/Provide'
import Foryou from "./Foryou/Foryou";
import About from "./About/About";
import './Tech.scss'


function Tech() {
  const bannerBack = require('./image/tech-banner.png')
  return (
    <div className='tech'>
      <img className='banner-back' src={bannerBack} alt="banner"/>
      <div className='banner-text'>MVGX Tech</div>
      <div className='common-wrapper'>
        <Foryou/>
        <Provide/>
        <About/>
      </div>
    </div>
  );
}

export default Tech;