import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Presentation1.scss'
import Book1 from "./Book1";

const list = [
  {
    icon: require('../image/techIcon.png'),
    title: 'Tech',
    text: 'We develop trusted carbon software solutions to enable corporations, governments, and institutions to take action at every stage of their decarbonisation journeys. From carbon measurement, reporting and verification to carbon credit development and issuance, our proprietary technology ensures the integrity of decarbonisations efforts.',
    bg: require('../image/tech.png'),
    // btn: 'Explore',
    path: 'tech',
    info: [
      'MVGX Tech is a Singapore-based Carbon Software-as-a-Service (SaaS) company that aims to empower corporations, governments, and institutions to take action at every stage of their decarbonisation journeys. ',
      "Consolidated under the Carbon Connect Suite, MVGX Tech's products, services, and software focus on carbon measurement, reporting, and verification ('MRV') as well as decarbonisation rating, carbon credit development and issuance, carbon credit listing and registry, and carbon credit trading. ",
      "Carbon Connect Suite is powered by MVGX Tech's proprietary blockchain-enabled technologies including its Non-Fungible Digital Twin (NFDT®) and Digital Carbon Credit (CNT®).",
    ]
  },
  {
    icon: require('../image/exchangeIcon.png'),
    title: 'Exchange',
    text: 'ur digital green asset marketplace is the first exchange to mandate carbon disclosure for both issuers and investors through its NASDAQ-powered trading platform. Regulated by the Monetary Authority of Singapore, MVGX Exchange provides a licenced, transparent and efficient platform for asset owners and investors.',
    bg: require('../image/exchange.png'),
    // btn: 'Explore',
    path: 'exchange',
    info: [
      'MVGX Exchange is a digital green asset marketplace that aims to make illiquid assets in the real economy more accessible.',
      'It is the first exchange of its kind to mandate carbon disclosure for both issuers and investors through its trading platform. ',
      'Regulated by the Monetary Authority of Singapore as a Recognized Market Operator (RMO) and powered by NASDAQ’s advanced trading and surveillance technology, MVGX Exchange provides capabilities for primary issuances, secondary trading, settlement, and custody of digital green assets.',
      '',
    ]
  },
  {
    icon: require('../image/securitiesIcon.png'),
    title: 'Securities',
    text: 'MVGX’s licensed brokerage service allows clients to trade traditional financial securities as well as innovative green assets such as carbon credits and green asset backed securities, all from the same platform.',
    bg: require('../image/securities.png'),
    // btn: 'Explore',
    path: 'securities',
    info: [
      'MVGX provides brokerage services enabled by its Capital Markets Services license in line with the Monetary Authority of Singapore’s Securities and Futures Act. This includes the dealing of securities, collective investment schemes, exchange-traded funds, over-the-counter derivatives, as well as custodial services.'
    ]
  },
  
]


function LiBox({data, type}) {
  const dispatch = useDispatch();
  const targetRef = useRef(null);
  const [distanceToBottom, setDistanceToBottom] = useState(null);
  const [moveClass, setMoveClass] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current) {
        const rect = targetRef.current.getBoundingClientRect();
        const distance = window.innerHeight - rect.bottom + rect.height;
        setDistanceToBottom(distance)
        if (distance > -10) {
          setMoveClass('li-move');
        } else {
          setMoveClass('');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const name = `${moveClass} list-cell`

  const routeTo = (path) => {
    if (path.indexOf('http') > -1) {
      window.open(path, "_blank")
    } else {
      dispatch({type: 'INCREMENT', payload: path})
    }
  }

  let content;
  if (type === 'left') {
    content = <div className='left-box'>
      <div ref={targetRef} data-dis={distanceToBottom}>
        <div className={name}>
          <div className='flex-box'>
            <div className='content'>
              <div>
                <img src={data.icon}  alt="icon" className='icon'></img>
                {data.title}
              </div>
              {data.info.map((item, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: item}} />
              ))}
              <div onClick={() => routeTo(data.path)} className='btn'>{data.btn}</div>
            </div>
            <img src={data.bg} alt="icon"/>
          </div>
        </div>
      </div>
    </div>
  } else {
    content = <div className='right-box'>
      <div ref={targetRef} data-dis={distanceToBottom}>
        <div className={name}>
          <div className='flex-box'>
            <img src={data.bg} alt="icon"/>
            <div className='content'>
              <div>
                <img src={data.icon}  alt="icon" className='icon'></img>
                {data.title}
              </div>
              {data.info.map((item, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: item}} />
              ))}
              <div onClick={() => routeTo(data.path)} className='btn'>{data.btn}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  return (
    content
  )
}

function Presentation() {
  const count = useSelector((state) => state.counter.count);
  const myRef = useRef(null);
  const myRef1 = useRef(null);
  const myRef2 = useRef(null);

  useEffect(() => {
    // 在组件挂载后，添加 Redux 数据变化的监听
    setTimeout(() => {
      if (count === 'tech') {
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (count === 'exchange') {
        myRef1.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (count === 'securities') {
        myRef2.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 300)
  }, [count]);
  return (
    <div>
      <Book1/>
      <div className='presentation1'>
        <div className='presentation-wrapper'>
          <div className='list'>
            <div ref={myRef}><LiBox data={list[0]} type={'left'}/></div>
            <div ref={myRef1}><LiBox data={list[1]} /></div>
            <div ref={myRef2}><LiBox data={list[2]} type={'left'}/></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Presentation;