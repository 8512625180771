import React from 'react';
import './Provide.scss'

const data = [
  {
    title: 'Carbon credits',
    text: [
      'Incorporating nationally recognized carbon credit standards,',
      'Certified by independent accredited firms',
      'Registered in a national registry to avoid double counting/double',
      'usages and NDC issues'
    ],
    img: require('../image/Group.png')
  },
  {
    title: 'ISO14064-1/ISO14067 Carbon footprint standard',
    text: ['Supersedes industrial, regional, or national standards'],
    img: require('../image/footer.png')
  },
  {
    title: 'PAS2060 Carbon neutrality (CN) standard',
    text: ['Only Internationally recognized CN standard ', 'In the process of being adopted by the ISO'],
    img: require('../image/leaf.png')
  }
]

const data1 = [
  {
    title: 'Utilizes proprietary blockchain technology',
    text: ['Transparent', 'Immutable', 'Traceable'],
    img: require('../image/light.png')
  },
  {
    title: 'CNT® technology tracks and guarantees the integrity of the carbon credit creation process',
    text: [],
    img: require('../image/bag.png')
  },
  {
    title: 'NFDT ® technology tracks and guarantees the integrity of the carbon footprint verification and carbon neutrality validation',
    text: [],
    img: require('../image/safe.png')
  },
  {
    title: 'NFDT ® technology tracks and guarantees the integrity of the carbon footprint verification and carbon neutrality validation',
    text: [],
    img: require('../image/book.png')
  }
]

function Box({data}) {
  return (
    <div className='cell-box'>
      <div className='flex-box'>
        <div className='flex-left'>
          <img src={data.img} alt="img"/>
        </div>
        <div className='flex-right'>
          <h4>{data.title}</h4>
          {data.text.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </div>
    </div>
  )
}

function Provide() {

  return (
    <div className='provide'>
      <div className='content'>
        <h1>MVGX Carbon Offerings Provides The Highest Level Of Integrity</h1>

        <div className='flex-wrapper'>
          <div>
            <h3>Highest Certification Standards</h3>
            {data.map((item, index) => (
              <Box data={item} key={index} />
            ))}
          </div>
          <div>
            <h3>Highest Technology Standards</h3>
            {data1.map((item, index) => (
              <Box data={item} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Provide;