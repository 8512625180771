import React from "react";
import './Banner.scss'

function Banner() {
  const src = require('../image/green-back.png')

  /*function toSign() {
    window.open('','_blank')
  }*/

  return (
    <div className='carbon-banner'>
      <img className='banner-back' src={src} alt=""/>
      <div className='text-content'>
        <h3>Your decarbonisation journey is more than a mechanism for environmental progress.</h3>
        <p>It can also drive economic opportunities and lead to competitive advantages. </p>
        {/*<button onClick={toSign}>Book a demo</button>*/}
      </div>
    </div>
  )
}

export default Banner;