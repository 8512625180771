// reducers.js
const initialState = {
  page: 1
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET':
      return {...state, page: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
