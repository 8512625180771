import React from 'react';
import './About.scss'

const data = [
  {
    text: 'All processes and certificates will be recorded on the blockchain and presented to the client through an NFT​',
    img: require('../image/cfdc.png')
  },
  {
    text: 'Powered by Nasdaq’s technologies + expertise',
    img: require('../image/hat.png')
  },
  {
    text: 'Unique Business Model -Intersection of digital and green assets',
    img: require('../image/treeLeaf.png')
  },
  {
    text: 'Enhances liquidity to real-world assets, funding real economy and green infrastructure',
    img: require('../image/cash.png')
  }
]

const data1 = [
  {
    text: 'MVGX Tech offers a comprehensive platform for carbon asset management, emissions calculation, verification, and carbon neutrality certification.',
    img: require('../image/pt.png')
  },
  {
    text: 'Anti-greenwashing and duplicate calculation avoidance.',
    img: require('../image/repeat.png')
  },
  {
    text: 'First exchange to realize quantified carbon disclosures for both issuers and investors',
    img: require('../image/jzw.png')
  },
  {
    text: 'Patent-pending system and process for global digital carbon trading and virtual representative of assets in MVGX Tech',
    img: require('../image/status.png')
  }
]

function Box({data}) {
  return (
    <div className='cell-box'>
      <div className='flex-box'>
        <div className='flex-top'>
          <img src={data.img} alt="img"/>
        </div>
        <div className='flex-bottom'>{data.text}</div>
      </div>
    </div>
  )
}

function About() {

  return (
    <div className='about-MVGX'>
      <div className='content'>
        <h2>ABOUT MVGX Tech</h2>
        <div className='des'>MVGX Tech Pte. Ltd. (MVGX Tech) is a Singapore company established to empower corporations, governments,
          and institutions to take action at every stage of their decarbonisation journey. MVGX Tech offers Carbon Connect Suite,
          a collection of Carbon as a Service (CaaS) products consisting of five systems–the carbon management system, the carbon
          rating system, and the carbon credit development, registry, and trading systems. Carbon Connect Suite is powered by MVGX
          Tech’s proprietary blockchain-enabled technologies including the Non-Fungible Digital Twin (NFDT®) and the Carbon Neutrality
          Token (CNT®), a digital carbon credit.
        </div>

        <div className='flex-wrapper'>
          {data.map((item ,index) => (
            <Box data={item} key={index} />
          ))}
        </div>
        <div className='flex-wrapper'>
          {data1.map((item, index) => (
            <Box data={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default About;