import React, {useEffect, useRef, useState} from 'react';
import { useDispatch } from 'react-redux';
import './Presentation.scss'

const list = [
  {
    title: 'Tech',
    text: 'From carbon measurement, reporting and verification to carbon credit development and issuance, our carbon software solutions enable corporations, governments, and institutions to take action at every stage of their decarbonisation journeys.\n',
    bg: require('../../image/tech1 (1).png'),
    btn: 'Explore',
    path: 'tech'
  },
  {
    title: 'Exchange',
    text: 'Regulated by the Monetary Authority of Singapore, our digital green exchange is the first of its kind, mandating carbon disclosures for both issuers and investors through its NASDAQ-powered trading platform.\n',
    bg: require('../../image/tech1 (2).png'),
    btn: 'Explore',
    path: 'exchange'
  },
  {
    title: 'Securities',
    text: 'Our licensed brokerage service allows for the trading of traditional financial securities as well as emerging asset classes such as carbon credits and green-asset backed securities — all from a single platform. \n',
    bg: require('../../image/tech1 (3).png'),
    btn: 'Explore',
    path: 'securities'
  },
  {
    title: 'Funds',
    text: 'We drive green impact investment throughout the region through Asia Green Fund, our affiliated venture capital and private equity fund with over US$2.5 billion in assets under management.\n',
    bg: require('../../image/tech1 (4).png'),
    btn: 'Explore',
    path: 'http://www.asiagreenfund.com/html/en_index/'
  }
]

function ListCell({data}) {
  const dispatch = useDispatch();
  const targetRef = useRef(null);
  const [distanceToBottom, setDistanceToBottom] = useState(null);
  const [moveClass, setMoveClass] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current) {
        const rect = targetRef.current.getBoundingClientRect();
        const distance = window.innerHeight - rect.bottom + rect.height;
        setDistanceToBottom(distance)
        if (distance > -10) {
          setMoveClass('li-move');
        } else {
          setMoveClass('');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const name = `${moveClass} list-cell`

  const routeTo = (path) => {
    if (path.indexOf('http') > -1) {
      window.open(path, "_blank")
    } else {
      dispatch({type: 'INCREMENT', payload: path})
    }
  }
  return (
    <div ref={targetRef} data-dis={distanceToBottom}>
      <div className={name}>
        <div className='flex-box'>
            <img src={data.bg} alt="icon"/>
          <div className='content'>
            <div>{data.title}</div>
            <div>{data.text}</div>
            <div onClick={() => routeTo(data.path)} className='btn'>{data.btn}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Presentation() {
  return (
    <div className='presentation'>
      <div className='presentation-wrapper'>
        <div className='list'>
          {list.map((item, index) => (
            <div key={index} className='item'>
              <ListCell data={item}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Presentation;