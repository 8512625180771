import React, {useEffect, useRef} from 'react';
import { useNavigate  } from 'react-router-dom';
import {RightOutlined} from '@ant-design/icons'

const Waterfall = ({ items }) => {
  const navigate = useNavigate();
  const targetRef = useRef(null);
  let counts = 0;
  useEffect(() => {
    items.forEach((cell) => {
      const image = new Image();
      image.src = cell.img;
      image.onload = handleImageLoad;
    });
    function handleImageLoad() {
      counts++;
      if (counts === items.length) {
        getWaterFall();
      }
    }

    function getWaterFall() {
      if (targetRef.current) {
        let doms = targetRef.current.children
        let nums = targetRef.current.clientWidth === 1240 ? 3 : 2;

        // 获取第一排的所有高度
        let arrHeight = []

        for (let i = 0; i < doms.length; i++) {
          if (i < nums) {
            // 将所有高度都添加到 arrHeight列表中
            doms[i].style.position = 'static'
            arrHeight.push(doms[i].clientHeight)
          } else {
            // 第二排图片 默认添加到第一个照片下面
            let obj = { minH: arrHeight[0], minI: 0 }
            // 循环判断 那个高度最小 将第二排的图片进行排比
            for (let j = 0; j < arrHeight.length; j++) {
              // 找到高度最小的将那个默认的给进行替换
              if (arrHeight[j] < obj.minH) {
                obj.minH = arrHeight[j]
                obj.minI = j
              }
            }
            // 给不是第一排的数据进行设置 样式定位
            doms[i].style.position = 'absolute'
            doms[i].style.left = doms[obj.minI].offsetLeft + 'px'
            doms[i].style.top = obj.minH + 20 + 'px'
            arrHeight[obj.minI] = arrHeight[obj.minI] + doms[i].clientHeight + 20
          }
        }
        targetRef.current.style.height = Math.max(...arrHeight) + 50 + 'px';
      }
    }

    const handleResize = () => {
      getWaterFall();
    };
    window.addEventListener('resize', handleResize);
    window.onload = () => {
      handleResize();
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [items, counts]);

  const toDetail = (item) => {
    navigate('/press-release-detail', {state: item})
  }

  return (
    <div className='wrapper' ref={targetRef}>
      {items.map((item, index) => (
        <div key={index} className='list-cell'>
          <section>
            <div className='img-wrapper'>
              <img onClick={() => toDetail(item)} src={item.img} alt="img"/>
            </div>
            <div className='content'>
              <p className='time'>{item.time}</p>
              <h4 onClick={() => toDetail(item)}>{item.title}</h4>
              <p>{item.content}</p>
              <a href="javasctipt:;" onClick={() => toDetail(item)} className='link'>Read More <RightOutlined/></a>
            </div>
          </section>
        </div>
      ))}
    </div>
  );
};

export default Waterfall;