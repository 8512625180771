import React from 'react'
import './AboutMvgx.scss'

let aboutData = {
    title: 'About MVGX',
    content: 'MetaVerse Green Exchange (“MVGX”) is a leading FinTech company providing end-to-end Carbon-as-a-Service solutions,' +
      ' backed by its licensed digital asset exchange platform and patent-pending Metaverse technologies. ' +
      'MVGX is committed to bridging carbon trading markets from different jurisdictions and bridging illiquid assets in the real economy with wider investor accessibility.'
  }
let visionData = {
    title: "Our Vision",
    children: [
      {
        title: 'The exchange that makes a sustainable and greener future in the metaverse era',
        content: 'Founded in 2018 and based in Singapore, ' +
          'MVGX has built a world-class digital exchange ledger with carbon footprint tag, and rigorous compliance processes. ' +
          'As the first regulated exchange for the metaverse era, MVGX has licenses from the Monetary Authority of Singapore (“MAS”) including the Recognized Market Operator (RMO), ' +
          'Capital Market Services License for Dealing in Securities, and Collective Investment Schemes, providing custodial services,' +
          ' exchange-traded derivatives as well as OTC derivatives.'
      },
      {
        title: 'Our Two Technologies',
        content: 'MetaVerse Green Exchange has two patent-pending technologies, Non-Fungible Digital Twin (NFDT®) to represent objects in the metaverse,' +
          ' and Carbon Neutrality Token (CNT®) to facilitate cross-border trading of carbon voluntary emission reduction credits without triggering national' +
          ' ownership issues (Nationally Determined Contributions).'
      }
    ]
  }
function AboutMvgx() {
  let imgSrc = require('../image/finger-img.png')
  return (
    <div className='about-mvgx'>
      <div className='about-wrapper'>
        <div className='img-wrapper'>
          <img src={imgSrc} alt="finger"/>
        </div>
        <div className='content'>
          <h2>{aboutData.title}</h2>
          <p>{aboutData.content}</p>
        </div>
      </div>
      <div className='vision-wrapper'>
        <h2>{visionData.title}</h2>
        <div className='vision'>
          <div className='flex-item'>
            <h3>{visionData.children[0].title}</h3>
            <p>{visionData.children[0].content}</p>
          </div>
          <div className='flex-item'>
            <h3>{visionData.children[1].title}</h3>
            <p>{visionData.children[1].content}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutMvgx