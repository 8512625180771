import React from "react";
import './PrivacyPolicy.scss'

function PrivacyPolicy() {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <div>
        <h3 className="wp-block-heading" id="introduction">Introduction</h3>

        <p>This Data Protection Notice (“Notice”) sets out the basis upon which MVGX Group may collect, use, disclose or
          otherwise process personal data of our customers in accordance with the Personal Data Protection Act (“PDPA”).
          This Policy applies to personal data in our possession or under our control, including personal data in the
          possession of organisations which we have engaged to collect, use, disclose or process personal data for our
          purposes.</p>

        <h3 className="wp-block-heading" id="personal-data">Personal Data</h3>

        <ol>
          <li>As used in this Notice:</li>
        </ol>


        <p>"<strong>Customer</strong>" means an individual who (a) has contacted us through any means to find out more
          about any services we provide, or (b) may, or has, entered into a contract with us for the supply of any
          services by us; and<br/>"<strong>Personal data</strong>" means data, whether true or not, about a customer who
          can be identified: (a) from that data; or (b) from that data and other information to which we have or are
          likely to have access.</p>


        <ol start="2">
          <li>Depending on the nature of your interaction with us, some examples of personal data which we may collect
            from you include:
          </li>
        </ol>


        <ul>
          <li>Financial data, which includes information about your financial position, status and history, and payment
            account details (such as bank, credit, payment or other payment details);
          </li>


          <li>Transaction data, which includes details about payments to and from your accounts with us;</li>


          <li>Technical data, which includes details about the devices and technology you use including internet
            protocol address, your login data, and other technology on the devices you use to access our website,
            products and/or services;
          </li>


          <li>Usage data, which includes information about how you use our website, products and/or services, including
            cookies, IP address, and account details; and/or;
          </li>


          <li>Blockchain data, which includes blockchain addresses and public keys.</li>
        </ul>


        <p>Other terms used in this Notice shall have the meanings given to them in the PDPA (where the context so
          permits).</p>


        <h3 className="wp-block-heading" id="collection-use-and-disclosure-of-personal-data">Collection, Use And
          Disclosure Of Personal Data</h3>


        <ol>
          <li>We generally collect personal data that (a) you knowingly and voluntarily provide in the course of or in
            connection with our provision of service to you, or via a third party who has been duly authorised by you to
            disclose your personal data to us (your “authorised representative”, after (i) you (or your authorised
            representative) have been notified of the purposes for which the data is collected, and (ii) you (or your
            authorised representative) have provided written consent to the collection and usage of your personal data
            for those purposes, or (b) collection and use of personal data without consent is permitted or required by
            the PDPA or other laws.
          </li>


          <li>We shall seek your consent before collecting any additional personal data and before using your personal
            data for a purpose which has not been notified to you (except where permitted or authorised by law). If you
            do not provide your Personal Data to us, MVGX Group may not be able to provide the services which you
            require of us. We collect personal data during the course of our daily activities and will only collect such
            personal data if it is necessary for one of our functions or activities.
          </li>


          <li>Some examples of how personal data can be collected: o when you access and/or use our website, products
            and/or services (or part thereof);
          </li>
        </ol>


        <ul>
          <li>When you access and/or use our website, products and/or services (or part thereof); register your details
            with MVGX Group;
          </li>


          <li>when you complete our account application for our products or services;</li>


          <li>when you communicate with us directly via our customer help desk, or directly via our client facing
            personnel within our organization in relation to our products and services (whether in person, by email,
            telephone, direct mail or any other means);
          </li>


          <li>when you request that we contact you;</li>


          <li>when you enter interact with us during events, workshops, or by using communication devices; participate
            in surveys and other types of research;
          </li>
        </ul>


        <ol start="4">
          <li>We may collect and use your personal data for any or all of the following purposes:</li>
        </ol>


        <ul>
          <li>to perform obligations in the course of or in connection with our provision of the services requested by
            you;
          </li>


          <li>to verify your identity;</li>


          <li>to respond to, handle, and process queries, requests, applications, complaints, and feedback from you; to
            manage your relationship with us;
          </li>


          <li>to communicate with you on matters relevant to your relationship with or interest in us; to work on any
            other purposes for which you have provided the information;
          </li>


          <li>to administer and process any payments related to products, services and/or facilities requested by you;
          </li>


          <li>to respond to your enquiries or complaints and resolve any issues and disputes which may arise in
            connection with any dealings with us;
          </li>


          <li>to provide you with information and/or updates on our products, services, upcoming workshops offered by us
            and/or events organised by us and selected third parties which may be of interest to you from time to time;
          </li>


          <li>to selectively send you information via email and/or any other appropriate communication channels to you
            on our services and products; in accordance with your consent;
          </li>


          <li>to maintain and update internal record keeping; for internal administrative purposes;</li>


          <li>to send you invitation to join our events and workshops;</li>


          <li>to monitor, review and improve our events and workshops, products and/or services; to process any payments
            related to your trading activity with us;
          </li>


          <li>to share any of your Personal Data with the auditor for our internal audit and reporting purposes;</li>


          <li>to share any of your Personal Data pursuant to any agreement or document which you have duly entered with
            us for purposes of seeking legal and/or financial advice and/or for purposes of commencing legal action;
          </li>


          <li>for audit, risk management and security purposes;</li>


          <li>for detecting, investigating and preventing fraudulent, prohibited or illegal activities;</li>


          <li>for enabling us to perform our obligations and enforce our rights under any agreements or documents that
            we are a party to; to transfer or assign our rights, interests and obligations under any agreements entered
            with us;
          </li>


          <li>for meeting any applicable legal or regulatory requirements and making disclosure under the requirements
            of any applicable law, regulation, direction, court order, by-law, guideline, circular or code applicable to
            us;
          </li>


          <li>to enforce or defend our rights and your rights under, and to comply with, our obligations under the
            applicable laws, legislation and regulations; and/or
          </li>


          <li>for other purposes required to operate, maintain and better manage our business and your relationship with
            us; which we notify you of at the time of obtaining your consent.
          </li>


          <li>transmitting to any unaffiliated third parties including our third party service providers and agents, and
            relevant governmental and/or regulatory authorities, whether in Singapore or abroad, for the aforementioned
            purposes;
          </li>


          <li>any other incidental business purposes related to or in connection with the above.</li>
        </ul>


        <p>(collectively, the “Purposes”) </p>


        <p>5. We may disclose your personal data:</p>


        <p>where such disclosure is required for performing obligations in the course of or in connection with our
          provision of the goods and services requested by you; or to third party service providers, agents and other
          organisations, whether sited in Singapore or outside of Singapore, we have engaged to perform any of the
          functions with reference to the above mentioned purposes.</p>


        <p>6. The purposes listed in the above clauses may continue to apply even in situations where your relationship
          with us has been terminated or altered in any way, for a reasonable period thereafter (including, where
          applicable, a period to enable us to enforce our rights under a contract with you).</p>


        <h3 className="wp-block-heading">Specific
          Issues On Disclosure Of Personal Data To Third Parties</h3>


        <ol>
          <li>We respect the confidentiality of the personal data you have provided to us. MVGX Group does not share,
            sell, rent or release any personal data collected to any individuals, companies or groups. Any information
            we collect is used for MVGX Group’s purposes as described in this PDPA policy.
          </li>


          <li>So that we can provide a wide range of products and services to you, third party service providers
            offering services are engaged through MVGX Group, including but not limited to the following:
          </li>
        </ol>


        <ul>
          <li>professional advisors, consultants</li>


          <li>Third party service providers who provide operational services such as telecommunications, information
            technology, marketing promotions, and relevant government regulators to comply with any laws, rules,
            guidelines and regulations or schemes imposed by any governmental authority.
          </li>
        </ul>


        <ol start="3">
          <li>The third parties whom we conduct business are only authorized to use your information to perform the
            service for which they were hired. As part of our agreement with them, they are required to follow the PDPA
            laws and policies that we provide, and to take reasonable measures to ensure your personal data is secure.
          </li>
        </ol>


        <p>In that regard, we will not disclose your personal data to third parties without first obtaining your consent
          permitting us to do so. However, please note that we may disclose your personal data to third parties without
          first obtaining your consent in certain situations, including, without limitation, the following:</p>


        <ul>
          <li>cases in which the disclosure is required or authorized based on the applicable laws and/or regulations;
          </li>


          <li>cases in which the purpose of such disclosure is clearly in your interests, and if consent cannot be
            obtained in a timely way;
          </li>


          <li>cases in which the disclosure is necessary to respond to an emergency that threatens the life, health or
            safety of yourself or another individual;
          </li>


          <li>cases in which the disclosure is necessary for any investigation or proceedings;</li>


          <li>cases in which the personal data is disclosed to any officer of a prescribed law enforcement agency, upon
            production of written authorisation signed by the head or director of that law enforcement agency or a
            person of a similar rank, certifying that the personal data is necessary for the purposes of the functions
            or duties of the officer;
          </li>


          <li>where such disclosure without your consent is permitted by the PDPA or by law.</li>
        </ul>


        <ol start="4">
          <li>For more information on the exceptions, you are encouraged to peruse the Second, Third and Fourth
            Schedules of the PDPA which is publicly available at https://sso.agc.gov.sg.
          </li>
        </ol>


        <p>Where we disclose your personal data to third parties with your consent, we will employ our best efforts to
          require such third parties to protect your personal data.</p>


        <h3>Cookies</h3>


        <ol>
          <li>When you browse our websites, we use cookies to store information about how you use these websites in
            order to improve the quality of service provided to you.
          </li>


          <li>To understand what type of cookies we use and how these works when you use our websites, please refer to
            our Cookie Policy.
          </li>
        </ol>


        <h3>Withdrawing Your Consent</h3>


        <ol>
          <li>The consent that you provide for the collection, use and disclosure of your personal data will remain
            valid until such time it is being withdrawn by you in writing. As a customer, you may withdraw consent and
            request us to stop collecting, using and/or disclosing your personal data for any or all of the purposes
            listed above by submitting your request in writing or via email to our Data Protection Officer at the
            contact details provided below.
          </li>


          <li>Upon receipt of your written request to withdraw your consent, we may require reasonable time (depending
            on the complexity of the request and its impact on our relationship with you) for your request to be
            processed and for us to notify you of the consequences of us acceding to the same, including any legal
            consequences which may affect your rights and liabilities to us. In general, we shall seek to process and
            effect your request within ten (10) days of receiving it.
          </li>


          <li>Whilst we respect your decision to withdraw your consent, please note that depending on the nature and
            extent of your request, we may not be in a position to continue providing our goods or services to you (as
            the case may be). We shall, in such circumstances, notify you before completing the processing of your
            request (as outlined above). Should you decide to cancel your withdrawal of consent, please inform us in
            writing in the manner described in clause 1 of this section.
          </li>


          <li>Please note that withdrawing consent does not affect our right to continue to collect, use and disclose
            personal data where such collection, use and disclose without consent is permitted or required under
            applicable laws.
          </li>
        </ol>


        <h3>Access To And Correction Of
          Personal Data</h3>


        <ol>
          <li>If you wish to make (a) an access request for access to a copy of the personal data which we hold about
            you or information about the ways in which we use or disclose your personal data, or (b) a correction
            request to correct or update any of your personal data which we hold, you may submit your request in writing
            or via email to our Data Protection Officer at the contact details provided below.
          </li>


          <li>Please note that a reasonable fee may be charged for an access request. If so, we will inform you of the
            fee before processing your request.
          </li>


          <li>We will respond to your request as soon as reasonably possible. In general, our response will be within
            thirty (30) business days. Should we not be able to respond to your request within thirty (30) days after
            receiving your request, we will inform you in writing within thirty (30) days of the time by which we will
            be able to respond to your request. If we are unable to provide you with any personal data or to make a
            correction requested by you, we shall generally inform you of the reasons why we are unable to do so (except
            where we are not required to do so under the PDPA).
          </li>
        </ol>


        <h3>Protection Of Personal Data</h3>


        <ol>
          <li>To safeguard your personal data from unauthorised access, collection, use, disclosure, copying,
            modification, disposal or similar risks, we have introduced appropriate administrative, physical and
            technical measures such as up-to-date antivirus protection, encryption, use of privacy filters, and
            disclosing personal data both internally and to our authorised third party service providers and agents only
            on a need-to-know basis.
          </li>


          <li>You should be aware, however, that no method of transmission over the Internet or method of electronic
            storage is completely secure. While security cannot be guaranteed, we strive to protect the security of your
            information and are constantly reviewing and enhancing our information security measures.
          </li>
        </ol>


        <h3>Accuracy Of Personal Data</h3>


        <p>We generally rely on personal data provided by you (or your authorised representative). In order to ensure
          that your personal data is current, complete and accurate, please update us if there are changes to your
          personal data by informing our Data Protection Officer in writing or via email at the contact details provided
          below.</p>


        <h3>Retention of Personal Data</h3>


        <ol>
          <li>We may retain your personal data for as long as it is necessary to fulfil the purposes for which they were
            collected, or as required or permitted by applicable laws.
          </li>


          <li>We will cease to retain your personal data, or remove the means by which the data can be associated with
            you, as soon as it is reasonable to assume that such retention no longer serves the purposes for which the
            personal data were collected, and are no longer necessary for legal or business purposes.
          </li>
        </ol>


        <h3>Transfer Of Personal Data
          Outside Singapore</h3>


        <p>We generally do not transfer your personal data to countries outside of Singapore. However, if we do so, we
          will obtain your consent for the transfer to be made and will take steps to ensure that your personal data
          continues to receive a standard of protection that is at least comparable to that provided under the PDPA.</p>


        <h3>Data Protection Officer</h3>


        <p>You may contact our Data Protection Officer if you have any enquiries or feedback on our personal data
          protection policies and procedures; or if you wish to make any request, in the following manner:</p>


        <p>To: Data Protection Officer </p>


        <p>Email Address: <a href="mailto:dpo@mvgx.com">dpo@mvgx.com</a></p>


        <h3 >Effect Of Notice And Changes To Notice</h3>


        <ol>
          <li>This Notice applies in conjunction with any other policies, notices, contractual clauses and consent
            clauses that apply in relation to the collection, use and disclosure of your personal data by us.
          </li>


          <li>We may revise this Notice from time to time without any prior notice. You may determine if any such
            revision has taken place by referring to the date on which this Notice was last updated. Your continued use
            of our services constitute your acknowledgement and acceptance of such changes.
          </li>
        </ol>


        <p>Effective Date: 02/12/2019 </p>


        <p>Last Updated: 09/02/2023</p>


        <h1 style={{textAlign: 'left', marginTop: '40px'}}>privacy policy for job applications</h1>


        <div>


          <h3>Introduction</h3>


          <p>This Data Protection Notice (“Notice”) sets out the basis upon which MVGX Group may collect, use, disclose
            or otherwise process personal data of job applicants in accordance with the Personal Data Protection Act (
            “PDPA”). This Policy applies to personal data in our possession or under our control, including personal
            data in the possession of organisations which we have engaged to collect, use, disclose or process personal
            data for our purposes.</p>


          <h3>Application of this Notice</h3>


          <p>This Notice applies to all persons who applied for any such position with us ( “job applicants”).</p>


          <h3>Personal Data</h3>


          <ol>
            <li>As used in this Notice, “personal data” means data, whether true or not, about an employee or a job
              applicant who can be identified: (a) from that data; or (b) from that data and other information to which
              we have or are likely to have access.
            </li>


            <li>Personal data which we may collect includes, without limitation, your:</li>
          </ol>


          <ul>
            <li>identity and contact data, which includes your name,
              passport or other identification number, telephone number, mailing address, images of your passport or
              identification document;</li>


            <li>resume,
              educational qualifications, professional qualifications and certifications and employment references;
              employment and training history;</li>


            <li>salary information and bank account details;</li>


            <li>details of your next-of-kin, spouse and other family members; work-related health issues and disabilities;</li>


            <li>photographs;</li>


            <li>any other personal data relevant to the job application</li>
          </ul>


          <p>3. Other terms used in this Notice shall have the meanings given to them in the PDPA (where the context so
            permits).</p>


          <h3>Collection, Use And
            Disclosure Of Personal Data</h3>


          <ol type="1">
            <li>We generally collect personal data that (a) you knowingly and voluntarily provide in the course of or in
              connection with your employment or job application with us, or via a third party who has been duly
              authorised by you to disclose your personal data to us (your “authorised representative”, which may
              include your job placement agent), after (i) you (or your authorised representative) have been notified of
              the purposes for which the data is collected, and (ii) you (or your authorised representative) have
              provided written consent to the collection and usage of your personal data for those purposes, or (b)
              collection and use of personal data without consent is permitted or required by the PDPA or other laws. We
              shall seek your consent before collecting any additional personal data and before using your personal data
              for a purpose which has not been notified to you (except where permitted or authorised by law).
            </li>


            <li>Your personal data will be collected and used by us for the following purposes and we may disclose your
              personal data to third parties where necessary for the following purposes:
            </li>
          </ol>


          <ul>
            <li>assessing and evaluating your suitability for employment in any current or prospective position within the organisation; verifying your identity and the accuracy of your personal details and other information provided;</li>


            <li>performing
              obligations under or in connection with your contract of employment with us, including payment of
              remuneration and tax; all administrative and human resources related matters within our organisation,
              including administering payroll, granting access to our</li>


            <li>premises and computer systems, processing leave applications, administering your insurance and other
              benefits, processing your claims and expenses, investigating any acts or defaults (or suspected acts or
              defaults) and developing human resource policies;
            </li>


            <li>managing and terminating our employment relationship
              with you, including monitoring your internet access and your use of our intranet email to investigate
              potential contraventions of our internal or external compliance regulations, and resolving any employment
              related grievances;</li>


            <li>assessing and evaluating your suitability for
              employment/appointment or continued employment/appointment in any position within our organisation;</li>


            <li>ensuring
              business continuity for our organisation in the event that your employment with us is or will be
              terminated; performing obligations under or in connection with the provision of our goods or services to
              our clients;</li>


            <li>facilitating any proposed or confirmed merger,
              acquisition or business asset transaction involving any part of our organisation, or corporate
              restructuring process;</li>


            <li>facilitating our compliance with any laws, customs and
              regulations which may be applicable to us.</li>
          </ul>


          <p>3. The purposes listed in the above clauses may continue to apply even in situations where your
            relationship with us has been terminated or altered in any way, for a reasonable period thereafter
            (including, where applicable, a period to enable us to enforce our rights under a contract with you).</p>


          <h3>Withdrawing Consent</h3>


          <ol>
            <li>The consent that you provide for the collection, use and disclosure of your personal data will remain
              valid until such time it is being withdrawn by you in writing. As a job applicant, you may withdraw
              consent and request us to stop collecting, using and/or disclosing your personal data for any or all of
              the purposes listed above by submitting your request in writing or via email to our Data Protection
              Officer at the contact details provided below.
            </li>


            <li><span>Upon receipt of your written request to withdraw your consent, we may require reasonable time (depending on the complexity of the request and its impact on our relationship with you) for your request to be processed and for us to notify you of the consequences of us acceding to the same, including any legal consequences which may affect your rights and liabilities to us. In general, we shall seek to process and effect your request within ten (10) days of receiving it.</span>
            </li>


            <li><span>Whilst we respect your decision to withdraw your consent, please note that depending on the nature and extent of your request, we may not be in a position to process your job application (as the case may be). We shall, in such circumstances, notify you before completing the processing of your request (as outlined above). Should you decide to cancel your withdrawal of consent, please inform us in writing in the manner described in clause 1 of this section.</span>
            </li>


            <li>Please note that withdrawing consent does not affect our right to continue to collect, use and disclose
              personal data where such collection, use and disclose without consent is permitted or required under
              applicable laws.
            </li>
          </ol>


          <h3>Access To And Correction Of Personal Data</h3>

          <ol>
            <li>If you wish to make (a) an access request for access to a copy of the personal data which we hold about
              you or information about the ways in which we use or disclose your personal data, or (b) a correction
              request to correct or update any of your personal data which we hold, you may submit your request in
              writing or via email to our Data Protection Officer at the contact details provided below.
            </li>


            <li>We will cease to retain your personal data, or remove the means by which the data can be associated with
              you, as soon as it is reasonable to assume that such retention no longer serves the purposes for which the
              personal data were collected, and are no longer necessary for legal or business purposes.
            </li>
          </ol>


          <h3>Transfers of Personal Data
            Outside Singapore</h3>


          <p>We generally do not transfer your personal data to countries outside of Singapore. However, if we do so, we
            will obtain your consent for the transfer to be made and will take steps to ensure that your personal data
            continues to receive a standard of protection that is at least comparable to that provided under the
            PDPA.</p>


          <h3>Data Protection Officer</h3>


          <p>You may contact our Data Protection Officer if you have any enquiries or feedback on our personal data
            protection policies and procedures; or if you wish to make any request, in the following manner:</p>


          <p>To: Data Protection Officer </p>


          <p>Email Address: <a href="mailto:dpo@mvgx.com">dpo@mvgx.com</a></p>


          <h3>Effect Of Notice And Changes To Notice</h3>


          <ol type="1">
            <li>This Notice applies in conjunction with any other policies, notices, contractual clauses and consent
              clauses that apply in relation to the collection, use and disclosure of your personal data by us.
            </li>


            <li>We may revise this Notice from time to time without any prior notice. You may determine if any such
              revision has taken place by referring to the date on which this Notice was last updated. Your continued
              use of our services constitute your acknowledgement and acceptance of such changes.
            </li>
          </ol>


          <p>Effective Date: 02/12/2019 </p>


          <p>Last Updated: 09/02/2023</p>


          <h1 style={{textAlign: 'left', marginTop: '40px'}}>Cookie Policy</h1>


          <div>

            <h3 >Introduction</h3>


            <ol>
              <li>Our cookie policy (“Cookie Policy”) explains how and why we use cookies on our websites.</li>


              <li>By continuing to use our website without changing your settings, you consent to our use of cookies as
                set out in this Cookie Policy. If you do not agree to our use of cookies as set out in this Cookie
                Policy, you should disable or delete the cookies associated with the websites by changing the setting on
                the web browser on your device accordingly.
              </li>
            </ol>


            <p>We may from time to time amend the Cookie Policy. Such changes will be published here and effective upon
              publication. Please check the Cookie Policy regularly for updated information/version. Your continued
              access and/or use of our website, our products and/or our services will constitute your agreement to the
              revised Cookie Policy.</p>


            <h3>Cookies</h3>


            <ol>
              <li>Cookies are small files (typically made up of letters and numbers) placed onto your computer by
                certain websites that you visit or e-mails that you open. Cookies allow a website to recognize a
                particular device or browser. There are several types of cookies:
              </li>
            </ol>


            <p>Session cookies expire at the end of your browser session and allow us to link your actions during that
              particular browser session. Persistent cookies are stored on your device in between browser sessions,
              allowing us to remember your preferences or actions across multiple sites. First-party cookies are set by
              the site you are visiting.</p>


            <ol start="2">
              <li>Cookies can be disabled or removed by tools that are available in most commercial browsers. The
                preferences for each browser you use will need to be selected separately and different browsers offer
                different functionalities and options.
              </li>
            </ol>


            <h3>How We Use Cookies</h3>


            <ol type="1">
              <li>When you visit, access or interact with MVGX Group website or portal and use our services, we or our
                authorized service providers may use cookies for collecting and storing information to help provide you
                with a better, faster, and safer web experience.
              </li>


              <li>We use cookies to:</li>
            </ol>


            <p>manage the signing up process and general administration when you create an account with us; remember
              your user details for future correspondence when you submit data through a form; remember your preferences
              when viewing our websites; to remember when you are logged in to your account, which prevents you from
              having to log in every single time you visit a new page; record and store your acceptance of our terms of
              services (including our Privacy Policy and the Cookie Policy); collect statistical information about users
              of our websites (for example, time spent on each page and which links our users choose to click); and from
              time to time when we offer surveys and questionnaires to understand our user base more accurately, such
              surveys and questionnaires may use cookies to remember who has already taken part in a survey to provide
              you with accurate results after you change pages.</p>


            <p>3. Where third party marketing cookies are used to gather information about your browsing activities,
              please note that we do not have access to, nor control over these third parties’ use of cookies or other
              tracking technologies or the content of their advertisements.</p>


            <h3>Disabling Cookies</h3>


            <ol>
              <li>You can prevent the setting of cookies by adjusting the settings on your browser.</li>


              <li>Please note however that disabling cookies will typically result in disabling certain functionalities
                and features of this site. For example, we will not be able to know that you have opted out of targeted
                advertising on our website, recognise that you have agreed to the Privacy Policy and Cookie Policy, or
                remember your preferences.
              </li>


              <li>The personal data that we collect may include information about the way you use our website, your
                choice of Internet browser, the type of device you’re accessing our website from and where you are in
                the world.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy;