import React from "react";
import './Terms.scss'

function Terms() {
  return (
    <div className={'terms'}>
      <h1>Terms of Use</h1>

      <div className="rodller-entry-content container">

        <div className="wp-block-separator has-text-color has-css-opacity has-background is-style-wide">

          <p>
            <strong><u>1.DEFINITIONS</u></strong>
          </p>


          <div style={{padding: '10px 0'}}>
            <table>
              <tbody>
              <tr>
                <td>
                  <strong>“Terms”</strong>
                </td>
                <td>The terms and conditions contained herein and any amendments from time to time, as published on
                  the Website. &nbsp;</td>
              </tr>
              <tr>
                <td><strong>“Personal Data”</strong>
                </td>
                <td>Data, whether true or not, about an individual who can be identified: from that data;
                  or  from that data and other information to which the organisation has or is likely to have
                  access. </td>
              </tr>
              <tr>
                <td><strong style={{whiteSpace: 'nowrap'}}>“MVGX”, “we”, “our” and “us”</strong></td>
                <td>MetaVerse Green Exchange Pte. Ltd., a company bearing registration number 201825931K and
                  incorporated under the laws of Singapore.
                </td>
              </tr>
              <tr>
                <td><strong>“Website”</strong> &nbsp;</td>
                <td>Both the mobile and web versions of the website located at <a href="https://www.mvgx.com">https://www.mvgx.com</a> . &nbsp;</td>
              </tr>
              <tr>
                <td><strong>“you” or “your”</strong></td>
                <td>Any person and/or entity accessing and/or using the Website.</td>
              </tr>
              </tbody>
            </table>
          </div>


          <p>2. <strong><u>INTERPRETATION</u></strong></p>


          <ol>
            <li>The headings in these Terms are inserted for convenience of reference only and shall not affect the
              interpretation or construction of these Conditions.</li>


            <li>Unless elsewhere otherwise expressly provided or where the context otherwise requires, words importing
              the singular shall include the plural and vice versa and words importing a specific gender shall include
              the other gender.</li>


            <li>Any reference to conduct includes without limitation, an omission, statement or undertaking, whether
              or not in writing.
            </li>
          </ol>


          <p>3. <strong><u>GENERAL</u></strong></p>


          <ol>
            <li>These Terms govern your use of or access to any area of the Website, including any information,
              services, contents, functionalities, products or features that form part of the Website as offered
              and/or operated by MVGX. These Terms constitute a binding agreement between you and MVGX.
            </li>


            <li>By browsing, accessing and/or using the Website, you are deemed to have read and accept these Terms.
              If you do not agree to these Terms, you shall cease using and/or accessing the Website.
            </li>


            <li>By accessing and using the Website, you unconditionally agree and accept to be legally bound by these
              Terms and any amendments thereto from time to time. We reserve the right to change these Terms at our
              sole discretion and at any time without prior notice to you. Your continued access to and/or use of the
              Website indicate your acceptance of the revised Terms. It is your responsibility to check for the latest
              version of the Terms.
            </li>


            <li>We may modify or discontinue any information or features that form part of the Website at any time,
              without notice to you, and without liability. We may also, in our discretion, cease to operate the
              Website at any time, without notice to you, and without liability.
            </li>


            <li>From time to time, we may (including through our services providers or third parties) offer specific
              or new products, features, functionalities and/or services to complement or supplement the
              product/service offerings comprised within the Website. Additional or separate terms and conditions may
              apply to these products, features, functionalities and/or services, and you shall accept and comply with
              those terms and conditions to enjoy them.
            </li>


            <li>If you are accessing the Website for and on behalf of another entity (such as your employer), you
              warrant and represent that you have the necessary authority to bind such entity to these Terms.
            </li>
          </ol>


          <p>4. <strong><u>ACCESS TO THE WEBSITE</u></strong></p>


          <p>(1) Your use of the Website is subject to your compliance with these Terms. You represent, undertake and
            warrant that you shall:</p>


          <ul>
            <li>provide us with all necessary information and/or produce all necessary documents as may be required by
              us in order to provide you with products and services you need on the Website;
            </li>


            <li>comply with all applicable laws and regulations with respect to your activities in connection with the
              Website; and
            </li>


            <li>comply with all applicable security or encryption standards, rules, procedures and guidelines.</li>
          </ul>


          <p>(2) We do not guarantee that the Website shall:</p>


          <ul>
            <li>be error-free;</li>


            <li>be continuously available or uninterrupted in operation;</li>


            <li>not be susceptible to any cybersecurity breach or cannot be compromised and/or free of any data breach
              (inadvertently or intentionally through interference or interception by third parties);
            </li>


            <li>be free of bugs or other harmful codes and components not specifically mentioned herein; or</li>


            <li>contain information that is always timely and accurate.</li>
          </ul>


          <p>5. <strong><u>USER ACCOUNT REGISTRATION</u></strong></p>


          <p>(1) In order to access or use certain functions of the Website, you may be required to register and/or
            set up a user account. You agree and represent that all registration information you provide for the user
            account shall be accurate. You shall not impersonate, imitate, or pretend to be somebody else when
            registering for an account on the Website. You take full responsibility for all information and
            instructions transmitted through the Website using your account. You shall not access another person’s
            account without his/her authority.</p>


          <p>(2) We may terminate your user account at any time without prior notice if:</p>


          <ul>
            <li>we believe that you are in breach, or will be in breach of any of these Terms;</li>
          </ul>


          <ul>
            <li>your user account is inactive;</li>
          </ul>


          <ul>
            <li>it is appropriate to do so for security interests or to protect our interests; or</li>
          </ul>


          <ul>
            <li>we are ordered by law enforcement or any governmental agency to do so.</li>
          </ul>


          <p>(3) You acknowledge and agree that we may access your user account and its contents as necessary for
            purposes including but not limited to identifying or resolving technical problems or responding to
            complaints without prior notice to you.</p>


          <p>(4) You must take reasonable care to protect and keep confidential your password and other account or
            identity information. You must notify us in writing immediately of any apparent breach of security such as
            loss, theft, misuse or unauthorised disclosure or use of a password.</p>


          <p>6) <strong><u>INTELLECTUAL PROPERTY</u></strong></p>


          <ol>
            <li>Except as otherwise expressly stated herein, the copyright and all other intellectual property in the
              contents of the Website (including, but not limited to, all texts, images, designs or links) are the
              property of MVGX and/or its subsidiaries. They may not be reproduced, transmitted, published, performed,
              broadcast, stored, adapted, distributed, displayed, licensed, altered, hyperlinked or otherwise used in
              whole or in part in any manner without the prior written consent of MVGX and/or its subsidiaries.
            </li>


            <li>All trade marks, service marks and logos used in the Website are the property of MVGX and/or the
              respective third party proprietors identified in the Website. No licence or right is granted and your
              access or use of the Website should not be construed as granting, by implication, estoppel or otherwise,
              any license or right to use any trade marks, service marks or logos appearing on the Website without the
              prior written consent of MVGX or the relevant third party proprietor thereof. Save and except with
              MVGX’s prior written consent, no such trade mark, service mark or logo may be used as a hyperlink or to
              mark any hyperlink to any MVGX.
            </li>
          </ol>


          <p>7) <strong><u>RESTRICTIONS</u></strong></p>


          <p>Neither MVGX, any of its affiliates, subsidiaries, employees, agents, partners, principals and
            representatives (all of the foregoing, collectively, “<strong>MVGX Parties</strong>”), nor any other
            person is, in connection with this site, engaged in rendering auditing, accounting, tax, legal, advisory,
            consulting or other professional services or advice. You agree not to use the content of the Website as a
            substitute for independent investigations and competent financial and business judgment of the user of the
            Website and such user shall obtain professional advice tailored to his particular factual situation. You
            are responsible for all matters arising from its use of the Website.</p>


          <p>8) <strong><u>LINKS TO OTHER WEBSITES</u></strong></p>


          <p>Links may be established from the Website to one or more external websites or resources operated by third
            parties (the “<strong>Third Party Sites</strong>”). In addition, certain Third Party Sites also may
            provide links to this site. None of such links should be deemed to imply that MVGX endorses the Third
            Party Sites or any content therein. Unless the link is to another MVGX website, MVGX does not control and
            is not responsible or liable for any Third Party Sites or any content, advertising, products, or other
            materials on or available from such Third Party Sites. Access to any Third Party Sites is at your own risk
            and MVGX will have no liability arising out of or related to such websites and/or their content or for any
            damages or loss caused or alleged to be caused by or in connection with any purchase, use of or reliance
            on any such content, goods, or services available on or through any such Third Party Site.
          </p>


          <p>9) <strong><u>PERSONAL DATA</u></strong></p>


          <p>Your personal data may be collected by us when you access and use the Website. You consent to our
            collection, use, disclosure and processing of your personal data in accordance with our Privacy Policy,
            which is accessible at <a href="https://www.mvgx.com">https://www.mvgx.com</a> .</p>


          <p>10) <strong><u>SECURITY AND RISKS</u></strong></p>


          <ol>
            <li>Where appropriate, we use available technologies to protect the security of the Website. To the
              maximum extent permitted under applicable laws, we do not accept liability for the security,
              authenticity, integrity or confidentiality of any transactions and other communications made through the
              Website.
            </li>


            <li>Internet transactions and communications may be susceptible to interference or interception by third
              parties. You acknowledge that there are inherent risks (including risks related to security,
              authenticity, integrity and confidentiality) in such activities over electronic networks. We expressly
              disclaim any liability for such risks, which you agree to assume. We make no warranties that the Website
              is free of infection by computer viruses or other unauthorised software.
            </li>
          </ol>


          <p>11) <strong><u>LIMITATION OF LIABILITY</u></strong></p>


          <ol>
            <li>The contents of the Website are provided “as is” and “as available” without warranties of any kind. To
              the fullest extent permitted by applicable law, MVGX Parties make no guarantee, representation or
              warranty of any kind, either express or implied:
              <ul>
                <li>as to the truth, accuracy, adequacy, reliability, completeness, reasonableness, currency or
                  timeliness, of the contents contained in or accessed through the Website and expressly disclaims
                  liability for any errors in or omissions from, the contents of the Website; or
                </li>


                <li>that the contents available through the Website or any functions associated therewith will be
                  uninterrupted or error-free, or that defects will be corrected or that the Website and the server is
                  and will be free of all viruses and/or other harmful elements.
                </li>
              </ul>
            </li>


            <li>No warranty of any kind, express, implied or statutory (including but not limited to, warranties of
              title, merchantability, satisfactory quality, non-infringement of third party intellectual property
              rights, fitness for a particular purpose and freedom from computer virus), is given in conjunction with
              the contents of the Website.
            </li>


            <li>In no event shall MVGX be liable for damages, expenses, costs or loss of any kind (including without
              limitation any direct, or indirect, special, incidental or consequential damages, loss of profits or
              loss opportunity) howsoever caused as a result (direct or indirect) of, or in connection with, your use
              of the Website, or reliance on any information, materials, tools or online services provided in or
              provided form the Website, regardless of the form of action.
            </li>


            <li>Under no circumstances shall MVGX be liable for any failure of performance, system, server or
              connection failure, interruption, defect, error, omission, delay in operation or transmission, breach of
              security, malicious code, computer virus or inability or unavailability to use or access in connection
              with your accessing the Website even if the MVGX had been advised as to the possibility of the above.
            </li>
          </ol>


          <p>12. <strong><u>TECHNICAL REQUIREMENTS</u></strong></p>


          <p>You acknowledge and agree that for the Website to function, it may require a compatible device (including
            a mobile or computing device), appropriate third party software (such as browsers), and also connectivity
            to the internet. You are solely responsible for obtaining such device(s), software, and the necessary
            connectivity services to access and use the Website. We assume no responsibility for such devices,
            software and services, or for any functionality of the Website which are dependent on them to operate.</p>


          <p>13. <strong><u>INDEMNITY</u></strong></p>


          <p>(1) You agree to indemnify the MVGX Parties in respect of any and all claims, losses, damages, expenses
            (including legal costs on a full indemnity basis), or liabilities and costs of settlement suffered or
            incurred by the MVGX Parties or asserted against them in respect of:</p>


          <ul>
            <li>any access, use of, or conduct in connection with, the Website by you;</li>
          </ul>


          <ul>
            <li>any breach of these Terms by you;</li>
          </ul>


          <ul>
            <li>any violation of the rights of any third party;</li>
          </ul>


          <ul>
            <li>our reliance on information, data or records provided by you in connection with your use of the
              Website;
            </li>
          </ul>


          <ul>
            <li>the occurrence of any event due to your act, omission or default which compromises the security or
              integrity of the Website or its contents; and/or
            </li>
          </ul>


          <ul>
            <li>your violation of any applicable law or the rights (including but without limitation to intellectual
              property rights and privacy rights) of any other person or entity.
            </li>
          </ul>


          <p>14. <strong><u>CONFIDENTIALITY</u></strong></p>


          <p>You shall, at all times, keep confidential all information provided by us or on our behalf that is marked
            or is by its nature confidential or proprietary (“<strong>Confidential Information</strong>”). You shall
            not disclose or permit to be disclosed the Confidential Information to any third party without our prior
            written consent. These obligations of confidentiality do not apply to information that is publicly
            available, already in your possession without confidentiality restrictions or required to be disclosed by
            order of a court or relevant governmental authority. </p>


          <p>15. <strong><u>ASSIGNMENT</u></strong></p>


          <p>We may assign or transfer all or part of our rights or duties under the Terms provided we take reasonable
            steps to ensure that your rights under the Terms are not prejudiced. You shall not transfer any of your
            rights or duties under the Terms without prior written consent.</p>


          <p>16. <strong><u>APPLICABLE LAW AND JURISDICTION</u></strong></p>


          <p>The law applicable to the Terms of the Website is the law of the Republic of Singapore and the courts of
            the Republic of Singapore shall have exclusive jurisdiction in case of any dispute. Any claim you may have
            against us in connection with these terms or your access and use of the Website must be commenced within
            one (1) year from accrual of the relevant cause of action.</p>


          <p>17. <strong><u>MISCELLANEOUS</u></strong></p>


          <p>If any provision of these terms is found to be unlawful, void or for any reason unenforceable, that
            provision will be deemed severable from these terms and will not affect the validity and enforceability of
            the remaining provisions. These terms constitute the entire agreement between you and us relating to the
            subject matter herein and supersede any and all prior or contemporaneous written or oral representations
            (including information found on the Website). Our failure to insist upon or enforce strict performance of
            any provision of these terms shall not be construed as a waiver of any provision or right. A person who is
            not a party to these terms shall have no right under the Contracts (Rights of Third Parties) Act 2001 to
            enforce these terms. </p>
        </div>
      </div>
    </div>
  )
}

export default Terms;