import React from "react";
import { useLocation  } from 'react-router-dom';

function VideosDetail() {
  const location = useLocation()
  let content;
  /*console.log(location.state.src)
  const onload = (e) => {
    console.log('load')
  }
  const onError = (e) => {
    console.log('error')
  }*/
  if (location.state.type === '1') {
    content = <div className='videos-first common-box'>
      <p>In this explainer video, we hope to educate everyone about how they can start their journey towards attaining carbon neutrality. There are 4 key steps to this:</p>
      <p className='indent'>1.<strong>Measure</strong>: This entails the tracking and counting of your organization’s carbon footprint using various technologies.</p>
      <p className='indent'>2.<strong>Mitigate</strong>: This entails the reduction of emissions by your organization, which you can do so using different methods.</p>
      <p className='indent'>3.<strong>Offset</strong>: This entails offsetting your organization’s residual carbon footprint. However, when deciding on where to purchase carbon credits to do so, it is important to
        ensure that these carbon credits are of the highest integrity, such as those of our <a href="https://www.mvgx.com/videos/what-is-a-carbon-neutrality-token-mvgx-educates/">Carbon Neutrality Token</a>.</p>
      <p className='indent'>4.<strong>Certify</strong>: After going through the first three steps, it is important to verify your company’s carbon neutrality efforts and this can be done through certification companies such as BSI.</p>
      <p className='mail'>Contact us at <a href="mailto:contact@mvgx.com">contact@mvgx.com</a> to find out more!</p>
    </div>
  } else if (location.state.type === '2') {
    content = <div className='videos-second common-box'>
      <p>In this explainer video, we hope to educate everyone about how our Carbon Neutrality Token (CNT™) helps organizations in their pursuit toward net zero. Our CNT™ is of the highest quality, verified and certified by reputable agencies to be units of emissions reduction or carbon removal. These carbon credits are then registered in regional, national or international registries.</p>
      <p>Furthermore, we have developed a proprietary distributed ledger technology to ‘freeze’ these carbon credits at the point of issuance in their host country and then track these carbon credits as they are bought and sold on MVGX. This thus prevents double counting and still enables cross-border trading on the Voluntary Emissions Market without triggering the UN’s Nationally Determined Contribution issues as established in the Paris Agreement. Watch our video to find out more about our CNT™!</p>
    </div>
  } else if (location.state.type === '3') {
    content = <div className='common-box'>
      <p>In this explainer video, we hope to educate everyone about the problems that are plaguing the carbon market. The first problem we addressed in the video is the lack of standardization in the voluntary carbon market as there are too many agencies that offer variations of standards, confusing consumers. The second problem is the lack of technological infrastructure for the movement of voluntary carbon credits. The third problem is the lack of integrity of carbon credits. As such, our Carbon Neutrality Token (CNT™) was developed to address these problems. Watch our video to find out more!</p>
    </div>
  }
  return (
    <div className='videos-detail'>
      <h1>{location.state.title}</h1>
      {/*<iframe title={location.state.title}
              src={location.state.src}
              onLoad={() => {onload()}}
              onError={() => {onError()}}
              frameBorder="0"
              width='750px'
              sandbox="allow-scripts"
              allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share"
              allowFullScreen/>*/}
      <iframe
        title={location.state.title}
        width="750"
        src={location.state.src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen=""/>
      {content}
    </div>
  )
}

export default VideosDetail;