// reducers/index.js
import { combineReducers } from 'redux';
import counterReducer from './counter';
import pageReducer from './pageStore'

const rootReducer = combineReducers({
  counter: counterReducer,
  pageStore: pageReducer
});

export default rootReducer;
