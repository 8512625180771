import React from "react";
import { useLocation  } from 'react-router-dom';
import Back from '../../components/Back/Back';

let curData;
function PressReleaseDetail() {
  const location = useLocation();
  let type = location.state.type;
  if (type === '2') {
    curData =
      <div className="rodller-entry-content container-small">
        <ul>
          <li><em>The Carbon Neutrality Rating System serves to be a vital tool for enterprises to assess their carbon
            output, contribution to emission reduction and green action&nbsp;</em></li>
          <li><em>Partnership will bring rigorous standardisation to enterprise measurement, reporting of carbon emissions
            and carbon neutrality branding&nbsp;</em></li>
          <li><em>System will pave the way for the integration of high-integrity carbon credits, invigorating carbon
            markets across Asia</em></li>
        </ul>

        <p className='common-text'><strong>SINGAPORE &amp; HONG KONG, NOVEMBER 28th, 2022 —&nbsp; </strong><a className='link' href="https://www.tuvsud.com/en-sg">TÜV
          SÜD</a>, a global solutions provider for independent sustainability assessment and certification, announced its
          strategic partnership with MVGX through its Asia Carbon Neutrality Assessment Center (“ACNAC”), to establish a
          holistic carbon neutrality ranking system for enterprises to comprehensively understand their risk and carbon
          neutrality operation levels. ACNAC is a subsidiary of MVGX Holdings, the parent company of <a className='link'
            href="https://www.mvgx.com/">MVGX</a>, a digital green exchange licensed and regulated by the Monetary
          Authority of Singapore (“MAS”). ACNAC is primarily focused on the enterprise assessment of carbon assets, carbon
          footprint, and carbon neutrality ratings.</p>

        <p className='common-text'>Together, TÜV SÜD and ACNAC launched the <strong>Carbon Neutrality Rating System, </strong>a customized
          methodology to evaluate the level of carbon neutrality of organizations. The evaluation system measures
          enterprises’ performance in social contribution, carbon neutrality operations management level, carbon
          neutrality commitment and disclosure, and green supply chain and green investments within the context of carbon
          neutrality. All in all, this data is essential to keeping organizations accountable and informing them of where
          they are in their sustainability journeys, enabling them to effectively track, measure, and monitor the quality
          of their efforts.</p>

        <p className='common-text'>This assessment methodology quantifies the carbon output and emission reduction contribution of the enterprise,
          focuses on tracking and recording a number of carbon neutral operation metrics of the enterprise, so as to
          assess and score the enterprise in a more fair, transparent and professional manner from different dimensions.
          The assessment report and rating need to be verified by the authoritative third-party TÜV SÜD prior to release
          to ensure the authenticity and reliability of the data, thereby enhancing the credibility of the assessment.</p>

        <p className='common-text'>Celebrating the launch of the rating system, <strong>Bo Bai, Executive Chairman and Co-Founder of
          MVGX, </strong>said: “Businesses want to commit to more sustainable action but lack the tools to effectively
          ensure that their efforts pay off. Seeing this gap, we’ve looked to promising new technologies such as
          blockchain to accelerate green action in Southeast Asia and beyond. As a trusted industry pioneer within the
          sustainability space, we are thrilled to be partnering with TÜV SÜD to bring greater integrity to
          enterprise-grade sustainability tracking and rating solutions, making it more accessible for companies of all
          sizes to understand where they are in greening their operations and what more they can do.”</p>

        <p className='common-text'>This partnership is especially timely given that Singapore recently joined the <a
          className='link' href="https://www.nccs.gov.sg/media/press-releases/article-6-implementation-partnership-at-cop27/">Article 6
          Implementation Partnership</a> as a member country at COP27 to encourage international opportunities for
          collaboration in and advancing high integrity carbon markets. Similar to how countries are expected to
          contribute to the development of baseline frameworks and methodologies for carbon markets on both a domestic and
          international level, the creation of the Carbon Neutrality Rating System will play an important role in ensuring
          greater standardization and measurement at a <em>smaller</em> scale, specifically aimed at businesses and
          corporations.</p>

        <p className='common-text'>The Carbon Neutrality Rating System operates according to the following process:</p>

        <ol type="1">
          <li>ACNAC, through its carbon software system, will issue a questionnaire to the company which will then need to
            be completed and supported with additional documentation and self-reported data.<br/>ACNAC and TÜV SÜD will
              then review and rank the company’s carbon operations performance in line with&nbsp; the evaluation
              methodology developed by TÜV SÜD. As the technological infrastructure provider and together its technical
              partner Beijing Zeepson Technology, ACNAC powers the underlying technological infrastructure that is used to
              accurately verify and authenticate the data attesting to a company’s carbon standing.</li>

          <li>From the calculations, TÜV SÜD then ranks the company’s carbon neutrality operations and assigns a final
            score mapped to a tiering system of Diamond, Platinum, Gold, Silver, and Bronze, relative to other
            participating organisations and partners.&nbsp;</li>
        </ol>

        <p className='common-text'>Speaking on the partnership, <strong>Xu Hailiang, Vice President of TÜV SÜD Smart Energy in Greater
          China, </strong>said: “While leaders have sounded the alarm that we are nearing the tipping point of global
          warming, there is still opportunity for recovery. Making tangible change starts with having a clear
          understanding on where firms stand and what more they can do to combat climate change. We hope that companies
          that actively contribute to carbon neutrality and set an example for others can receive higher honors and
          recognition. As such, we are thrilled to partner with innovative changemakers such as MVGX who are at the
          frontier of climate action in Asia and beyond.”</p>

        <p className='common-text'>ACNAC parent company MVGX is a Singapore-based leading fintech company that provides end-to-end
          Carbon-as-a-Service (“CaaS”) solutions, backed by its licensed digital asset exchange platform and
          patent-pending technologies including its Non-Fungible Digital Twin (“NFDT<sup>®</sup>”) and Carbon Neutrality
          Tokens (“CNT<sup>®</sup>”). Backed by blockchain technology, MVGX provides businesses with a transparent,
          tamper-proof record of the performance of all green projects tied to the credits listed on its exchange
          infrastructure. MVGX provides a holistic offering to strengthen opportunities for businesses to achieve carbon
          neutrality in a verified, compliant way.</p>

        <p className='common-text'>Founded in 1866, TÜV SÜD was established in Munich, Germany with a bold vision to reduce the impact of
          technological risks and protect people, assets and the environment. More than 150 years on, TÜV SÜD continues to
          partner with innovative businesses providing internationally-accredited independent product testing services in
          accordance with international standards, and quality auditing and system certification for all industries.</p>

        <p className='common-text'><strong>For media enquiries, please contact&nbsp;<a className='link' href="mailto:pr@mvgx.com">pr@mvgx.com</a></strong></p>
      </div>
  } else if (type === '1') {
    curData =
      <div>
        <div className='common-text'>SINGAPORE — 27 February 2023 — MetaComp Pte. Ltd. (f.k.a. Cyberdyne Tech Services Pte. Ltd), a Major Payment Institution licensed and regulated by the Monetary Authority of Singapore (“MAS”), announced the appointment of financial services veteran John Kang as Chief Financial Officer (CFO). John will be responsible for leading the strategic planning, implementation, and managing of all the firm’s financial activities alongside Executive Chairman and Co-Founder, Dr Bo Bai. John’s responsibilities equally extend to MetaComp’s parent company, MetaVerse Green Exchange (MVGX), the Singapore-based green fintech company that provides end-to-end Carbon-as-a-Service solutions, where he will also be taking on the role of CFO.</div>
        <div className='common-text'>Prior to joining MetaComp, John served as CFO of EMQ Inc, the leading global payments services firm focused on the digital economy, where he oversaw several functions, including accounting, finance, and treasury functions across multiple markets and subsidiaries. Through his tenure, John also closed the company’s Series Seed, Series A, and Series B fundraisings.</div>
        <div className='common-text'>With over two decades of experience in the financial services sector in North Asia, John has held senior leadership positions at world-class banking, hedge fund, and corporate advisory firms including Citigroup Global Markets, Citadel Investment Group, and Goldman Sachs.</div>
        <div className='common-text'>Speaking on his appointment, John said, “Equipped with best-in-class Nasdaq engine and proprietary technologies, MetaComp stands out in the rapidly-crowded digital assets ecosystem. With the team having successfully secured its Major Payments Institution license under the Payment Services Act last year, I’m thrilled to be joining them at this critical stage of their growth as they set the standard for security, compliance, and integrity among crypto and blockchain firms across the globe.”</div>
        <div className='common-text'>“2023 is set to be an exciting year for MetaComp, and bringing John onboard our team is only the start. With his prolific career and expertise across the financial services space, I am confident that John will enable us to continue to grow MetaComp as we continue to expand our client base across the region. Throughout 2022, we already saw a two-fold increase every month in our total payment volumes, and we are only more determined to surpass our financial and business targets this year.,” concluded Dr Bo Bai.</div>
      </div>
  } else if (type === '3') {
    curData =
      <div className="rodller-entry-content container-small">

      <p className='common-text'><em>Chainlink Labs and Singapore-based MVGX Set the Foundation for Strong Future Collaboration</em></p>


      <p className='common-text'>SINGAPORE, November 3, 2022 — Today, <a className='link' href="https://www.mvgx.com/">MetaVerse Green Exchange</a> (“MVGX”), a
        digital green exchange licensed and regulated by the Monetary Authority of Singapore (MAS), announced an

        upcoming collaboration with <a className='link' href="https://chain.link/">Chainlink</a>, the industry-standard Web3 services
        platform. The technology partnership is set to foster greater interconnectedness among sustainability players in
        both the Web2 and Web3 worlds amid growing calls for greater transparency and data-driven measurement standards
        in the carbon reporting space.</p>


      <p className='common-text'>The collaboration will open the door for potential integrations, with support from Chainlink in the form of <a className='link'
        href="https://chain.link/cross-chain">Cross-Chain Interoperability Protocol</a> (CCIP), <a className='link'
        href="https://chain.link/proof-of-reserve">Proof of Reserve</a> (PoR), and dynamic NFTs, to help MVGX securely
        and transparently develop into a next-level Web3 sustainability platform, and to connect the Web3 sustainability
        community with real-world carbon systems in a compliant manner.</p>


      <p className='common-text'>MVGX offers an end-to-end suite of blockchain-based Carbon-as-a-Service solutions backed by its licensed
        digital exchange and patent-pending technologies including its <a className='link'
          href="https://www.mvgx.com/press-release/metaverse-green-exchange-develops-further-applications-of-its-proprietary-non-fungible-digital-twin-nfdt-technology/">Non-Fungible
          Digital Twin </a>(NFDT®) and <a className='link' href="https://www.mvgx.com/carbon-neutrality-token/">Carbon Neutrality
          Token</a> (CNT®), its proprietary digital carbon credit. CNT®s address one of the most challenging aspects of
        the Paris Agreement (COP21) — namely, the ability to properly verify and track carbon credits for cross-border
        trading— effectively injecting greater integrity in VCMs while enabling carbon neutrality at scale and
        facilitating the global goal of net-zero emissions.</p>


      <p className='common-text'>This technology partnership with Chainlink comes on the back of <a className='link'
        href="https://www.mvgx.com/press-release/indonesia-stock-exchange-signs-mou-with-singapore-based-green-fintech-mvgx-to-explore-opportunities-in-developing-carbon-markets">MVGX’s
        exploratory collaboration with Indonesia Stock Exchange</a>. Earlier this week, the country’s national bourse
        announced that it would be working with MVGX to develop carbon trading capabilities. MVGX has seen a number of
        high-profile partnerships with financial institutions this year, including one with <a className='link'
          href="https://www.mvgx.com/press-release/ocbc-bank-and-metaverse-green-exchange-to-develop-new-green-financing-solutions-with-tokenised-carbon-credits/">Southeast
          Asian banking giant Oversea-Chinese Banking Corporation (OCBC Bank) to co-create green finance solutions</a>.
        This past October, MVGX also unveiled the world’s first enterprise finance and carbon-integrated enterprise
        resource planning (ERP) system in collaboration with Oracle NetSuite, the leading cloud-based enterprise
        software company.</p>


      <p className='common-text'>“Governments and businesses across the globe recognize the devastating impact of climate change, but existing
        solutions lack the much-needed transparency to ensure stakeholders that their climate initiatives are having the
        intended impact on the ground,” says Dr. Bo Bai, Executive Chairman, and Co-Founder at MVGX. “We are thrilled to
        be embarking on this partnership with Chainlink as we recognize the vital role it will play in enabling us to
        empower our customers and clients with greater integrity, veracity, and accountability in the green finance and
        broader ESG ecosystem, in both Web2 <em>and</em> Web3 worlds.”</p>


      <p className='common-text'>Chainlink’s technology has continued to grow in popularity among sustainability-focused companies, with
        Accuweather providing weather data through Chainlink, builders like Arbol and Hyphen utilizing the network’s
        technology, and Chainlink’s role in the Lemonade Foundation’s Crypto Climate Coalition. The announcement with
        MVGX opens the door to expand on Chainlink’s ability to impact the sustainability space in a positive way.</p>


      <p className='common-text'>“MVGX has a lot of potential,” says Niki Ariyasinghe, Global Head of Partnerships at Chainlink Labs. “The
        company has spent the last few years building a strong foundation for growth, and I believe that this is a
        long-term collaboration with a lot of potentially productive components. I’m looking forward to our work
        together.”</p>


      <p className='common-text'><strong>For media enquiries, please contact&nbsp;<a className='link' href="mailto:pr@mvgx.com">pr@mvgx.com</a>.</strong></p>

    </div>
  } else if (type === '4') {
    curData =
      <div className="rodller-entry-content container-small">

        <ul>
          <li><em>Physical card pre-paid with blockchain-based carbon credits allows businesses to instantly mitigate
            hard-to-manage emissions such as travel</em></li>
          <li><em>Designed to be an affordable, accessible way for corporates and their employees to engage in positive
            climate action</em></li>
          <li><em>Tackles the “Nationality Problem” of carbon credits by allowing voluntary offsets without triggering
            Nationally Determined Contributions</em></li>
        </ul>


        <p className='common-text'><strong>SINGAPORE, June 16, 2022 — </strong><a className='link' href="http://www.mvgx.com">MetaVerse Green
          Exchange</a> (“MVGX”), a digital green exchange licensed and regulated by the Monetary Authority of Singapore
          (MAS), announced the launch of a new tool, the <em>Net Zero Card</em>, in its suite of products and services
          to enable employees to easily support and reinforce an organization’s sustainability commitments.</p>


        <p className='common-text'>The <em>Net Zero Card </em>was recently piloted for the very first time at DLD Munich and at the 2022 Annual
          Meeting at Davos for the World Economic Forum, where MetaVerse Green Exchange Executive Chairman and
          Co-Founder Dr. Bo Bai offset 3 tons of CO2 — equivalent to the entirety of his round-trip journey from
          Singapore to Munich and Davos as well as hotel stays. Attendees to MVGX’s <em>Afternoon Tea
            Session</em> during the World Economic Forum received these cards as a door gift and were also encouraged to
          neutralize their trip to Davos, which resulted in a total of 7.3 tons of CO2 neutralized. To mark the soft
          launch of the <em>Net Zero Card</em>, MVGX is working with a couple of organizations to release an initial
          batch of 700 cards at the end of June.</p>


        <p className='common-text'>Commenting on this latest corporate initiative, <strong>Dr. Bo Bai, Executive Chairman and Co-Founder of
          MetaVerse Green Exchange</strong>, said: “If the past year is any indication, the climate crisis has fully
          transitioned into a climate emergency and we, as a collective business community, need to take action in any
          way that we can. Reduction is a crucial piece of the puzzle, however, there needs to be a simpler and more
          streamlined way to offset the emissions that remain. We created the <em>Net Zero Card</em> to make it easier
          for businesses across the globe — no matter the industry or their size — to offset their carbon footprint,
          across all their activities in an accessible, affordable way.”</p>


        <p className='common-text'>The customizable <em>Net Zero Card </em>is a pre-paid, white-label solution that is designed for different
          situations in which a business may want to offset or enable their clients to offset their carbon footprint on
          a short-term basis. Comparable to an EZ-link travel card, the <em>Net Zero Card </em>marks MVGX’s first
          offering that applies to retail through their institutional customers. For its initial launch, the <em>Net
            Zero Card</em> will solely support travel-related applications within Singapore such as commuting to work or
          to events with public or private transportation. This is especially timely as domestic restrictions
          surrounding in-person events and enforced remote working lift. In fact, with nearly a million vehicles
          traveling on Singapore’s roads releasing <a className='link'
            href="https://www.straitstimes.com/singapore/environment/green-vehicles-add-power-to-the-fight-against-climate-change">6.4
            million tons of CO2</a> each year, domestic travel equates to over a tenth of the city-state’s total
          emissions.</p>


        <p className='common-text'>Each <em>Net Zero Card</em> is equipped with a near-field communication (NFC) tag which can be scanned with a
          smartphone. If the NFC reader has been switched off by the user for privacy reasons, a QR code printed on the
          gift card can also be used to access a landing page that will allow the user to calculate their emissions
          based on the duration of travel and the mode of transportation in the <em>Net Zero Diary</em>. Accompanying
          the <em>Net Zero Card</em> is the soon-to-be-launched mobile app, which will leverage the user’s smartphone’s
          geo-tracking capabilities to track and measure their carbon footprint throughout their journey. Once the
          emissions have been calculated, MVGX will retire the corresponding amount of Carbon Neutrality Tokens
          (CNTs<sup>TM</sup>) based on the prepaid amount on the card, with traceable and immutable records on the
          blockchain.</p>


        <p className='common-text'>The patent-pending<em> Net Zero Card </em>is backed by MVGX’s asset-backed CNT<sup>TM</sup> which are tied to
          Voluntary Emission Reductions (VERs). Backed by proprietary protocols and blockchain technology,
          CNTs<sup>TM</sup> are designed to combine the immutability of non-fungible tokens with real-time data updates,
          offering an immutable and continuously updated record of carbon performance.</p>


        <p className='common-text'>CNTs<sup>TM</sup> address one of the most challenging aspects of the Paris Agreement (COP21) — namely, the
          “Nationality Problem” of carbon credits — enabling carbon neutrality at scale and facilitating the global goal
          of Net Zero emissions without triggering Nationally Determined Contributions.</p>


        <p className='common-text'>“A company’s sustainability strategy should not only be a matter of corporate accountability but also
          individual responsibility. A critical roadblock in mobilizing climate action is often when people are unable
          to truly visualize the full negative impact of their behavior on the environment. With the <em>Net Zero Card
            and Net Zero Diary</em>, we hope that people can come to realize that every action — as much as inaction —
          matters when it comes to securing the future of a greener planet,” continued Dr. Bai.</p>


        <p className='common-text'>VERs play a significant role in supporting carbon-reduction projects and as of 2021, the voluntary carbon
          market topped US$1 billion, pointing to the growing appetite for green financing opportunities across
          businesses of all sizes as they work to keep to their sustainability commitments. This appetite is equally
          reflected among employees — according to a <a className='link'
            href="https://www.unily.com/insights/guides/future-of-the-sustainable-workplace-in-the-age-of-covid-19-and-climate-change">Unily
            survey</a>, 65 percent of surveyed respondents expressed that they would be more likely to work for a
          company with robust environmental policies.</p>


        <p className='common-text'>The <em>Net Zero Card</em>’s applications are going to be more important due to the resumption of
          large-scale, in-person corporate events as borders across the world gradually reopen, inevitably resulting in
          a surge of international business travel, pointing to its enduring relevance for businesses. While global
          carbon emissions <a className='link'
            href="https://www.nbcnews.com/science/environment/pandemic-show-us-can-cut-carbon-emissions-sort-rcna715">dropped
            by 7 percent</a> in 2021 due to ongoing lockdowns, aviation continues to account for <a className='link'
            href="https://www.channelnewsasia.com/commentary/air-travel-reduce-carbon-emissions-flights-sustainable-aviation-fuel-2579681">up
            to 3 percent</a> of all carbon emissions globally.</p>


        <p className='common-text'>In light of this, MVGX’s <em>Net Zero Card</em> is now available for businesses across Singapore for purchase
          as they empower their employees and clients to play an active role in strengthening their company’s
          sustainability strategy as the emphasis on positive climate action intensifies.</p>


        <p className='common-text'>For media enquiries contact <a className='link' href="mailto:pr@mvgx.com">pr@mvgx.com</a>. </p>

      </div>
  } else if (type === '5') {
    curData =
      <div className="rodller-entry-content container-small">

        <p className='common-text'><a className='link' href="http://www.mvgx.com">MetaVerse Green Exchange</a> (“MVGX”), a digital green exchange licensed and
          regulated by the Monetary Authority of Singapore (“MAS”), announced the appointment of former Bank of England
          Senior Advisor Michael Sheren as President and Chief Strategy Officer (CSO) as the firm strengthens its
          end-to-end suite of Carbon as a Service (CaaS) products to customers across the globe.</p>


        <p className='common-text'>In his capacity as President and CSO, Michael will drive the promotion of MVGX’s CaaS offerings
          internationally amid growing calls for greater climate action. Based in Singapore and Europe, Michael will be
          responsible for developing MVGX’s new climate and environmental advisory services which will enable the firm
          to collaborate with businesses, government agencies, and policymakers to drive sustainability initiatives
          within the Southeast Asian region and beyond.</p>


        <p className='common-text'>Michael’s appointment comes as Southeast Asia reaffirms its commitment to accelerate sustainable finance in
          one of the most <a className='link'
            href="https://www.straitstimes.com/singapore/south-east-asia-among-regions-hardest-hit-by-climate-change-must-prioritise-adaptation-ipcc">at-risk
            regions</a> from climate change. Most recently, the Asian Development Bank signed a memorandum of
          understanding with the Government of the United Kingdom to develop a <a className='link'
            href="https://www.adb.org/news/134-million-uk-fund-help-catalyze-green-financing-southeast-asia">US$134
            million trust fund</a> to scale green financing and adopt low-emission, climate-resilient development in
          ASEAN countries ahead of COP27.</p>


        <p className='common-text'>Speaking on his appointment, Michael said, “Home to some of the most vibrant cities and financial hubs,
          Southeast Asia has a thriving green sector set to become a <a className='link'
            href="https://www.businesstimes.com.sg/asean-business/south-east-asias-green-sector-worth-us1t-by-2030-but-only-us15b-invested-bain-temasek">US$1
            trillion economic opportunity</a> by 2030. I am thrilled to tap into the region’s sustainability
          opportunities and to join a company such as MVGX that is at the forefront of emerging sustainability
          solutions, working together with leaders that share a collective vision of realizing a greener tomorrow.”</p>


        <p className='common-text'>Prior to joining MVGX, Michael was a Senior Advisor at the Bank of England (“BoE”) for nearly a decade,
          leading much of the Bank’s sustainable finance and environmental risk management work. Further, Michael
          co-founded the BoE’s fintech accelerator programme. On behalf of BoE and the UK government, Michael was also
          the&nbsp; Co-Chair of the G20 Sustainable Finance Study Group with China from 2016-2019, driving domestic and
          international green finance initiatives. In his twenty-five years of private sector banking before joining the
          BoE, Michael held leadership roles at JPMorgan Chase, GE Capital, and Credit Agricole CIB among other
          financial firms.</p>


        <p className='common-text'>Bolstered by Michael’s extensive industry leadership, MVGX will be strengthening its suite of CaaS offerings
          which includes existing and upcoming products and services such as <a className='link'
            href="https://www.mvgx.com/carbon-neutrality-connect/">Carbon Neutrality Connect</a>, <a className='link'
            href="https://www.mvgx.com/carbon-neutrality-management-system/">Carbon Management System</a>, climate and
          environmental consulting services, <a className='link' href="https://www.mvgx.com/carbon-neutrality-token/">Carbon Neutrality
            Tokens (CNTs</a><a className='link'
            href="https://www.mvgx.com/press-release/metaverse-green-exchange-develops-further-applications-of-its-proprietary-non-fungible-digital-twin-nfdt-technology/"><sup>TM</sup></a><a
            href="https://www.mvgx.com/press-release/metaverse-green-exchange-develops-further-applications-of-its-proprietary-non-fungible-digital-twin-nfdt-technology/">)</a>,
          and the <a className='link' href="https://www.mvgx.com/net-zero-card/">Net Zero Card and Diary</a>. MVGX is also leveraging its
          proprietary blockchain systems to build an end-to-end carbon Enterprise Resource Planning (“ERP”) system,
          strengthening opportunities for businesses to achieve carbon neutrality in a verified, compliant way.</p>


        <p className='common-text'>With a prolific career championing sustainable finance and development across the world, Michael will
          continue his roles as Senior Advisor to the United Nations Development Program Finance Hub, shaping
          sustainable investment strategies to achieve the UN Sustainable Development Goals; Emeritus Governor at the
          London School of Economics; and a Price of Wales Fellow at Cambridge University’s Cambridge Institution for
          Sustainable Leadership. Michael has also had an accomplished academic career, holding Master’s degrees from
          the Harvard Kennedy School, the London School of Economics and Political Science, and New York University, and
          a Bachelor of Arts from George Washington University.</p>


        <p className='common-text'>Welcoming Michael, <strong>Dr Bo Bai, Executive Chairman and Co-Founder of MetaVerse Green Exchange </strong>said,
          “We are excited to welcome Michael to join MVGX. With a wealth of experience from traditional financial
          services and portfolio management, to public policy and international finance, development, and economics,
          Michael’s multi-faceted background will bolster MVGX’s capabilities and contribute greatly to the
          still-nascent sustainability sector in Asia. As MVGX continues to grow, Michael will play an essential role in
          strengthening our position as a leader in green solutions, bringing us a step towards the global goal of
          reaching net zero, and championing a more climate-conscious future generation.”</p>


        <p className='common-text'>Founded in 2018 and based in Singapore, MetaVerse Green Exchange (“MVGX”) is a leading FinTech company
          providing end-to-end Carbon-as-a-Service solutions, backed by its licensed digital asset exchange platform and
          patent-pending Metaverse technologies including Non-Fungible Digital Twin (NFDT<sup>TM</sup>) and Carbon
          Neutrality Token (CNT<sup>TM</sup>). MVGX has built a world-class digital exchange platform with the
          best-in-class Nasdaq engines, MVGX proprietary digital asset ledger with carbon footprint tag, and rigorous
          compliance processes. MVGX is committed to bridging carbon trading markets from different jurisdictions and
          bridging illiquid assets in the real economy with wider investor accessibility.</p>


        <p className='common-text'><strong>For media enquiries, please contact <a className='link' href="mailto:pr@mvgx.com">pr@mvgx.com</a>.</strong></p>

      </div>
  } else if (type === '6') {
    curData =
      <div className="rodller-entry-content container-small">

        <ul>
          <li><em>IDX is preparing a trading system for a carbon exchange in Indonesia</em></li>


          <li><em>MVGX to provide IDX with opportunities to explore its industry-grade software to establish IDX’s
            carbon exchange capabilities</em></li>


          <li><em>Both parties are exploring opportunities for Indonesian carbon trading development.</em></li>
        </ul>


        <p className='common-text'><strong>SINGAPORE &amp; JAKARTA, NOVEMBER 1 2022 —&nbsp;</strong><a className='link' href="https://www.idx.co.id/">Indonesia
          Stock Exchange</a> (“IDX”), the country’s national securities exchange and among the fastest growing in the
          continent, is exploring the possibility of working with <a className='link' href="http://www.mvgx.com">MetaVerse Green
            Exchange</a> (“MVGX”), a digital green exchange licensed and regulated by the Monetary Authority of
          Singapore (MAS), to develop Indonesia’s carbon exchange system.</p>


        <p className='common-text'>This development comes amid the Indonesian government’s <a className='link'
          href="https://www.esdm.go.id/en/media-center/news-archives/uji-coba-perdagangan-karbon-diikuti-80-pembangkit-">plan
          to launch</a> the country’s own emissions trading scheme by <a className='link'
          href="https://www.reuters.com/business/cop/indonesia-introduces-carbon-trading-policy-reduce-emission-2021-11-15/">2025</a> to
          incentivize domestic businesses in high-emitting industries to reduce their emissions. To date, Indonesia is
          among 61 countries with <a className='link'
            href="https://www.reuters.com/business/cop/indonesia-introduces-carbon-trading-policy-reduce-emission-2021-11-15/">established
            carbon pricing regulations</a> and has committed to reducing its greenhouse gas emissions by 43 percent by
          2030 with international support, whether through financing or new technologies. Indonesia itself has made good
          on its sustainability promises, having cut 10.37 million tons of carbon dioxide from its energy plants in 2021
          alone — <a className='link'
            href="https://www.esdm.go.id/en/media-center/news-archives/indonesia-cuts-1037-million-tonnes-of-carbon-emissions-from-power-plants-in-2021">210.8%</a> of
          its initial target.</p>


        <p className='common-text'>Commenting on the partnership, <strong>Jeffrey Hendrik, IDX Director of Business Development, </strong>said:
          “The Indonesian government has recognized the vital role that the financial services industry can play in
          strengthening the country’s sustainability commitments. IDX is currently preparing for the possibility of
          becoming a carbon exchange in Indonesia and started discussions with several parties to deepen our knowledge.
          We believe that this strategic partnership with MVGX can support us in building a robust carbon exchange
          infrastructure and ecosystem that will underpin the country’s plan to develop a sustainable finance
          ecosystem.”</p>


        <p className='common-text'><strong>Bo Bai, Executive Chairman and Co-Founder of MVGX, said: </strong>“At MVGX, our goal is to build an
          ecosystem of secure, compliant carbon trading systems across the world in order to <em>empower and
            enable</em> businesses and governments to reach their sustainability goals. Today, carbon markets still
          continue to face challenges around lack of transparency, standardization, and liquidity — we are thrilled to
          be supporting IDX as they work towards meeting this critical milestone as part of Indonesia’s climate policy
          and as they raise the bar for green finance in the region.”</p>


        <p className='common-text'>MVGX leverages blockchain technology which offers a transparent, tamper-proof record of the performance of
          all green projects tied to the credits listed on its exchange infrastructure. This will give buyers and
          issuers the utmost assurance of the integrity of the carbon credits listed on IDX’s registry. When used for
          carbon neutrality verification by businesses, these credits will be certified in accordance with
          internationally-recognized gold standards for carbon footprint validation in line with <a className='link'
            href="https://www.bsigroup.com/en-IN/CFV-Carbon-Footprint-Verification/">ISO 14064-1:2018 Carbon Footprint
            Verification</a> and<a className='link' href="https://www.bsigroup.com/en-SG/PAS-2060-Carbon-Neutrality/"> </a><a className='link'
            href="https://www.bsigroup.com/en-SG/PAS-2060-Carbon-Neutrality/">PAS 2060 Carbon Neutrality
            Verification</a><a className='link' href="https://www.bsigroup.com/en-SG/PAS-2060-Carbon-Neutrality/">.</a></p>


        <p className='common-text'><strong>Michael Sheren, President of MVGX, former Senior Advisor for the Bank of England, former Co-Chair of
          the G20 Sustainable Finance Study Group, said: </strong>“Asia is set to be among the hardest hit by the impact
          of climate change, and it’s estimated that<a className='link'
            href="https://www.businesstimes.com.sg/companies-markets/us2t-in-investments-needed-over-next-decade-to-green-south-east-asias"> US$2
            trillion</a> in sustainable financing will be needed over the next decade in Southeast Asia alone to pave
          the way for the sustainable transition towards achieving net-zero. Home to a third of the world’s rainforests,
          Indonesia has made meaningful strides in its deforestation mitigation efforts, and in working with IDX, we’re
          excited for this opportunity to support the country on its next climate-related milestone as it looks to
          develop and launch its own carbon trading registry.”</p>


        <p className='common-text'>IDX is a Self-Regulatory Organization that aims to be a competitive stock exchange equipped with world-class
          credibility that holds membership titles with the World Federation of Exchanges and Sustainable Stock
          Exchanges. As one of the fastest-growing stock exchanges in the continent, IDX has more than 800 listed
          companies on its exchange and a total of over 4.2 million investors on the exchange.</p>


        <p className='common-text'><strong>Irvan Susandy, IDX Director of Trading and Membership, said: </strong>“Indonesia has so many
          potential nature-based solutions. With a fully operational carbon trading infrastructure in place, businesses
          and governments will be able to trade carbon credits all while identifying high-quality climate projects. This
          partnership with MVGX will enhance IDX capacity and capability to design carbon trading exchange as
          Indonesia’s efforts toward building a climate-resilient future.”</p>


        <p className='common-text'>MVGX is a leading FinTech company that provides end-to-end Carbon-as-a-Service solutions, backed by its
          licensed digital asset exchange platform and patent-pending technologies including its Non-Fungible Digital
          Twin (NFDT<sup>®</sup>) and CNT<sup>®</sup>), its proprietary digital carbon credit. CNT<sup>®</sup>s address
          one of the most challenging aspects of the Paris Agreement (COP21) — namely, the ability to properly verify
          and track carbon credits — enabling carbon neutrality at scale and facilitating the global goal of net-zero
          emissions.</p>


        <p className='common-text'>MVGX has built a world-class digital exchange platform with the best-in-class Nasdaq engines, MVGX
          proprietary digital asset ledger with a carbon footprint tag, and rigorous compliance processes. MVGX is
          committed to bridging carbon trading markets from different jurisdictions and bridging illiquid assets in the
          real economy with wider investor accessibility.</p>


        <p className='common-text'><strong>For media enquiries, please contact&nbsp;<a className='link' href="mailto:pr@mvgx.com">pr@mvgx.com</a>.</strong></p>

      </div>
  } else if (type === '7') {
    curData =
      <div className="rodller-entry-content container-small">

        <ul>
          <li><em>Driven by increasing investor desire for crypto assets and cross-border digital carbon credits on
            regulated and licensed platforms&nbsp;</em></li>
          <li><em>Singapore-China collaboration marks first step towards building a connected international network of
            digital carbon trading exchanges&nbsp;</em></li>
          <li><em>Strengthens Singapore’s position as a global hub for digital green finance in the Metaverse era</em>
          </li>
        </ul>


        <p className='common-text'><a className='link' href="https://www.greenlandfinancial.com">Greenland Financial Technology Group</a> (Shanghai) (“Greenland
          Financial Technology”), a core platform of Greenland Group, a global Fortune 500 company and one of Shanghai’s
          largest state-backed enterprises focusing on real estate, infrastructure and finance, and&nbsp;<a className='link'
            href="https://www.mvgx.com/">MetaVerse Green Exchange</a>&nbsp;(“MVGX”), a digital green exchange licensed
          and regulated by the Monetary Authority of Singapore (MAS), have formed a strategic partnership to meet
          increasing investors’ desire for cross-border digital carbon credits and crypto assets on regulated and
          licensed platforms.&nbsp;</p>


        <p className='common-text'>In working together, MVGX and Greenland Financial Technology will leverage blockchain-powered innovation and
          combine financial service capabilities from their respective licensed digital green exchange and licensed
          digital bank to serve clients’ increasing demand for accessible crypto assets and digital carbon credits that
          combine with cross-border trading capabilities with the security and oversight of on regulated and licenced
          platforms.&nbsp;</p>


        <p className='common-text'>The Singapore-China collaboration through MVGX and Greenland Financial Technology’s controlled subsidiary,
          Guizhou Green Finance and Emissions Exchange (GGFEX), also marks the critical first step towards MVGX’s
          mission of building a connected international network of carbon trading exchanges using its proprietary <a className='link'
            href="https://www.mvgx.com/carbon-neutrality-token/" data-type="page" data-id="379">Carbon Neutrality
            Tokens</a> (CNT<sup>TM</sup>) designed for cross-border carbon credit trading.&nbsp;</p>


        <p className='common-text'>In addition, they will jointly promote tokenization, or digital securitization, of green infrastructure
          assets and green buildings, creating channels for international capital to finance green infrastructure
          projects in China and other developing countries with aspirations to achieve carbon neutrality.&nbsp;</p>


        <p className='common-text'>Asset tokenization involves creating digital tokens that are issued on a blockchain, serving as a
          non-fungible digital representation of either digital or physical assets in the metaverse. With blockchain,
          Greenland Financial Technology and MVGX customers can be assured that these tokens are verifiably and
          immutably owned by the owners, improving both asset liability management and giving greater clarity and
          protections for investors. Blockchain can also power a more efficient real-time trading experience, enabled by
          ​​faster settlement times.&nbsp;</p>


        <p className='common-text'>Commenting on the collaboration, Dr. Geng Jing, the Director and Executive President of Greenland Group,
          Chairman and President of Greenland Financial Technology Group and Chairman of Greenland Digital Technology
          Co. Ltd., said: “The growth of emerging technologies such as blockchain have brought about new applications
          that are radically transforming how easily investors can access new asset classes including both crypto assets
          and digital carbon credits, all the while ensuring greater standards of security, transparency, and
          traceability as they trade. This cooperation with MVGX allows us to safely explore and experiment with the
          potential of asset tokenization and cross-border digital carbon trading as we continue to raise the bar for
          financial innovation as an organization as well as facilitating the formation of a global interconnected
          carbon trading network.”</p>


        <p className='common-text'>MVGX will also be working with GGFEX, as the preferred partner to assist GGFEX to establish the most advanced
          digital carbon trading platform and registry arising from carbon assets in China — especially China Certified
          Emission Reduction (CCER) projects in the real estate and green infrastructure industries. This will be done
          through MVGX’s CNT<sup>TM</sup>&nbsp;which are underscored by its proprietary <a className='link'
            href="https://www.mvgx.com/press-release/metaverse-green-exchange-develops-further-applications-of-its-proprietary-non-fungible-digital-twin-nfdt-technology/"
            data-type="press-release" data-id="323">Non-Fungible Digital Twin (NFDT™) technology</a> to combine the
          immutability of NFTs with real-time data updates, offering an immutable and continuously updated record of
          carbon performance. With CNTs<sup>TM</sup>, GGFEX will be able to create a secure carbon registry that can set
          the benchmark for other VER markets in the region.&nbsp;</p>


        <p className='common-text'>Dr. Bo Bai, Executive Chairman and Co-Founder of MVGX, said: “This strategic collaboration with
          Greenland Financial Technology and GGFEX, by extension, marks a pivotal first step in our aspirations of
          creating an interconnected web of carbon exchanges. As we continue to strengthen our network with other
          international entities who share our vision of increasing the accessibility of green investment opportunities,
          we’re laying down the very foundation on which businesses across developing economies will soon be able to
          easily meet their sustainability commitments. From mature economies to emerging markets, we’re building a
          network that’s set to redefine how the cross-border trading of carbon credits can take place in a seamless,
          traceable way. At MVGX, we’re proud to showcase the meaningful work being done here in Singapore’s
          sustainability ecosystem as the country solidifies its position as a global green financial center, as a
          result of a rigorous and progressive regulatory environment, booming entrepreneurship and thriving
          technological advancement.”</p>


        <p className='common-text'>In coming together, this strategic cooperation between MVGX and Greenland Financial is a testament to
          Singapore’s unique position as a pioneering innovation hub in areas of digital green finance. As MVGX and
          Greenland Financial Technology work together, their clients will also benefit from access to
          sustainability-linked financial products, strengthening the ties between both countries as leaders in green
          finance as they work towards meeting their net-zero targets.</p>

        <p className='common-text'><strong>About Greenland Financial Technology Group (Shanghai)</strong></p>


        <p className='common-text'>Greenland Financial Technology Group is a global financial technology enterprise group headquartered in
          Shanghai, China. It is a subsidiary of the Fortune 500 company Greenland Group, with its focus on digital
          technology, finance, education, health care, science and technological innovation,and other diversified
          industries to promote digitalization.&nbsp;Leveraging on Greenland Financial Holdings Group, Greenland
          Financial Technology Group is a data-driven, fully-licensed digital company that covers the full supply
          chain. The integration&nbsp;</p>


        <p className='common-text'>of its licenses strengthens the synergy between finance and various industries, with data driving the core
          of the business through digital technology while continuing to improve the mission of “Investment +
          Incubation + Risk Management” which has seen the group earn annual profits in tens of billions of
          RMB. &nbsp;</p>


        <p className='common-text'>The Guizhou Green Finance and Emissions Exchange (“GGFEX”) under Greenland Financial Technology Group is an
          exchange approved and supervised by the Guizhou Local Financial Supervision and Administration Bureau. Asset
          trading venues have been transformed into environmental rights trading venues, committed to creating an
          integration of environmental rights trading such as carbon emission rights, pollution discharge rights, and
          energy use rights, and integrating green development services such as green financial services, green
          ecological services, and green building services – to become an all-in-one environmental rights trading
          center.&nbsp;</p>


        <p className='common-text'><strong>For media enquiries, please contact </strong><a className='link' href="mailto:pr@mvgx.com">pr@mvgx.com</a>.</p>

        <p className='common-text'><em>Image: Greenland Financial Technology Group</em></p>
      </div>
  } else if (type === '8') {
    curData =
      <div className="rodller-entry-content container-small">

      <h3 className="wp-block-heading"><em>This partnership between the two Singapore firms aims to help accelerate
        corporates’ journey to carbon neutrality</em></h3>


      <p className='common-text'>OCBC Bank and MetaVerse Green Exchange (MVGX), a digital green exchange licensed and regulated by the Monetary
        Authority of Singapore, have announced a strategic partnership to develop new green financing solutions aimed at
        accelerating large corporates’ journey to carbon neutrality.</p>


      <p className='common-text'>Scheduled to be launched later this year, these financing solutions will include tokenised carbon credits in
        the form of MVGX’s <a className='link' href="https://www.mvgx.com/carbon-neutrality-token/">Carbon Neutrality Tokens</a> (CNT™s),
        for large orporates to offset their carbon emissions, simplifying their path to carbon neutrality. An
        independent party will verify the expected carbon emissions from projects utilising these financing solutions,
        to calculate the corresponding carbon credits required.</p>


      <p className='common-text'>MVGX’s CNT™s are supported by MVGX’s proprietary <a className='link'
        href="https://www.mvgx.com/press-release/metaverse-green-exchange-develops-further-applications-of-its-proprietary-non-fungible-digital-twin-nfdt-technology/">Non-Fungible
        Digital Twin</a> (NFDT™) distributed ledger technology that provides corporates a verifiable, immutable and
        constantly updated record of the carbon performance of the climate-action projects that they have invested in
        through these digital carbon credits.</p>


      <p className='common-text'>The partnership comes at an opportune time when carbon credits are becoming an increasingly popular solution
        for corporates after they have explored other decarbonisation options to achieve carbon neutrality. This is
        particularly important for companies in hard-to-abate industries such as shipping, steel and energy. By
        purchasing such carbon credits, corporates are also investing in green projects such as reforestation and
        renewable energy that contribute to future decarbonisation. The Institute of International Finance’s Taskforce
        on Scaling Voluntary Carbon Markets estimates that the global demand for carbon credits could increase
        fifteen-fold by 2030.</p>


      <p className='common-text'>The ability to properly account for and track carbon credits under existing systems has resulted in challenges
        which have prevented uptake and impact at scale, such as double counting and the difficulties involved with
        cross-border carbon trading. The CNT™s provided in the new green financing solutions can mitigate these
        challenges by providing businesses with a reliable and accurate view of their emissions and offsets.</p>


      <p className='common-text'>Ms Elaine Lam, Head, Global Corporate Banking, OCBC Bank, said: “With the recently released report by the
        United Nations’ Intergovernmental Panel of Climate Change (IPCC), there will be increased urgency in corporates’
        transition to a low carbon future by cutting down greenhouse gas emissions. We hope to accelerate these efforts
        by providing financing solutions with tokenised carbon credits, and expand the reach of private-sector finance
        in areas and sectors most crucial in mitigating climate change.”</p>


      <p className='common-text'>This partnership underscores the Bank’s commitment to combating climate change and supporting customers in
        their journey to carbon neutrality, by seizing growth opportunities in green and sustainable financing. By
        end-2021, the Bank had extended over $34 billion in sustainable financing to customers, surpassing its original
        target of S$25 billion by 2025 four years ahead of schedule. A new target of S$50 billion in sustainable
        financing commitments by 2050 has been established.</p>


      <p className='common-text'>Commenting on the company’s landmark partnership, MVGX Executive Chairman and Co-Founder Bo Bai, said: “Despite
        the best intentions, governments and businesses around the world have come to realise the limitations of the
        current systems for tracking and neutralising carbon emissions. Thankfully, there is now a greater urgency to
        embrace new solutions that leverage technology to promote carbon reduction and finance green initiatives. By
        joining forces with Southeast Asia’s second-largest bank, we have the opportunity to advance our sustainability
        ambitions and fast-track our nation’s goal of achieving Singapore’s 2030 Green Plan. We are excited to be
        building the bridge between green investments in traditional finance and the global carbon trading markets of
        the future through green digital assets.”</p>


      <p className='common-text'>Last year, MVGX launched its first batch of CNT™s ahead of COP26 in Glasgow. These asset-backed tokens were
        tied to carbon credits generated by a wind project in Zhangjiakou, China that was verified and registered with
        China’s National Carbon Registry. The first tranche of 5,000 carbon credits was sold to a Hong Kong-based
        private equity firm last year, lowering barriers to access ESG assets for retail and institutional investors in
        Asia Pacific.</p>


      <p className='common-text'>For media queries, please contact:</p>


      <table>
        <tbody>
        <tr>
          <td><strong>Dawn Sin</strong></td>
          <td><strong>Ow Yong Weng Leong</strong></td>
        </tr>
        <tr>
          <td>Vice President
            Group Brand and Communications
            OCBC Bank
          </td>
          <td>Vice President
            Group Brand and Communications
            OCBC Bank
          </td>
        </tr>
        </tbody>
      </table>


      <p className='common-text'><strong>Melissa Fann</strong><br/>Marketing &amp; Communications Manager<br/>MetaVerse Green Exchange<br/><a className='link'
        href="mailto:pr@mvgx.com">pr@mvgx.com</a></p>

    </div>
  } else if (type === '9') {
    curData =
      <div className="rodller-entry-content container-small">

      <ul>
        <li><em>Red Dot Analytics and MetaVerse Green Exchange set to introduce carbon neutral data center operations,
          backed by AI and blockchain-enabled technologies</em></li>
        <li><em>Introduces greater transparency and verifiability in energy measurement and carbon offsetting efforts
          for cloud-powered businesses</em></li>
        <li><em>Partnership comes amid demands for greater energy-efficiency in data centers as 2019 moratorium on new
          data center projects lifts in Q2 2022</em></li>
      </ul>


      <p className='common-text'><a className='link' href="http://www.mvgx.com/" target="_blank" rel="noreferrer noopener">MetaVerse Green
        Exchange</a>&nbsp;(“MVGX”), a digital green exchange licensed and regulated by the Monetary Authority of
        Singapore (MAS) and industrial AI pioneer&nbsp;<a className='link' href="https://www.rda.ai/" target="_blank"
                                                          rel="noreferrer noopener">Red Dot Analytics</a>&nbsp;(“RDA”),
        a deep tech spin-off from Nanyang Technological University (NTU), Singapore, have announced a strategic
        collaboration to enable greater sustainability in data center development and operations. Both RDA and MVGX will
        be working together to verifiably measure and offset the carbon footprint of data centers in tropical
        environments, driving data center operations towards a sustainable future.</p>


      <p className='common-text'>With digitalization taking place at unprecedented rates as businesses modernize operations, data centers today
        play a critical role in powering the infrastructure of organizations across the globe. It’s estimated that data
        centers account for&nbsp;<a className='link'
          href="https://www.mordorintelligence.com/industry-reports/global-data-center-cooling-market-industry"
          target="_blank" rel="noreferrer noopener">up to 5 percent</a>&nbsp;of global greenhouse gas emissions, with
        cooling systems responsible for 40 percent of all power consumption. However, the energy expenditure of data
        centers is often neglected when companies and governments alike look to offset their carbon footprints in
        broader efforts to meet net-zero commitments.</p>


      <p className='common-text'>In Singapore alone, data centers were reported to contribute&nbsp;<a className='link'
        href="https://www.datacenterdynamics.com/en/news/communications-minister-confirms-singapores-data-center-pilot-program-to-accept-applications-from-q2/"
        target="_blank" rel="noreferrer noopener">7 percent</a>&nbsp;of the city-state’s electricity consumption which
        is still largely based on natural gas, prompting a moratorium on new data center projects since 2019 amid
        growing environmental concerns. While the moratorium is&nbsp;<a className='link'
          href="https://www.businesstimes.com.sg/companies-markets/new-data-centres-in-singapore-to-meet-higher-standards-when-moratorium-lifts-in-q2"
          target="_blank" rel="noreferrer noopener">set to lift in Q2 2022</a>&nbsp;as part of a pilot program, the
        Singapore government is introducing new efficiency standards in line with the country’s overall 2030 Green Plan.
      </p>


      <p className='common-text'>With this in mind, RDA provides AI-powered enterprise solutions to strengthen the sustainability of critical
        data center infrastructures, enabling businesses to more accurately track greenhouse gas emissions in their
        lifecycle management. Backed by research developed at NTU, RDA leverages “cognitive digital twin” technology to
        create a virtual replica of a real-world system that can ingest large quantities of data to simulate the effect
        of any changes to a system before they are deployed into the physical system. With RDA’s&nbsp;<em>DCWiz</em>,
        its cognitive digital twin solution digitalizes data center operations and applies predictive AI-powered models
        to forecast energy consumption.</p>


      <p className='common-text'>Commenting on the partnership,&nbsp;<strong>Red Dot Analytics Chief Scientist Dr. Wen Yonggang, who is also a
        Professor and President’s Chair of Computer Science and Engineering at NTU</strong>&nbsp;said: “For too long
        now, rapid technological innovation and changing consumer behaviors have come at the expense of the environment.
        While there are growing trends in the use of renewables or the race to produce more energy-efficient hardware,
        the truth is, we need to find ways to use energy in a more&nbsp;<em>intelligent</em>&nbsp;way and that begins
        with accurate measurement. RDA can solve one part of the puzzle and in working with MVGX, we hope to strengthen
        our value proposition by taking our clients one step further in their sustainability journeys.”</p>


      <p className='common-text'>In addition, MVGX’s proprietary Carbon Management System, powered by its proprietary Non-Fungible Digital Twin
        technology (NFDT™), will support RDA clients through the reporting, calculation, and validation process to
        credibly certify carbon neutrality with the British Standards Institution (BSI)’s<a className='link'
          href="http://ec2-34-221-130-80.us-west-2.compute.amazonaws.com/x/d?c=20163910&amp;l=a3778c95-ad95-4974-9f37-d0f4a321ed03&amp;r=7f37d308-279b-481f-939e-a42039a04915"
          target="_blank" rel="noreferrer noopener">&nbsp;</a><a className='link'
          href="http://ec2-34-221-130-80.us-west-2.compute.amazonaws.com/x/d?c=20163910&amp;l=a3778c95-ad95-4974-9f37-d0f4a321ed03&amp;r=7f37d308-279b-481f-939e-a42039a04915"
          target="_blank" rel="noreferrer noopener">ISO 14064-1:2018 Carbon Footprint Verification</a>&nbsp;and<a className='link'
          href="http://ec2-34-221-130-80.us-west-2.compute.amazonaws.com/x/d?c=20163910&amp;l=354029a5-ecda-44f4-a0b8-4e902be89512&amp;r=7f37d308-279b-481f-939e-a42039a04915"
          target="_blank" rel="noreferrer noopener">&nbsp;</a><a className='link'
          href="http://ec2-34-221-130-80.us-west-2.compute.amazonaws.com/x/d?c=20163910&amp;l=354029a5-ecda-44f4-a0b8-4e902be89512&amp;r=7f37d308-279b-481f-939e-a42039a04915"
          target="_blank" rel="noreferrer noopener">PAS 2060 Carbon Neutrality Verification</a>. These
        internationally-recognized gold standards for carbon footprint validation have been established according to
        international management benchmarks. Powered by its proprietary blockchain technology, the Carbon Management
        System instills greater integrity, transparency, and trust into the carbon neutral certification process.</p>


      <p className='common-text'>In recognition of its commitment to greener data center operations, MVGX will designate RDA as one of its
        preferred data center providers, encouraging its global client base to work with RDA to increase the
        sustainability of their data center operations. Simultaneously, RDA will designate MVGX as its preferred
        platform for the financing of green data centers while encouraging its clients to avail of MVGX’s solutions to
        offset their carbon footprint in a verifiable manner.</p>


      <p className='common-text'><strong>Bo Bai, Executive Chairman and Co-Founder of MVGX, said:&nbsp;</strong>“As businesses work toward
        meeting their net-zero goals, they need to consider the&nbsp;<em>entire</em>&nbsp;scope of what they do and that
        begins, first and foremost, with the infrastructure that powers their operations. Today, data centers, while
        overlooked, are a core component of any online organization and in working with RDA, we want to show that there
        is in fact a way to ensure that energy expenditure at every level, can in fact be accounted for and mitigated
        accurately. Backed by RDA’s high-quality energy measurement capabilities and our emphasis on high-fidelity,
        transparent reporting, we look forward to exploring collective opportunities to offer greener cloud services to
        businesses as they strengthen their sustainability commitments.”</p>


      <p className='common-text'>MVGX’s Carbon Management System is backed by MVGX’s Carbon Neutrality Token (CNT<sup>TM</sup>). Enabled by
        proprietary protocols and blockchain technology, CNTs<sup>TM</sup>&nbsp;combine the immutability of non-fungible
        tokens with real-time data updates, offering an immutable and continuously updated record of carbon performance.
        CNTs<sup>TM</sup>&nbsp;address one of the most challenging aspects of the Paris Agreement (COP21) — namely, the
        ability to properly verify and track carbon credits — enabling carbon neutrality at scale and facilitating the
        global goal of net-zero emissions. In March 2022, MVGX used its Carbon Management System to certify its status
        as a carbon neutral business, making it the first company in the world to do so with tokenized carbon credits.
      </p>


      <p className='common-text'>Amid growing recognition of the energy expenditure of data center operations, the introductions of solutions
        such as the Carbon Management System will be critical to ensuring accurate measurement, accountability and
        reporting, and compliance for digital businesses exploring solutions to mitigate their carbon footprint.</p>


      <p className='common-text'>For media enquiries, please contact <a className='link' href="mailto:pr@mvgx.com">pr@mvgx.com</a>.</p>

    </div>
  } else if (type === '10') {
    curData = <div className="rodller-entry-content container-small">

      <ul>
        <li><em>Sets gold standard for carbon neutrality reporting by combining blockchain technology with BSI
          certification</em></li>
        <li><em>Proof-of-concept system uses tokenized carbon credits to achieve neutrality</em></li>
        <li><em>System to foster integrity and transparency into carbon reporting</em></li>
      </ul>


      <p className='common-text'><a className='link' href="https://www.mvgx.com">MetaVerse Green Exchange</a> (“MVGX”), has successfully certified its status as
        a carbon neutral business through tokenized cross-border carbon credits, the first company in the world to do so
        using blockchain technology. The landmark milestone is bolstered by certification from the <a className='link'
          href="https://www.bsigroup.com/">British Standards Institut</a>ion (“BSI”), the first time the body has
        accepted tokenized carbon credits. The move sets a new gold standard for carbon neutrality reporting in the wake
        of calls for more rigorous systems following COP26.</p>


      <p className='common-text'>As organizations across the world struggle to navigate the complexities of carbon reporting, MVGX, a digital
        green exchange licensed and regulated by the Monetary Authority of Singapore, designed a proof-of-concept system
        to calculate and verify its own carbon neutrality in order to demonstrate the value that blockchain technology
        can deliver.</p>


      <p className='common-text'>Leveraging BSI’s methodology to accurately verify carbon neutrality, MVGX was able to offset its carbon
        footprint precisely using its <a className='link' href="https://www.mvgx.com/carbon-neutrality-token/"
                                         data-id="379">Carbon Neutrality Token</a> (CNT<sup>TM</sup>). Enabled by
        proprietary protocols and blockchain technology, CNT<sup>TM</sup>s combine the immutability of NFTs with
        real-time data updates, offering an immutable and continuously updated record of carbon performance.</p>


      <p className='common-text'>Following its successful pilot to neutralise its own carbon emissions, MVGX’s Carbon Neutrality Management
        System is now available to all institutions looking to enhance their sustainability efforts and further their
        environmental, social and governance (ESG) mandates. Backed by its proprietary blockchain technology, the system
        is built to instill integrity, transparency and trust into carbon neutral certification.</p>


      <p className='common-text'>The Carbon Neutrality Management System supports businesses through the reporting, calculation and validation
        process to credibly certify carbon neutrality with <a className='link'
          href="https://www.bsigroup.com/en-IN/CFV-Carbon-Footprint-Verification/">ISO 14064-1:2018 Carbon Footprint
          Verification</a> and <a className='link' href="https://www.bsigroup.com/en-SG/PAS-2060-Carbon-Neutrality/">PAS 2060 Carbon
          Neutrality</a> Verification — the internationally recognised gold standards for carbon footprint validation in
        accordance with international management benchmarks. Carbon neutrality certification is recorded on the
        blockchain and minted as non-fungible tokens (NFTs), uniquely authenticating an organization’s sustainability
        efforts.</p>


      <p className='common-text'>Commemorating the milestone, MVGX Executive Chairman and Co-Founder Bo Bai said, “We are honoured to make
        history as the first business globally to be certified carbon-neutral from tokenized cross-border carbon
        credits. The recognition from the BSI attests to our capacity to redefine carbon neutrality in the digital age
        and affirms the rigour and credibility of our Carbon Neutrality Management System. This is just the first step
        into furthering our ambitions to champion sustainability efforts. We will continue to make further inroads into
        the digital asset sector and pioneer innovative technologies to create a greener future.”</p>


      <p className='common-text'>CNT<sup>TM</sup> was launched to address one of the most challenging aspects of the Paris Agreement (COP21) —
        the ability to properly account for and track carbon credits — enabling carbon neutrality at scale and
        facilitating the global agenda of net-zero emissions. Globally, nations have committed to the Paris Agreement to
        reduce emissions and reach the goal of net-zero emissions by 2050, achieving a climate-neutral world by
        mid-century.</p>


      <p className='common-text'>In bridging the worlds of digital assets with the sustainability sector, MVGX’s Carbon Neutrality Management
        System imbues integrity into the verification process, guiding organizations through the entire exercise from
        reporting to certification. Valid for one year, organizations benefit from streamlined data management when
        re-validating on an annual basis to maintain their carbon neutrality certification.</p>


      <p className='common-text'>Commenting on MVGX’s landmark achievement, Frank Zhang, Managing Director, Greater China at BSI said, “We are
        thrilled to welcome MVGX’s technological innovations to bolster BSI’s world-class reporting standards.
        Blockchain and digital assets are the cornerstones of today’s emerging technologies. With their traceability and
        immutability, these innovations will enhance BSI’s ISO 14064-1:2018 and PAS 2060 verification processes,
        strengthening it with precise real-time data. With the threat of climate change, there is an accelerating
        urgency for organizations to ingrain transparency and integrity into their carbon footprint reporting to tackle
        today’s environmental challenges for a greener tomorrow.”</p>


      <p className='common-text'>Based in Singapore, MVGX is a global platform that connects high quality, certified and registered carbon
        reduction and removal schemes, with buyers and traders. The exchange leverages Nasdaq’s trading platform
        combined with blockchain technologies to create a practical solution to bolster carbon reduction. Last year,
        MVGX launched its first batch of its asset-backed CNT<sup>TM</sup>s ahead of COP26 in Glasgow. These
        asset-backed tokens were tied to carbon credits generated by a wind project in Zhangjiakou, China that was
        verified and registered with China’s National Carbon Registry. The first tranche of 5,000 carbon credits was
        sold to financial institutions last year, lowering barriers to access ESG assets for institutional investors in
        the Asia Pacific.</p>


      <p className='common-text'><strong>For media enquiries, please contact </strong><a className='link' href="mailto:pr@mvgx.com">pr@mvgx.com</a>.</p>

    </div>
  } else if (type === '11') {
    curData = <div className="rodller-entry-content container-small">
        <h3 className="wp-block-heading">MVGX demonstrates momentum in Q1 with expansion of leadership team and product
          offering</h3>


        <p className='common-text'>MetaVerse Green Exchange (MVGX), a leading digital green exchange, today announced it has appointed Eddie Hui
          as Chief Operating Officer (COO). Eddie joins MVGX’s executive team, alongside Executive Chairman Dr. Bo Bai
          in defining the direction of the business.</p>


        <p className='common-text'>In this role, Eddie will lead crypto-related initiatives with the aim of creating a bridge between the
          traditional finance and the digital asset world. This will allow clients to gain exposure to cryptocurrencies
          while having the assurance of operating within a regulated framework.</p>


        <p className='common-text'>Eddie’s priorities lie on enhancing client experience and satisfaction, operational excellence, risk
          management and the sustainable development of MVGX. With the support of the operational department, Eddie is
          looking to mobilize the team to achieve MVGX’s long term goals&nbsp;of bridging crypto assets with traditional
          financial industries, bridging carbon trading markets from different jurisdictions, and bridging illiquid
          assets in real economy with wider investor accessibility.</p>


        <p className='common-text'>With over 20 years of experience in the financial industry, Eddie has worked for leading global investment
          bank, Société Générale for most of his career.&nbsp;&nbsp;He started in IT, working as a system and network
          administrator in Japan and gradually moved to a management role.&nbsp;&nbsp;In 2008, Eddie started working
          with Front Office functions, successively occupying the roles of COO for the Proprietary Trading activity, COO
          for Fixed Income, Credit and FX, COO for Prime Services and more recently COO for the Equity Market Making
          desk, operating out of Hong Kong.&nbsp;&nbsp;</p>


        <p className='common-text'>Eddie graduated in 1999 from ENSEEIHT (Ecole Nationale Supérieure d’Electrotechnique, Electronique,
          Informatique, Hydraulique de Toulouse), a French engineering school.&nbsp;&nbsp;He holds a Master of Science
          in Engineering.</p>


        <p className='common-text'>As part of its ambition to strengthen its product offering, MVGX has recently expanded the application of
          its <a className='link'
            href="https://www.mvgx.com/press-release/metaverse-green-exchange-develops-further-applications-of-its-proprietary-non-fungible-digital-twin-nfdt-technology/"
             data-id="323">Non-Fungible Digital Twin</a> (NFDT™) technology towards other
          products and services and has launched its Carbon Neutrality Management System, available to clients alongside
          cryptocurrencies and its <a className='link' href="https://www.mvgx.com/carbon-neutrality-token/"
                                      data-id="379">Carbon Neutrality Token</a> (CNT™), with plans to launch a mobile
          app by April.</p>


        <p className='common-text'><strong>Executive Chairman and Co-Founder of MVGX, Bo Bai, has said:&nbsp;</strong>“<em>I am thrilled to
          welcome someone of Eddie’s caliber as our Chief Operating Officer. His wealth of knowledge and experience will
          undoubtedly help to strengthen and expand our business and client offering. As a
          leading&nbsp;</em><em>digi</em><em>tal green exchange, providing the infrastructure for our clients to access
          sustainable, impactful and innovative products is our bedrock and I am confident that Eddie’s expertise will
          help us take MVGX to the next level</em>.”</p>


        <p className='common-text'><strong>Eddie Hui has said:&nbsp;</strong><em>“I am delighted to be joining MVGX at such a crucial time in
          the company’s expansion as the carbon markets and digital assets space continue to evolve. The team is filled
          with energy and momentum with a real sense of commitment to deliver and bring value to our clients and to
          contribute to a greener tomorrow. I am excited to be continuing the fantastic work that has already started in
          building a system to deliver the best results for our clients, always with the goal to help businesses meet
          their ESG objectives.”</em></p>


        <p className='common-text'><em>“My objective is to remain receptive to our clients’ needs and to leverage on the blockchain ecosystem to
          provide them with the best possible solutions. With MVGX’s agility and commitment to deliver, I am confident
          we can demonstrate rapid growth and help with the adoption of the blockchain technology, while working towards
          a greener way of doing business</em>.”</p>


        <p className='common-text'><strong>For media enquiries, please contact:</strong></p>


        <p className='common-text'><a className='link' href="mailto:pr@mvgx.com">pr@mvgx.com</a></p>

    </div>
  } else if (type === '12') {
    curData = <div className="rodller-entry-content container-small">

      <p className='common-text'><strong>Singapore, 22 February 2022 –&nbsp;</strong>MetaVerse Green Exchange (MVGX, formerly Cyberdyne Tech
        Exchange) is announcing the expansion of use cases for its proprietary Non-Fungible Digital Twin (NFDT™)
        technology to include the creation of bankable digital twins of sustainable smart buildings, EV batteries and
        solar panels, as well as the next generation carbon registry.</p>


      <p className='common-text'>While most metaverse use cases currently focus on social interactions and experiences in the metaverse through
        augmented reality (AR) and virtual reality (VR) technologies, MVGX is focused on bringing real-world assets into
        the metaverse through NFDT™ technology, believing that humans need to interact and transact with objects that
        originate from both the digital and real world.</p>


      <p className='common-text'>With its proprietary NFDT™ technology, MVGX is advancing the functionality and value of Non-Fungible Tokens
        (NFT) by supporting the real-time addition of new information to the NFDT™ after its initial creation. The NFDT™
        technology, which combines the digital twin and blockchain technologies, creates assets in the metaverse which
        are ‘living’, with new information constantly being updated and automatically stored onto the blockchain.</p>


      <p className='common-text'>MVGX first demonstrated an application of its NFDT™ technology during the launch of its Carbon Neutrality
        Tokens (CNT™) last November for cross-border trading of Certified Emission Reduction (CER) credits. Investors
        who purchased the CNTs are able to track the integrity and performance of the carbon credit through the unique
        NFDT™ right from its provenance in the wind projects operated by CGN Wind Energy Limited in Zhangjiakou City of
        China.</p>


      <p className='common-text'>Building upon that success, MVGX has been exploring the application of the NFDT™ technology in other
        sustainability initiatives, for example by facilitating the representation of sustainable buildings in the
        metaverse, with ‘live’ data such as building occupancy, electricity usage, carbon footprint and footfall –
        enabling more accurate data for more informed decision-making. This is essential for leaders who wish to
        increase building performance while meeting their net zero goals, and investors who are looking to invest into
        green real estate.</p>


      <p className='common-text'><strong>Dr. Bo Bai, Executive Chairman and Co-Founder of MVGX, commented:</strong>&nbsp;<em>“The application of
        NFDT™ technology can be profound, especially as we move into the metaverse era. I am very excited about how our
        next generation carbon registry system powered by the NFDT™ technology can help national or state governments or
        carbon exchanges build their own carbon registries with the highest quality and integrity, helping to bridge
        carbon trading across different jurisdictions. I am equally excited that the virtual representations of green
        buildings, EV batteries and solar panels through our NFDT™ technology can make these green assets fully
        traceable and bankable, helping to bridge the gap between increasing capital needs for green infrastructures
        while expanding investor accessibility.”</em></p>


      <p className='common-text'><strong>For media enquiries, please contact:</strong></p>


      <p className='common-text'><a className='link' href="mailto:pr@mvgx.com">pr@mvgx.com</a></p>

    </div>
  } else if (type === '13') {
    curData = <div className="rodller-entry-content container-small">

      <p className='common-text'>Cyberdyne Tech Exchange (CTX),<strong>&nbsp;</strong>the leading green digital asset marketplace, has today
        announced that the company will begin operating under the new&nbsp;brand&nbsp;MetaVerse Green Exchange (MVGX),
        to better align with the company’s product and service offerings as well as its commitment towards
        sustainability while&nbsp;embracing the new era of tech innovation and trading.</p>


      <p className='common-text'>MVGX seeks to be the bridge between:&nbsp;</p>


      <ul className='common-text'>
        <li>The crypto and digital asset space, and traditional financial services;</li>
        <li>Carbon trading markets of different jurisdictions with its patent-pending product and system; and</li>
        <li>New sources of capital from the crypto world and green infrastructure projects.</li>
      </ul>


      <p className='common-text'>MVGX has already demonstrated the use of its proprietary Non-Fungible Digital Twin (NFDT™) technology through
        its Carbon Neutrality Token (CNT™). By combining the immutability of blockchain technology behind the NFT and
        that of its Digital Twin, MVGX ensures the highest integrity of carbon credit tokens on its exchange while
        preventing double-counting. This provides fully auditable and traceable virtual records of ownership and “live”
        data of carbon projects in the digital world.&nbsp;</p>


      <p className='common-text'>To provide its participants with a safe regulatory framework, MVGX has obtained a unique portfolio of licenses
        from the Monetary Authority of Singapore (MAS). This includes:</p>


      <ul type="1">
        <li>Holding a Recognized Market Operator (RMO) license;</li>
        <li>Holding Capital Markets Services License (CMSL) for dealing in capital markets products and providing
          custodial services; and&nbsp;</li>
        <li>Currently under exemption from holding a license under the Payment Service Act.&nbsp;</li>
      </ul>


      <p className='common-text'>Powered by Nasdaq and its own proprietary ledger platform, MVGX provides a transparent and powerful digital
        asset ecosystem in which assets are traded and carbon disclosures are achieved by both issuers and
        investors.&nbsp;</p>


      <p className='common-text'><strong>Executive Chairman and Co-Founder of MetaVerse Green Exchange, Dr. Bo Bai commented</strong>: “<em>The
        Metaverse is not just a buzzword in the world of tech. It represents a burgeoning era for green financing which
        can now be conducted and innovated through the power of digital transformation. As a company, we are committed
        to maintaining our innovative cutting-edge. We are also ensuring our operations use the totality of
        technologies, regulation and financial service industry innovations to create better outcomes for our
        clients.&nbsp;</em></p>


      <p className='common-text'><em>With our NFDT™ technology, we will create digital twins of real-world assets and establish MVGX as&nbsp;
        <strong>the</strong>&nbsp;green exchange for the metaverse era.</em>”<em></em></p>


      <p className='common-text'><strong>For media enquiries, please contact:</strong></p>


      <p className='common-text'><a className='link' href="mailto:pr@mvgx.com">pr@mvgx.com</a></p>

    </div>
  } else if (type === '14') {
    curData = <div className="rodller-entry-content container-small">

      <p className='common-text'>Cyberdyne Tech Exchange Pte., Ltd (“CTX”) and BSI China (a group company of the British Standards Institution)
        have announced a new cooperation agreement on implementing carbon neutralisation and green financing standards
        on the Singapore-based exchange.</p>


      <p className='common-text'>The agreement, signed today in the form of a Memorandum of Understanding, includes BSI carrying out the carbon
        footprint verification and certification for listings on the digital green exchange. Issuers and investors of
        asset-backed securities will be able to participate securely, knowing that carbon disclosures and carbon
        neutrality claims will have met rigorous international standards such as ISO 14064, ISO 14067 and PAS 2060.</p>


      <p className='common-text'>The parties confirmed the scope of cooperation provides for further development, implementation, and promotion
        of green finance standards.</p>


      <p className='common-text'><strong>Bo Bai, Executive Chairman of CTX, said:</strong></p>


      <p className='common-text'>“CTX is delighted to cooperate with the BSI on building confidence in the markets by implementing strict,
        science-driven standards to listed assets. We are combining the BSI’s more than 120-year history of standards
        setting and standards certification with CTX’s proprietary technologies to ensure the UN Sustainable Development
        Goals, specifically climate action, remain at the forefront of our activities.”</p>


      <p className='common-text'><strong>Frank Zhang, Managing Director, Greater China at BSI:&nbsp;</strong></p>


      <p className='common-text'>“BSI will utilize the well-recognized international standards such as ISO 14064, ISO 14067 and PAS 2060 to
        guide and certify companies in their journey to measure and reduce carbon footprint and to achieve carbon
        neutrality. BSI carbon footprint and carbon neutrality certification can provide a recognised method to
        substantiate claims by companies around climate change efforts and to enhance their sustainability credentials.
        We are looking forward to working with CTX on developing advanced technologies to allow for frictionless
        assessment, verification and documentation of carbon related information for investors on the exchange.”</p>


      <p className='common-text'>For more information on BSI standards, including the PAS 2060 specification for the demonstration of carbon
        neutrality, please visit the BSI website&nbsp;<a className='link'
          href="https://www.bsigroup.com/en-GB/pas-2060-carbon-neutrality" target="_blank"
          rel="noreferrer noopener">www.bsigroup.com/en-GB/pas-2060-carbon-neutrality</a>
        <a className='link' href="https://www.bsigroup.com/en-GB/pas-2060-carbon-neutrality" target="_blank" rel="noreferrer noopener">www.bsigroup.com/en-GB/pas-2060-carbon-neutrality</a></p>


      <p className='common-text'><strong>Media enquiries:&nbsp;</strong></p>


      <p className='common-text'><a className='link' href="mailto:pr@ctx.sg" target="_blank" rel="noreferrer noopener">pr@ctx.sg</a></p>


      <p className='common-text'><a className='link' href="mailto:cservices@bsigroup.com" target="_blank" rel="noreferrer noopener">cservices@bsigroup.com</a>
      </p>

    </div>
  } else if (type === '15') {
    curData = <div className="rodller-entry-content container-small">

      <p className='common-text'><em>CNT’s underpinning technology to unleash global carbon trading markets</em></p>


      <p className='common-text'>The Cyberdyne Tech Exchange (CTX) has released and sold the first tranche of a new asset-backed Carbon
        Neutrality Token (CNT) which resolves one of the most challenging aspects of the Paris Agreement (COP21) – the
        ability to properly account for and track carbon credits using its proprietary protocols and blockchain
        technologies.</p>


      <p className='common-text'>With the COP26 in Glasgow approaching, the failure to resolve the complexities of Article 6 of the Paris
        Agreement has led to delays in financing green projects, accelerating the climate crisis.</p>


      <p className='common-text'>COP21 required nations reduce their emissions every five years. By creating nationally determined contributions
        (NDCs) for cutting emissions, the clear expectation was that nations would act, by submitting new or updated
        national climate plans. However, ambiguity in transparency and commitment to implementing more ambitious targets
        means that the world is well behind in achieving the 1.5°C temperature target set out in Paris.</p>


      <p className='common-text'>The responsibility of carbon offsetting does not simply rest with governments. Companies have a crucial role to
        play in addressing the climate crisis – even on a voluntary basis. The Taskforce on Scaling Carbon Markets and
        Deloitte has estimated that the current market size of the Voluntary Carbon Markets is around USD300 million. It
        is estimated that this market will grow to reach USD50 billion by 2030 according to McKinsey.</p>


      <p className='common-text'>The main sticking point preventing efforts from going further relates to establishing clarity around “double
        counting”. This occurs when a country has overachieved relative to its carbon reduction targets and is able to
        sell the balance to another nation who have yet to achieve their own reduction targets. In so doing, double
        counting can occur when both nations claim they have met their reduction targets by counting the surplus or
        additional carbon reductions themselves.</p>


      <p className='common-text'>CTX, which is licensed by the Monetary Authority of Singapore, is solving the problem of “double counting”
        through the launch of a new carbon neutrality token. With robust proprietary distributed ledger technology
        supporting the token, CNTs can provide buyers and issuers with an immutable and constantly updated record of the
        carbon performance of their tokens.</p>


      <p className='common-text'>The sale of the first tranche of 5,000 carbon credits to the Hong Kong-based private equity firm Celadon
        Partners on the exchange demonstrates there is ample demand for voluntary emission reductions (VERs) which do
        not interfere with NDCs – something no other solution has been able to address.</p>


      <p className='common-text'>Celadon Partners Managing Partner Donald Tang remarked, “We are very excited to be the first investor to
        transact CNTs on CTX. Beyond this step towards sustainability in our own private equity portfolio, we hope to
        continue catalysing innovations with CTX to lower the barriers to sustainability for corporates and investors
        everywhere.”</p>


      <p className='common-text'>The CNT is asset-backed with the first issuance of carbon credits being generated by a wind project in
        Zhangjiakou. The project has been verified by the Foreign Economic Cooperation Office, Ministry of Environmental
        Protection of China (MEPFECO) and is registered with the national carbon registry, which holds to the same high
        standards as the European Union. Other global carbon credits programmes will soon be going live, demonstrating
        the stability and capability of the CTX platform.</p>


      <p className='common-text'>Executive Chairman and Co-Founder of Cyberdyne Tech Exchange, Dr. Bo Bai&nbsp;commented: “We are delighted to
        have helped resolve one of the key challenges facing the voluntary emission reductions (VERs) market segment.
        When developing the CNT our focus was to create an effective solution that provides businesses with a
        trustworthy, high-quality offering, that gives them the assurance they need in knowing their investments are
        directly impacting global carbon reduction.</p>


      <p className='common-text'>Not only is our platform regulated by the Monetary Authority of Singapore, our proprietary protocols and
        blockchain technologies, and our ability to source and authenticate quality global carbon offsets and
        sustainable development initiatives can give the carbon trading markets the confidence it needs to flourish.</p>


      <p className='common-text'>Having been involved in and supported sustainable projects throughout my career, the CTX exchange is playing a
        key role in resolving the challenges faced by international carbon markets and is a direct and credible solution
        to reduce emissions.”</p>


      <p className='common-text'><strong>For media enquiries, please contact:</strong></p>


      <p><a className='link' href="mailto:pr@ctx.sg" target="_blank" rel="noreferrer noopener">pr@ctx.sg</a></p>

    </div>
  } else if (type === '16') {
    curData = <div className="rodller-entry-content container-small">

      <p className='common-text'>China Taiping Insurance Singapore Pte. Ltd. (“CTPIS”) and Cyberdyne Tech Exchange Pte. Ltd. (“CTX”) have
        announced a new cooperation agreement to encourage the investment, trading and insurance of green assets.</p>


      <p className='common-text'>The agreement, signed this week in the form of a Memorandum of Understanding, included CTX enlisting CTPIS as
        their preferred insurer. Asset issuers and investors (both accredited and institutional) will benefit from
        access to the expertise and customised insurance solutions of CTPIS when using CTX for primary issuance,
        secondary trading, settlement, clearing, and custody of asset-backed tokens.</p>


      <p className='common-text'>The two parties confirmed the scope of the agreement provides for continued strengthening of future cooperation
        in achieving common goals that will foster stability and liquidity of the increasingly in-demand asset
        class.</p>


      <p className='common-text'>Mr. Yang Yamei, General Manager at CTPIS, said:</p>


      <p className='common-text'>“ We are delighted to partner with Cyberdyne Tech Exchange who has deep knowledge in digital green asset.
        Together through resource sharing and collaborative innovation, we can unlock the potential of investment,
        trading and insurance of green assets. This strategic partnership will allow us to bring the best of both worlds
        together.”</p>


      <p className='common-text'>Bo Bai, Executive Chairman and co-founder of CTX, said:</p>


      <p className='common-text'>“CTX is very happy to cooperate with CTPIS on promoting green assets. As a licensed digital exchange, CTX
        offers best-in-class capital market services. China Taiping Insurance is regularly acknowledged as a leader in
        their field with unique market capabilities. This agreement will help us accomplish our goals of establishing
        Singapore as a green finance hub.”</p>


      <p className='common-text'><strong>For media enquiries, please contact:</strong></p>


      <p><a className='link' href="mailto:pr@ctx.sg" target="_blank" rel="noreferrer noopener">pr@ctx.sg</a></p>

    </div>
  } else if (type === '17') {
    curData = <div className="rodller-entry-content container-small">

      <p className='common-text'>Cyberdyne Tech Exchange (“CTX”), a regulated digital green exchange headquartered in Singapore, today announced
        that Kelvin Wong had joined the company as its Chief Compliance Officer.</p>


      <p className='common-text'>In his role, Mr. Wong will report to CTX’s Executive Chairman Dr. Bai Bo and oversee CTX’s compliance issues of
        its products and business activities, through developing, implementing and executing compliance and governance
        policies, procedures, manuals and training programs.</p>


      <p className='common-text'>Mr. Wong joined CTX following after more than 17 years experiences in compliance, audit and risk management in
        the financial services industry. In his prior roles including member of Risk Management &amp; Regulation at
        Singapore Exchange, Deputy Country Head/Risk Partner of Lloyds Bank (Singapore) and Head of Compliance at Tiger
        Brokers (Singapore), he has developed an extensive compliance experience of capital market products.</p>


      <p className='common-text'>Dr. Bai said, “We are excited to welcome Kelvin to CTX. As a regulated exchange aiming to create seamless
        connection with mainstream financial institutions, institutional investors and accredited individuals, we
        appreciate Kelvin’s deep compliance experiences to safeguard our bank-grade KYC/AML standards and best-practices
        of compliance procedures.”</p>


      <p className='common-text'>Mr. Wong said, “I am thrilled to join CTX, and I look forward to working with the team to implement and execute
        a world-class compliance practices to bring CTX to greater heights.”</p>


      <p className='common-text'>Mr. Wong has assumed his full-time responsibilities with CTX on June 14, 2021.</p>


      <p className='common-text'><strong>For media enquiries, please contact:</strong></p>


      <p><a className='link' href="mailto:pr@ctx.sg" target="_blank" rel="noreferrer noopener">pr@ctx.sg</a></p>

    </div>
  } else if (type === '18') {
    curData = <div className="rodller-entry-content container-small">

      <p className='common-text'>Dr Bo Bai, Executive Chairman ofCyberdyne Tech Exchange (CTX),&nbsp;a leading green digital asset marketplace
        and the first exchange to mandate carbon disclosure for both issuers and investors through its carbon neutrality
        blockchain platform, has been recognised by the Singapore Association of Trade and Commerce at the Entrepreneur
        100 Awards this year.</p>


      <p className='common-text'>The Entrepreneur 100 Award celebrates Singapore’s business leaders that have inspired and guided their
        businesses to deliver growth and innovation, acknowledging their corporate achievements and contributions to the
        industry, community and nation.</p>


      <p className='common-text'>2021 has seen a few milestones for CTX with the launch of&nbsp;its proprietary Carbon Neutrality Token (CNT)
        earlier this year, which has revolutionised the voluntary emission reductions (VERs) market by allowing access
        to high-quality, authenticated carbon credits – safe in the knowledge that the underlying projects are directly
        contributing to localised carbon reduction and are not sold on multiple occasions thanks to unique identifiers
        monitored within CTX’s distributed ledger.</p>


      <p className='common-text'>The company has also recently signed an agreement with BSI China (a company of the British Standards
        Institution Group) to implement carbon neutralisation and green financing standards on the Singapore-based
        exchange. The agreement includes BSI carrying out the carbon footprint verification and certification for
        listings on CTX’s platform, as well as the promotion and implementation of green finance standards.</p>


      <p className='common-text'>All these support the Green Finance Action Plan of the Singapore Green Plan 2030 and the ultimate goal of
        net-zero by 2050, while CTX continues to leverage innovation and technology to enable organisations of all sizes
        to enhance their sustainability goals.</p>


      <p className='common-text'><strong>Commenting on the accolade, Dr Bo Bai said:</strong></p>


      <p className='common-text'>“<em>I am honoured to have been named at this year’s Entrepreneur 100 Awards.Since my university days, I have
        been committed to making a difference in the planet we’re living on, engineering solutions that can be
        implemented locally and adopted globally in an attempt to undo the damage we have caused to the environment and
        our local communities.</em></p>


      <p className='common-text'><em>I would also like to thank the team at CTX, who have dedicated their time and energy to developing green
        finance solutions. Having the recognition of the Singapore Association of Trade and Commerce is reassurance that
        we are heading in the right direction.”</em></p>


      <p className='common-text'><strong>For media enquiries, please contact:</strong></p>


      <p className='common-text'><a className='link' href="mailto:pr@ctx.sg" target="_blank" rel="noreferrer noopener">pr@ctx.sg</a></p>

    </div>
  }
  return (
    <div className='press-release-detail'>
      <Back/>
      <img src={location.state.img} alt="img"/>
      <h1>{location.state.title}</h1>
      {curData}
    </div>
  )
}

export default PressReleaseDetail;