import React from 'react';
import './Careers.scss'
import { Carousel } from 'antd';
import { useNavigate  } from 'react-router-dom';

function Careers() {
  const navigate = useNavigate();
  let img1 = require('../image/careers-1.jpg')
  let img2 = require('../image/careers-2.jpg')
  let img3 = require('../image/careers-3.jpg')
  return (
    <div className='careers'>
      <h1>Careers</h1>
      <h2>Why join us?</h2>
      <section>
        <p>MetaVerse Green Exchange (MVGX®) is a leading FinTech company providing end-to-end Carbon-as-a-Service solutions, backed by its licensed digital asset exchange platform and patent-pending Metaverse technologies. Founded in 2018 and based in Singapore, MVGX® has built a world-class digital exchange platform with the best-in-class Nasdaq engines, MVGX® proprietary digital asset ledger with carbon footprint tag, and rigorous compliance processes.</p>
        <p>We're serious about empowering governments and organisations as they pursue their path towards carbon neutrality and ultimately attaining net zero status. If you're keen on using cutting edge technology to help shape a sustainable and greener future in the metaverse era, we want you!</p>
        <p>At MVGX, we embrace the Excellence, Responsibility and Teamwork culture. Here are some elements of our work culture:</p>
        <ul className='ul-list'>
          <li>Open Door Policy</li>
          <li>Fast and Dynamic Environment</li>
          <li>Smart Casual Office Attire</li>
          <li>Growth Opportunities</li>
          <li>Work with World-class Leaders from the ESG Space</li>
        </ul>
        <p>In addition, we also want our employees to feel valued while working with us. That’s why we have lined up several employee benefits for you so that you will feel taken care of. We also revise our perks periodically to keep up with the changing times. Here are some of the benefits available currently:</p>
        <ul className='ul-list'>
          <li>Competitive salary</li>
          <li>Medical and Dental Coverage</li>
          <li>Annual Health Check up</li>
          <li>Birthday leave</li>
          <li>Family Care Leave</li>
          <li>Marriage Leave</li>
        </ul>
      </section>
      <button onClick={() => navigate('/jobs')}>Join Us Now!</button>
      <h2>Outings at MVGX</h2>
      <section>
        <em>We work hard and play hard.</em>
        <Carousel className='carousel' autoplay effect="fade">
          <div>
            <img src={img1} alt="careers"/>
          </div>
          <div>
            <img src={img2} alt="careers"/>
          </div>
          <div>
            <img src={img3} alt="careers"/>
          </div>
        </Carousel>
      </section>
    </div>
  );
}

export default Careers;