import React from 'react'
import './OurTeam1.scss'

const boardMembersList = [
  {
    name: 'Dr Bo Bai',
    position: 'Executive Chairman, Co-founder',
    img: require('../image/Bobai.png'),
    info: [
      'Dr. Bo Bai is the Executive Chairman and Co-Founder of MVGX Holdings where he oversees the growth and direction of MVGX and its subsidiaries. Dr. Bai is also the Chairman and Founder of Asia Green Fund, an award-winning impact private equity firm with US$2.5 billion assets under management that is an affiliate of MVGX Holdings. Previously, Dr. Bai held senior leadership roles at leading private equity and investment institutions such as Warburg Pincus, First Reserve Corporation, and Goldman Sachs. Dr. Bai holds a Ph.D. in Physics from the Massachusetts Institute of Technology (MIT) and a certificate in the Financial Technology Option from MIT’s Sloan School of Management.',
    ]
  },
  {
    name: 'Meyer "Sandy" Frucher',
    position: 'Vice Chairman, MVGX Holdings',
    img: require('../image/SandyFrucher.png'),
    info: [
      'Meyer "Sandy" Frucher is the Vice Chairman of MVGX Holdings, where he is responsible for driving the company toward establishing an interconnected global carbon infrastructure network with national registries, trading, clearing platforms, and enterprise resource planning (ERP) systems. Prior to joining MVGX, Sandy helmed NASDAQ as Vice Chairman, leading the American multinational financial infrastructure technology provider and stock exchange provider for over 15 years. Sandy also served as Chairman and CEO of the Philadelphia Stock Exchange and was a former board member of the World Federation of Exchanges. Sandy holds a Master in Public Administration from Harvard University’s John F. Kennedy School of Government.',
    ]
  },
  {
    name: 'Michael Sheren',
    position: 'President & Chief Strategy Officer',
    img: require('../image/MichaelSheren.png'),
    info: [
      'Michael Sheren is the President and Chief Strategy Officer of MVGX, and is also a Board Member of MVGX Holdings, where he has played a pivotal role in strengthening the Carbon-as-a-Service proposition for global customers. Previously, Michael was a senior advisor at the Bank of England, leading its finance and environmental risk management work. Michael was also the co-chair of the G20 Sustainable Finance Study Group with China from 2016-2019. Today, Michael continues to serve as senior advisor to the United Nations Development Program’s Sustainable Finance Hub, is an Emeritus Governor at the London School of Economics, and is a Prince of Wales Fellow at Cambridge University’s Cambridge Institution for Sustainable Leadership.',
      'Michael holds a Master of Science in Philosophy and Public Policy from the London School of Economics and Political Science; a Master of Public Administration in International Finance, Development, and Economics from the Harvard Kennedy School; and a Master of Science in Commercial Property Finance from New York University.',
    ]
  },
]

const seniorExecutivesList = [
  {
    name: 'Frank Zhang',
    position: 'President',
    img: require('../image/FrankZhang.png'),
    info: [
      '<span class="bold-text">Formerly Managing Director of British Standards Institution (BSI) Greater China</span>, responsible for overall profit and loss and business developments.',
      'Formerly senior executives of software product and service companies including Merkle and IBM China.',
      'Senior operational experiences with Bank of China, Citibank, Blue Cross Blue Shield and Ernst & Young.',
      'MBA in Finance from Cornell University and an MS in Computer Science from the University of Tennessee, Knoxville.',
    ]
  },
  {
    name: 'James Martin',
    position: 'Head of Europe, Middle East & Africa',
    img: require('../image/JamesMartin.png'),
    info: [
      '<span class="bold-text">Former General Manager of Nasdaq for Europe, Middle East & Africa (EMEA), </span>, instrumental in establishing Nasdaq’s business in EMEA region.',
      'Formerly Co-Chairman at Astana International Financial Centre (AIFC) in Kazakhstan.',
      'Graduated from Stockholm School of Economics Executive Education with a degree in Financial Analysis & Valuation, Economics.',
    ]
  },
  {
    name: 'Gabriel Wong',
    position: 'Head of Southeast Asia, Chief Commercial Advisor, Co-Founder',
    img: require('../image/GabrielWong.png'),
    info: [
      '<span class="bold-text">Formerly head of syndicate at UBS Securities</span>',
      'Former banker at Citigroup Global Markets',
      'Former corporate lawyer in Hong Kong, Shanghai and Singapore.',
      'MBA from Columbia Graduate School of Business',
      'A qualified attorney in the State of New York, London and Wales and Singapore.',
    ]
  },
  {
    name: 'Lily Hong',
    position: 'Head of Greater China, Chief Technology Advisor, Co-Founder',
    img: require('../image/LilyHong.png'),
    info: [
      '<span class="bold-text">Founder of Beijing Zeepson Technology Inc.</span>',
      'Won "Chinese Independent Brand Innovative Women of the Year" and "China Data Intelligence Elite" awards given by Asian Economic Conference in 2018',
      'Awarded the “5 Most Influential Women in Financial Technology” in May 2019.',
      'Graduated from Stanford University with a major in Computer Science',
    ]
  },
  {
    name: 'Eddie Hui',
    position: 'Chief Operating Officer',
    img: require('../image/EddieHui.png'),
    info: [
      '23 years of experience with a leading financial institution in an operational leadership position with technology background',
      'Strong operational leader with a technology background overseeing architecture and implementation of platforms',
      'Formerly COO of Equity, FICC, Prop Trading of Société Générale Asia from 2008 - 2022',
      '1999-2008, head of IT infrastructure for Société Générale Japan',
      'Master degree of Electrical Engineering and Automation, Toulouse, France',
    ]
  },
  {
    name: 'John Kang',
    position: 'Chief Financial Officer',
    img: require('../image/JohnKang.png'),
    info: [
      'Chief Financial Officer of EMQ Inc for 2 years and Chief of Staff for 6 years',
      'Oversaw accounting, finance, and treasury functions, managing bank settlement and FX trading accounts across multiple markets and subsidiaries',
      'Two decades of experience in the financial services sector in North Asia',
      'Held senior leadership positions at world-class banking, hedge fund, and corporate advisory firms including Citigroup Global Markets, Citadel Investment Group, and Goldman Sachs\n',
      'Degree in Government from Cornell University\n',
    ]
  },
  {
    name: 'Kelvin Wong',
    position: 'Chief Compliance Officer',
    img: require('../image/KelvinWong.png'),
    info: [
      '30+ years of compliance and regulatory experience in financial institutions ',
      'Former deputy country head & Chief Compliance Officer at Lloyds Bank',
      'Former a regulatory supervisor at Singapore Stock Exchange (SGX)',
      'Degree in banking and finance from Monash University & a diploma in Compliance from International Compliance Association (ICA) with FICS specialist accreditation from the Institute of Banking and Finance (IBF).',
    ]
  },
  {
    name: 'Andrew Lim',
    position: 'Chief Technology Officer',
    img: require('../image/AndrewLim.png'),
    info: [
      'Over 20 years of extensive experience in the IT industry such as development, infrastructure, risk management. \n',
      'Former senior IT/cybersecurity manager at insurers such as Great Eastern (Regional) and Manulife (Global). \n',
      'Managed IT security for the Singapore Ministry of Education.\n',
      'Graduate from the Western Sydney University with a major in IT and is a certified professional with Microsoft and the International Information System Security Certification Consortium (ISC2).\n',
      ]
  },
]

function LiBox({data, type}) {
  let content;
    content = 
      <div className='cell'>
        <div className=''>
          <img src={data.img} alt="name"/>
          <div className='info'>
            <h4><span>{data.name}</span></h4>
            <div className='position'>{data.position}</div>
          </div>
        </div>
        <div className='content'>
          <h4><span>{data.name}</span></h4>
          <div className='position'>{data.position}</div>
          {data.info.map((item, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: item}} />
          ))}
        </div>
      </div>
  return (
    content
  )
}

function OurTeam() {
  return (
    <div>
      <div className='our-team'>
        <div className='team'>
          <h1>Our team – Board Members</h1>
          <div className='list'>
            {boardMembersList.map((item, index) => (
              <div key={index} className='item'>
                <LiBox data={item}/>
              </div>
            ))}
          </div>
        </div>
        <div className='team' style={{display: 'none'}}>
          <h1>Our Team – Senior Executives</h1>
          <div className='list'>
            {seniorExecutivesList.map((item, index) => (
              <div key={index} className='item'>
                <LiBox data={item}/>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurTeam