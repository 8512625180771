import React from 'react'
import './TechServices.scss'
import { useLocation  } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
const data = {
  CarbonManagementSystem: [
    {
      title: 'Carbon Footprint Calculation',
      content: 'Built on ISO standards, this module empowers organisations to calculate their organisational carbon inventory (ISO 14064-1), carbon footprint of a product or product line (ISO 14067), and/or supply chain emissions (Scope 3) efficiently and accurately, enabling them to gain a comprehensive understanding of their climate impact and at the same time lay a solid data foundation for various national and international regulatory requirements for carbon footprint reporting.'
    },
    {
      title: 'Carbon Footprint Reporting',
      content: 'An extension to the Carbon Footprint Calculation module, the Reporting module carries out further quantification processes, organises the calculated carbon footprint data using a set of pre-defined reporting formats to deliver meaningful insights and documentation to cater to the needs of organisations which ranges from ESG reporting, regulatory compliance, to certification purposes in conjunction with the Carbon Footprint Certification module.'
    },
    {
      title: 'Carbon Footprint Certification',
      content: 'The Carbon Footprint Certification module is an accredited tool that provides organisations with greater accessibility, data management and security to getting their reported carbon footprint verified and certified according to international ISO standards – ISO 14064-1 for organisational operation emissions, and ISO 14067 for product or product line related emissions. Organisations can choose to have their reported carbon footprint verified and certified by an internationally recognised certification body such as British Standards Institute (BSI) and TÜV SÜD, strengthening the credibility and global recognition of the certification.'
    },
    {
      title: 'Carbon Credit Asset Management',
      content: 'The Carbon Credit Asset Management module enables organisations to strategically manage their carbon credit assets, which includes the documenting of the source of the carbon credits, the type of carbon credits, and the transaction records such as the buying and selling of the carbon credits, providing a complete and continuous chain of compliant records of carbon credit assets as assets in the organisation\'s accounts.'
    },
    {
      title: 'Carbon Neutrality Verification',
      content: 'The Carbon Neutrality Verification module allows organisations to report and certify their decarbonisation efforts, including the use of carbon credits for offsetting residual emissions, according to the PAS 2060 carbon neutrality standard, the only internationally recognised neutrality standard. Similar to the Carbon Footprint Certification module, this module provides the organisations with the option to be certified by internationally recognised certification bodies such as British Standards Institute (BSI) and TÜV SÜD. In addition, the carbon neutrality verification process will be accelerated if the organisation’s carbon footprint has been certified by the same independent certification body.'
    },
    {
      title: 'CBAM Verification',
      content: 'The CBAM Verification module is a customised tool for organisations to calculate, report and obtain verification for the carbon footprint of their products as regulated by the European Union’s Carbon Border Adjustment Mechanism (CBAM).'
    },
    {
      title: 'ISSB Carbon Verification',
      content: 'The ISSB Carbon Verification module is a customised tool for organisations to report and obtain verification for their organisational carbon footprint and other climate-related disclosure and assurance according to the IFRS S1 and IFRS S2 standards as specified by the International Sustainability Standards Board (ISSB).'
    },
  ],
  CarbonRatingSystem: [
    {
      title: 'Decarbonisation Rating',
      content: 'The Decarbonisation Rating module is a benchmarking and assessment tool that offers organisations a comprehensive measurement of its decarbonisation performance. Based on the methodology developed with TÜV SÜD, a global certification body, the Decarbonisation Rating assesses eight dimensions of decarbonisation activities, which include: operational emissions, industry chain emissions, direct and indirect contributions to emission reductions, zero-carbon operations, green supply chains, and more, empowering organisations to evaluate their climate impact, set ambitious goals, and track their progress toward net-zero operation.'
    },
    {
      title: 'Carbon Neutrality Quality Rating',
      content: 'The Carbon Neutrality Quality Rating module is designed to evaluate the quality of the measures undertaken by an organisation to achieve carbon neutrality. Based on five pre-determined quality dimensions, an organisation using only purchased carbon credits to achieve carbon neutrality would have a lower rating than the ones using a combination of carbon credits and low carbon operation measures such as energy efficiency improvement and low carbon product development.'
    },
    {
      title: 'Carbon Credit Rating',
      content: 'The Carbon Credit Rating module is designed to evaluate the quality of carbon credits based on their additionality, permanency, baseline and quantification methodology, reflecting its intrinsic and fair market value, and is indicative of its probability of acceptance by international certification bodies for the purpose of carbon emissions offsetting, an important consideration for organisations seeking high-quality carbon credits to offset their hard to abate carbon emissions.'
    },
  ],
  CarbonCreditDevelopmentSystem: [
    {
      title: 'Carbon Project Development',
      content: 'With its intuitive design and robust operational functionality, the Carbon Project Development module is an interactive tool providing carbon asset owners and project developers an end-to-end certifiable pathway taking the carbon assets and carbon emissions reduction initiatives and turning them into high-integrity carbon credits, based on internationally recognised carbon mitigation and crediting standards, that are globally accepted and tradable.'
    },
    {
      title: 'Carbon Credit Issuance',
      content: 'The Carbon Credit Issuance module is a proprietary tool for carbon asset owners and project developers to efficiently manage the issuance of carbon credits to buyers such as large corporations and other entities seeking high-quality carbon credits to offset their hard to abate carbon emissions. Using advanced blockchain technology and our proprietary Non-Fungible Digital Twin (NFDT®) technology to record all activities, the entire process is rigorous, consistent, and transparent, effectively eliminating the risk of double counting plaquing carbon markets worldwide.'
    },
  ],
  CarbonCreditRegistrySystem: [
    {
      title: 'Carbon Credit Registry System',
      content: 'The Carbon Credit Registry system is specially designed to support national or sub-national governments on their Net-Zero journeys. In addition to serving as an important central database accounting for carbon emissions reductions generated within national jurisdictions, the System utilises advanced blockchain technology and our proprietary Non-Fungible Digital Token (NFDT®) technology to address the fundamental issues of national sovereignty and the double-counting of carbon credits, effectively enabling governments to credibly and certifiably account for their emissions reduction achievements and more importantly, maintain sovereignty over the corresponding carbon credits, which is crucial in meeting their Paris Agreement obligations and seizing climate funding opportunities.'
    },
  ],
  CarbonCreditTradingSystem: [
    {
      title: 'Carbon Credit Listing',
      content: 'The Carbon Credit Listing module facilitates the listing of carbon credits from projects registered in various independent registries for trading in the Carbon Credit Trading System. After the successful import of project information, the module would perform the verification of the quantity, quality, and tradability of the associated credits, ensuring its integrity before being made available for sale.'
    },
    {
      title: 'Carbon Credit Marketplace',
      content: 'The Carbon Credit Marketplace module is a platform that connects buyers and sellers in the carbon credit market. This innovative marketplace allows sellers to list their carbon credits, and buyers can make informed decisions based on various criteria, such as the underlying project\'s location, climate and other environmental impacts, and specific sustainability goals. With transparency and efficiency at its core, our marketplace facilitates seamless transactions, empowering organisations to take meaningful steps towards a greener and more sustainable future.'
    },
    {
      title: 'Carbon Credit Auction',
      content: 'The Carbon Credit Auction module is a dynamic and efficient trading platform that revolutionises carbon credit trading. Buyers have the opportunity to bid for carbon credits, while sellers can list their carbon credits for competitive bidding based on auction rules that can be customised. This interactive and transparent auction system empowers participants to engage in fair and competitive transactions, advancing carbon neutrality efforts worldwide.'
    },
    {
      title: 'Carbon Credit Negotiated Trading',
      content: 'The Carbon Credit Negotiated Trading module allows buyers and sellers to engage in private agreements on the terms of the deal before making the transaction official on the platform. With a focus on transparency and efficiency, this negotiation-based approach ensures fair deals and fosters trust in carbon credit trading.'
    },
    {
      title: 'Carbon Credit Regular Trading',
      content: 'The Carbon Credit Regular Trading module connects buyers and sellers in a transparent market, enabling efficient and seamless transactions. Powered by Nasdaq’s Matching Engine and Market Surveillance module, its functionalities include pre-trade validation, order matching on price-time priority and multiple order types. With real-time pricing data, visible orderbook, multi-currency support, support of high frequency trading with customised trading rulesets, our platform empowers participants to actively engage in carbon credit trading while promoting climate responsibility.'
    },
  ]
}

function TechServices () {
  const location =useLocation();
  let curData, curTitle;
  if (location.state.id === '1') {
    curData = data.CarbonManagementSystem
    curTitle = 'Carbon Management System'
  }
  if (location.state.id === '2') {
    curData = data.CarbonRatingSystem
    curTitle = 'Carbon Rating System'
  }
  if (location.state.id === '3') {
    curData = data.CarbonCreditDevelopmentSystem
    curTitle = 'Carbon Credit Development System'
  }
  if (location.state.id === '4') {
    curData = data.CarbonCreditRegistrySystem
    curTitle = 'Carbon Credit Registry System'
  }
  if (location.state.id === '5') {
    curData = data.CarbonCreditTradingSystem
    curTitle = 'Carbon Credit Trading System'
  }
  return (
    <div className='tech-services'>
      <h2>{curTitle}</h2>
      {curData.map((item, index) => (
        <div className='flex-wrapper' key={index}>
          <div>{item.title}</div>
          <div>{item.content}</div>
        </div>
      ))}
    </div>
  )
}

export default TechServices;