import React, {useEffect, useState} from 'react';
import {RightOutlined} from '@ant-design/icons'

const items = [
  {
    label: 'Commercial',
    info: [
      {
        isOpen: false,
        des: 'DIRECTOR/SENIOR DIRECTOR, INSTITUTIONAL SALES',
        desDetail: 'Our ideal candidate should have the ability to handle sensitive information confidentially. Desired personal traits include the ability to navigate through the emerging space, be a team player, able to work effectively and collaborate with colleagues across the firm.',
        Location: 'Singapore',
        Responsibilities: [
          'Actively approach and pitch existing and potential clients with emerging digital products that meet their investment requirements.',
          'Work closely with clients, to enhance working relationships and ensure the client’s needs are met.',
          'Work to broaden relationships with institutional investors in Singapore and elsewhere.',
          'Work closely with other departments on client-related matters and assist in any ad-hoc projects as required.',
          'Serve as the primary day to day contact for all related activities, including daily ad hoc enquiries and in-depth search of information regarding portfolios and operational related requests.',
          'Responding to any enquiries by clients and ensuring the quality and accuracy of both regular and ad hoc reports to be delivered to clients.',
          'Liaise and establish strong relationships with other departments for the on-boarding process of clients and ensure client reporting needs are met.',
          'Liaise and establish strong relationships with other departments for the on-boarding process of clients and ensure client reporting needs are met.',
          'Arrange and coordinate client portfolio meetings to review investment guidelines, objectives and processes.',
          'Ensure client documentation is updated accordingly and internal monitoring is feasible.',
        ],
        Requirements: [
          'A university degree or higher with 5-8 years of relevant experience.',
          'Proven track record in business development, sales and building a portfolio of clients within the Financial Institution industry.',
          'Bilingual, excellent oral and written communication skills.',
          'MAS representative license.',
          'Possess solid experience in buy & sell-side environment.',
          'Ability to build the trust and confidence of clients, ability to strengthen client relationships through ongoing dialogue and interactions, ability to work well with others to accomplish client objectives.',
          'Self-starter, resourceful, able to work independently and in a small team environment.',
          'Demonstrated history of being results-oriented, able to work under pressure, and organized.',
        ],
        tips: 'If you are looking to be part of an exciting team within the financial technology industry, we would like to hear from you. Remuneration will be commensurate with experience and qualifications.'
      }
    ],
  },
  {
    label: 'IT',
    info: [
      {
        isOpen: false,
        des: 'DIRECTOR, IT SECURITY',
        desDetail: 'Our ideal candidate plays a critical role in safeguarding the organization’s digital assets, information, and systems from security threats and breaches. This role involves developing, implementing, and overseeing security strategies, policies, and procedures to ensure the confidentiality, integrity, and availability of sensitive data and technology resources. He/She collaborates with various teams to assess risks, mitigate vulnerabilities, and respond to security incidents effectively.',
        Location: 'Singapore',
        Responsibilities: [
          'Lead and managed the team in implementation of IT security architecture and platforms in a cloud environment which may include access control solutions, identity and access management platforms, data protection technologies, anti-malware, vulnerability management, security monitoring and compliance tools.',
          'Lead the development and maintenance of enterprise IT security policies, standards, methodologies and best practices for security management to ensure IT systems are designed with due considerations for security.',
          'Lead and manage corporate IT compliance and IT risk assessments.',
          'Conduct regular security analysis such as penetration and platform security vulnerability scanning to prevent internal and external security incidents and attacks.',
          'Perform security review of cloud environment and IT systems to ensure compliance with MAS policies and standards.',
          'Lead in responses to law or regulatory bodies like MAS or PDPC with regards to cyber security controls.',
          'Lead in the responses to cyber incidents and assist in remediation efforts in a 24x7x365 mode of operation.',
          'Stay current on the latest threats and vulnerabilities to ensure the cyber defences and processes of the organisation are up to date.',
        ],
        Requirements: [
          'Degree or Diploma in Information Technology/Science/Computer Science/Engineering or related technical area of study.',
          'Minimum 7 years of working experience in cyber security and risks in cloud ecosystems like AWS.',
          'Minimum 3 years of practical experience in architecture, design, provision and administration of IT security devices and appliances.',
          'Strong technical skills, which may include experience with Linux and Windows operating systems, scripting languages like Python, and cloud ecosystems like AWS.',
          'Knowledge in networking protocols, IT security technologies will be highly regarded',
          'Strong understanding of financial services regulations and framework related to IT',
          'Strong leadership skills to guide the security team and collaborate across departments',
        ],
        tips: 'If you are looking to be part of an exciting team within the financial technology industry, we would like to hear from you. Remuneration will be commensurate with experience and qualifications.'
      }
    ],
  },
  {
    label: 'Legal & Compliance',
    info: [
      {
        isOpen: false,
        des: 'JUNIOR LEGAL COUNSEL',
        desDetail: 'Our ideal candidate will responsible for providing legal advice across various legal disciplines for the organizations. He/she will be in charge of overseeing and facilitating the negotiation of corporate and commercial agreements and managing and resolving any related legal matters.',
        Location: 'Hong Kong OR Beijing',
        Responsibilities: [
          'Work closely with the business and provide legal advice across a wide range of corporate, commercial, transactional, and regulatory matters to support the company’s operations, including fundraisings and other strategic transactions, corporate secretarial matters and IP matters',
          'Draft, review, negotiate and advise on a variety of commercial agreements, including service agreements, partnership/collaboration agreements, NDAs, and marketing agreements.',
          'Support structuring, legal and execution matters in relation to issuances on the platform.',
          'Conduct and/or coordinate legal due diligence on potential transactions.',
          'Co-ordinate and manage external legal counsel appointed by the company.',
          'Maintain the company’s regulatory and compliance documents which include KYC and AML, and other policies and procedures to ensure they are up-to-date and comply with applicable regulations.',
          'Ensure ongoing processes and controls are complete and accurate recordkeeping of all documentation and relevant communications with internal stakeholders, regulators, and clients.',
          'Partner with functional experts (i.e., Operation, Business, etc) to develop procedures, processes, and controls.',
          'Other ad hoc matters as and when required.',
        ],
        Requirements: [
          'Bachelor’s Degree in Law or equivalent from a reputable university',
          'Min 1 PQE qualified lawyer with transactional legal experience from a leading law practice in Hong Kong or China, preferably in the following practice areas: corporate/M&A or IP/TMT',
          'Direct deal experience in conducting diligence, drafting and negotiating transactional documents and advising business stakeholders',
          'Highly motivated self-starter and team-player, able and keen to work in a fast-paced and demanding, yet collaborative, environment',
          'Excellent at building rapport with different client types through effective communication and interpersonal skills',
          'Strong verbal, presentation, and written communication skills. Fluency in both Chinese and English is required as one is needed to liaise with Chinese speaking stakeholders',
          'A team player that collaborates well with other departments',
          'Training will be provided to the selected candidates',
        ],
        tips: 'If you are looking to be part of an engaging team in the new financial technology industry, we would like to hear from you. Remuneration will be commensurate with experience and qualifications.'
      },
      {
        isOpen: false,
        des: 'REGULATORY COMPLIANCE ANALYST',
        desDetail: 'Our ideal candidate will play a pivotal role in ensuring our company’s operations align with relevant laws, regulations, and industry best practices.',
        Location: 'Singapore',
        Responsibilities: [
          'Provide compliance advisory on the regulatory requirements in SFA and SFR to business units and participate in new initiatives/projects to understand business units’ concerns/needs,',
          'Provide accurate and practical guidance to address regulatory and industry requirements from a regulatory Compliance perspective;',
          'Provide oversight and support to the stakeholders on general compliance matters, including drafting of company’s policies and procedures in accordance with MAS regulations in the Capital Markets Products arena when required.',
          'Work with various stakeholders on regulatory compliance related matters, information requests and to answer the regulators’ queries promptly;',
          'Work with stakeholders to identify gaps, remediation actions and track action plans till closure in a timely manner;',
          'Provide oversight, advice in the regulatory compliance space in the Capital Market Service (CMS) license regime and mandatory training to stakeholders on key regulatory requirements such as FATCA/CRS, SFA04N02, and any new changes or updates to the regulations in the Capital Markets Products (“CMP”) regime especially regulatory changes pertaining to SFA and SFR.',
          'Takes accountability to consider the business and regulatory compliance risks involved and perform the appropriate steps to mitigate the risks.',
          'Maintains awareness of industrial trends on regulatory compliance in Capital Markets space, related emerging threats and technologies so as to understand the risk and  safeguard the company better.',
          'Carry out ad-hoc tasks/projects as required by the immediate Supervisor..',
        ],
        Requirements: [
          'Bachelor Degree, preferably in Business, or Finance related fields;',
          'Minimum 2-4 years working experience in compliance/internal audit in the financial services industry;',
          'Sound knowledge of regulatory and industry requirements relating to financial industry such as MAS Notices/guidelines, Securities and Futures Act, Securities and Futures Regulations, and regulations from other jurisdictions, etc.',
          'Self-motivated and driven, able to work independently;',
          'Meticulous, good analytical and problem-solving skills;',
          'Good communication, report writing and interpersonal skills;',
          'Able to multi-task and work under pressure;',
          'High level of integrity, takes accountability of work and good attitude;',
          'Able to collaborate and work well with others;',
          'Takes initiative to improve current state of things and adaptable to embrace new changes.',
        ],
        tips: 'If you are excited about the opportunity to ensure regulatory compliance within a thriving Fintech company, we would like to hear from you. Remuneration will be commensurate with experience and qualifications.'
      }
    ],
  },
  {
    label: 'Marketing & Communications',
    info: [
      {
        isOpen: false,
        des: 'VICE PRESIDENT, MARKETING & COMMUNICATIONS',
        desDetail: 'Our ideal candidate will be responsible for developing and executing comprehensive marketing and communication strategies to promote the company’s brand, products, and services in the Asia Pacific markets. This leadership role requires a creative and strategic thinker who can effectively manage a team, collaborate with cross-functional departments, and drive measurable results. He/She will play a pivotal role in shaping our brand image, enhancing customer engagement, and driving strategic communication initiatives.',
        Location: 'Hong Kong',
        Responsibilities: [
          'Develop and implement integrated marketing communication strategies aligned with the company’s goals and objectives.',
          'Collaborate with senior management to define brand positioning and messaging for the Asia Pacific markets.',
          'Identify market trends, consumer insights, and competitive landscape to inform marketing strategies.',
          'Oversee the development of brand guidelines and ensure consistent brand representation across all marketing materials and channels.',
          'Manage and protect the company’s brand reputation, addressing any issues that may arise.',
          'Lead digital marketing efforts, including social media, content marketing, SEO/SEM, email campaigns, and online advertising.',
          'Monitor digital trends and technologies to stay ahead of the curve in the digital landscape.',
          'Build and maintain relationships with media outlets, influencers, and industry partners to secure positive media coverage.',
          'Develop and distribute press releases and media kits as needed.',
          'Plan and execute advertising campaigns across various media channels, ensuring optimal reach and impact.',
          'Lead, mentor, and manage a high-performing marketing communications team, fostering a collaborative and innovative work environment.',
          'Provide guidance and professional development opportunities to team members.',
          'Develop and manage the marketing communications budget, ensuring efficient allocation of resources for maximum ROI.',
          'Represent MVGX Holdings and/or Executives while networking at industry events and conferences.',
        ],
        Requirements: [
          'Bachelor’s degree in Marketing, Communications, Business, or a related field; Master’s degree is a plus.',
          'Min 10 years of experience in marketing communications, with 5 years in a leadership role.',
          'Proven track record of developing and executing successful marketing campaigns.',
          'Strong understanding of digital marketing strategies and tools.',
          'Excellent written and verbal communication skills in English and Chinese',
          'Creative thinker with the ability to translate ideas into compelling marketing materials.',
          'Strong leadership and team management skills.',
          'Proficiency in marketing analytics and data-driven decision-making.',
          'Ability to thrive in a fast-paced, dynamic environment.',
        ],
        tips: 'If you’re a strategic thinker with a passion for marketing innovation within the financial technology industry, we invite you to apply and contribute to our continued success. Remuneration will be commensurate with experience and qualifications.'
      }
    ]
  },
  {
    label: 'Operations & Client Services',
    info: [
      {
        isOpen: false,
        des: 'CLIENT SUPPORT MANAGER',
        desDetail: 'Our ideal candidate will ensure a positive and productive relationship between the company and its clients. This role encompasses various responsibilities that bridge the gap between clients and the organization, ensuring effective communication, problem resolution, and overall client satisfaction. He/She will act as a liaison between the client and different internal teams, working to address client needs and concerns.',
        Location: 'Malaysia',
        Responsibilities: [
          'Serve as the primary point of contact for end-users seeking technical assistance regarding software applications/systems, responding promptly to inquiries via phone, email, or in-person.',
          'Guiding users through step-by-step solutions or escalating issues to appropriate internal teams if necessary.',
          'Provide hands-on training to users, both one-on-one and in group settings, to enhance their understanding of software/system functionality and usage.',
          'Collaborate with software developers and other IT team members to identify recurring user issues and contribute to the development of user-friendly solutions.',
          'Assist in the testing and deployment of software/system updates and patches, ensuring minimal disruption to user workflows.',
          'Continuously monitor and assess user satisfaction levels, identifying opportunities to improve the support process and enhance user experiences.',
          'Collaborate with other IT team members on projects and initiatives, contributing your expertise in user support to ensure successful project outcomes.',
        ],
        Requirements: [
          'Bachelor’s degree in Business, Information Technology, Computer Science, or a related field.',
          'At least 3 to 5 years of hands-on experience in operations support, preferably focusing on IT software applications/systems or shared services.',
          'Experience in IT, software companies will be advantageous.',
          'Strong interpersonal skills and the ability to communicate complex technical and business concepts to end users effectively.',
          'Managerial or supervisory experience will be an advantage to guide junior team members as the team grows.',
          'Experience in providing one-on-one and group training sessions.',
          'Attention to detail and excellent organizational skills.',
          'Proven ability to work independently and within a team environment.',
          'Adaptable to shifting priorities and evolving user needs.',
          'Familiarity with Microsoft Office Suite',
          'Proficiency in English, Mandarin and Bahasa Melayu is required for communication with various stakeholders.',
        ],
        tips: 'If you are looking to be part of an engaging team in the new financial technology industry, we would like to hear from you. Remuneration will be commensurate with experience and qualifications.'
      }
    ]
  },
];

function Cell({cell}) {
  const [open, setOpen] = useState(null);
  const showAll = () => {
    setOpen(!open)
    console.log()
  }
  useEffect(() => {

  }, []);
  return(
    <section>
          <div>
            <div className='des'>{cell.des} <RightOutlined onClick={() => showAll(cell)} className={open ? 'show-all show-icon' : 'show-icon'}/> </div>
            <div className={open ? 'content all-content' : 'content'}>
              <p>{cell.desDetail}</p>
              <div className='bold-text'>Location: {cell.Location}</div>
              <div className='bold-text'>Roles and Responsibilities: </div>
              <ul>
                {
                  cell.Responsibilities.map((res, ind) => (
                    <li key={ind}>{res}</li>
                  ))
                }
              </ul>
              <div className='bold-text'>Requirements:</div>
              <ul>
                {
                  cell.Requirements.map((req, ins) => (
                    <li key={ins}>{req}</li>
                  ))
                }
              </ul>
              <div>{cell.tips}</div>
              <div className='contact'>Interested candidates are requested to write in with your detailed resume including current and expected remuneration, and contact numbers to <span>corporateoffice@mvgx.com</span></div>
              <div>We regret that only shortlisted candidates will be notified.</div>
            </div>
            <hr/>
          </div>
    </section>
  )
}

function Jobs() {
  return (
    <div className='jobs'>
      <h1>Jobs</h1>
      {
        items.map((item, index) => (
          <div className='list-cell' key={index}>
            <h2>{item.label}</h2>
            {
              item.info.map((cell, inx) => (
                <Cell key={inx} cell={cell}/>
              ))
            }
          </div>
        ))
      }
    </div>
  );
}

export default Jobs;