import React, {useEffect, useState} from "react";
import './PressRelease.scss'
import Waterfall from './Waterfall';
import {ConfigProvider, Pagination} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

const dataList = [
  {
    title: 'Singapore-Based Digital Asset Platform MetaComp Appoints Financial Services Veteran John Kang as Chief Financial Officer',
    time: 'February 27, 2023',
    content: 'SINGAPORE — 27 February 2023 — MetaComp Pte. Ltd. (f.k.a. Cyberdyne Tech Services Pte. Ltd), a Major Payment...',
    type: '1',
    img: require('./image/press1.jpg')
  },
  {
    title: 'Sustainability Leader TÜV SÜD Partners with MVGX, Launches Holistic Carbon Neutrality Rating System for Enterprises',
    time: 'November 28, 2022',
    content: 'The Carbon Neutrality Rating System serves to be a vital tool for enterprises to assess their carbon output...',
    type: '2',
    img: require('./image/press-2.jpg')
  },
  {
    title: 'MVGX Collaborates with Chainlink to Connect Web2 and Web3 Carbon Markets',
    time: 'November 3, 2022',
    content: 'Chainlink Labs and Singapore-based MVGX Set the Foundation for Strong Future Collaboration SINGAPORE, November 3, 2022...',
    type: '3',
    img: require('./image/press-3.png')
  },
  {
    title: 'Customizable Net Zero Card Puts Power in the Hands of Businesses to Counter Micro-Level Emissions Such as Business Travel',
    time: 'June 16, 2022',
    content: 'Physical card pre-paid with blockchain-based carbon credits allows businesses to instantly mitigate hard-to-manage...',
    type: '4',
    img: require('./image/press-4.jpg')
  },
  {
    title: 'Former Bank of England Senior Advisor Spearheads MetaVerse Green Exchange’s Carbon Advisory, Sales, and Trading Offerings',
    time: 'July 21, 2022',
    content: 'MetaVerse Green Exchange (“MVGX”), a digital green exchange licensed and regulated by the Monetary Authority of...',
    type: '5',
    img: require('./image/press-5.jpeg')
  },
  {
    title: 'Indonesia Stock Exchange Signs MoU with Singapore-based Green Fintech MVGX to Explore Opportunities in Developing Carbon Markets',
    time: 'November 2, 2022',
    content: 'IDX is preparing a trading system for a carbon exchange in Indonesia MVGX to provide IDX with opportunities to explore...',
    type: '6',
    img: require('./image/press-6.png')
  },
  {
    title: 'China’s Greenland Financial Technology and Singapore’s MetaVerse Green Exchange Form Strategic Partnership on Cross-Border Digital Carbon Credits and Crypto Assets',
    time: 'March 28, 2022',
    content: 'Driven by increasing investor desire for crypto assets and cross-border digital carbon credits on regulated and...',
    type: '7',
    img: require('./image/press-7.jpeg')
  },
  {
    title: 'OCBC Bank and MetaVerse Green Exchange to Develop New Green Financing Solutions with Tokenised Carbon Credits',
    time: 'April 25, 2022',
    content: 'This partnership between the two Singapore firms aims to help accelerate corporates’ journey to carbon neutrality...',
    type: '8',
    img: require('./image/press-8.jpg')
  },
  {
    title: 'Sustainability-Focused AI Pioneer Red Dot Analytics Partners with MetaVerse Green Exchange to Power Carbon Neutral Data Center Operations',
    time: 'May 11, 2022',
    content: 'Red Dot Analytics and MetaVerse Green Exchange set to introduce carbon neutral data center operations, backed by AI and...',
    type: '9',
    img: require('./image/press-9.jpg')
  },
  {
    title: 'First Ever Tokenized Carbon Neutral Certification Completed by MetaVerse Green Exchange and BSI',
    time: 'March 16, 2022',
    content: 'Sets gold standard for carbon neutrality reporting by combining blockchain technology with BSI certificationProof-of...',
    type: '10',
    img: require('./image/press-10.jpg')
  },
]

const data2 = [
  {
    title: 'MetaVerse Green Exchange (MVGX) appoints Eddie Hui as COO ',
    time: 'March 7, 2022',
    content: 'MVGX demonstrates momentum in Q1 with expansion of leadership team and product offering MetaVerse Green Exchange...',
    type: '11',
    img: require('./image/press-11.jpg')
  },{
    title: 'MetaVerse Green Exchange develops further applications of its proprietary Non-Fungible Digital Twin (NFDT™) technology',
    time: 'February 22, 2022',
    content: 'Singapore, 22 February 2022 – MetaVerse Green Exchange (MVGX, formerly Cyberdyne Tech Exchange) is announcing the...',
    type: '12',
    img: require('./image/press-12.jpg')
  },{
    title: 'Cyberdyne Tech Exchange (CTX) becomes MetaVerse Green Exchange (MVGX) in Global rebrand',
    time: 'February 11, 2022',
    content: 'Cyberdyne Tech Exchange (CTX), the leading green digital asset marketplace, has today announced that the company...',
    type: '13',
    img: require('./image/press-13.png')
  },{
    title: 'CTX Cooperates with BSI on Green Finance and Carbon Neutrality Standards',
    time: 'November 25, 2021',
    content: 'Cyberdyne Tech Exchange Pte., Ltd (“CTX”) and BSI China (a group company of the British Standards...',
    type: '14',
    img: require('./image/press-14.jpeg')
  },{
    title: 'Cyberdyne Tech Exchange resolves COP challenge through release of Carbon Neutrality Token (CNT)',
    time: 'September 27, 2021',
    content: 'CNT’s underpinning technology to unleash global carbon trading markets The Cyberdyne Tech Exchange (CTX) has released...',
    type: '15',
    img: require('./image/press-15.png')
  },{
    title: 'China Taiping and CTX sign MoU promoting green assets',
    time: 'July 6, 2021',
    content: 'China Taiping Insurance Singapore Pte. Ltd. (“CTPIS”) and Cyberdyne Tech Exchange Pte. Ltd. (“CTX”) have announced a...',
    type: '16',
    img: require('./image/press-16.jpg')
  },{
    title: 'CTX Announces Kelvin Wong, former Deputy Country Head of Lloyds Bank (Singapore), has joined as Chief Compliance Officer',
    time: 'June 15, 2021',
    content: 'Cyberdyne Tech Exchange (“CTX”), a regulated digital green exchange headquartered in Singapore, today announced that...',
    type: '17',
    img: require('./image/press-17.jpg')
  },
  {
    title: 'Cyberdyne Tech Exchange (CTX) Executive Chairman, Dr Bo Bai, receives Entrepreneur 100 Awards accolade',
    time: 'December 3, 2021',
    content: 'Dr Bo Bai, Executive Chairman ofCyberdyne Tech Exchange (CTX), a leading green digital asset marketplace and the...',
    type: '18',
    img: require('./image/press-18.jpg')
  },
]

function PressRelease() {
  const [curData, setCurData] = useState(dataList);
  const page = useSelector((state) => state.pageStore.page);

  const dispatch = useDispatch();
  const pageChange = (payload) => {
    if (payload === 1) {
      dispatch({type: 'SET', payload: 1})
      setCurData(dataList)
    } else {
      dispatch({type: 'SET', payload: 2})
      setCurData(data2)
    }
  }
  useEffect(() => {
    if (page === 1) {
    setCurData(dataList)
    } else {
      setCurData(data2)
    }
  }, [page])
  return (
    <div className='press-release'>
      <h1>Press release</h1>
      <Waterfall items={curData} />
      <ConfigProvider
        theme={{
          components: {
            Pagination: {
              colorPrimary: 'rgba(2, 66, 50, 0.8)',
              algorithm: true, // 启用算法
            },
          },
        }}>
        <Pagination defaultCurrent={page} total={18} onChange={pageChange} />
      </ConfigProvider>
    </div>
  )
}

export default PressRelease;
