/*
 * @Author: liuxiaocong 
 * @Date: 2023-11-02 16:21:59 
 * @Last Modified by: liuxiaocong
 * @Last Modified time: 2023-11-03 14:50:30
 */
import React, {useEffect, useRef, useState} from 'react'
import './ProductsAndServices.scss'
import Banner from "./Banner";

const menuLinkList = [
    {
        name:'Carbon Management System',
        url:"/s",
    },
    {
        name:'Carbon Rating System',
        url:"/x",
    },
    {
        name:'Carbon Credit Development System',
        url:"/2",
    },
    {
        name:'Carbon Credit Registry System',
        url:"/w",
    },
    {
        name:'Carbon Credit Trading System',
        url:"/2",
    }
]
const productsServicesList = 
    {
        title:'Products and Services',
        description:"MVGX Tech’s Carbon Connect Suite is powered by proprietary blockchain-enabled technologies including its Non-Fungible Digital Twin (NFDT®) and Digital Carbon Credit (CNT®). Its five products provide end-to-end support for organisations to robustly address their carbon challenges and secure the economic opportunities of new green markets.",
        description2:'Wherever you are in your journey, ',
        spec:'contact us',
        description3:' to learn more about how we can support you.',
        contentList :[
            {
                title:"Carbon Management System",
                desc:'Enables organisations to manage their carbon emissions and environmental impact, comprising of the following modules: Carbon Footprint Calculation, Carbon Footprint Reporting, Carbon Footprint Certification, Carbon Credit Asset Management, Carbon Neutrality Verification, CBAM Verification and ISSB Verification.',
                img:require('../image/productsServices/carbonManagement.png'),
            },
            {
                title:'Carbon Rating System',
                desc:'A comprehensive toolset that assesses and benchmarks an organisation’s carbon reduction, comprising: Decarbonisation Rating, Carbon Neutrality Quality Rating, and Carbon Credit Rating.',
                img:require('../image/productsServices/carbonRating.png')
            },
            {
                title:'Carbon Credit Development System',
                desc:'Streamlines the process of creating and issuing carbon credits, enhancing transparency and reliability in carbon markets worldwide and comprises: Carbon Project Development and Carbon Credit Issuance.',
                img:require('../image/productsServices/carbonCreditDevelopment.png')
            },
            {
                title:'Carbon Credit Registry System',
                desc:'A critical foundation for national or sub-national governments looking to develop the capabilities required to develop their own carbon markets to meet their Net-Zero goals.',
                img:require('../image/productsServices/carbonCreditRegistry.png')
            },
            {
                title:'Carbon Credit Trading System',
                desc:'A robust foundational trading infrastructure to power efficient and transparent carbon credit trading comprising the following modules: Carbon Credit Listing, Carbon Credit Marketplace, Carbon Credit Auction, Carbon Credit Negotiated Trading, and Carbon Credit Regular Trading.',
                img:require('../image/productsServices/carbonCreditTrading.png')
            },
        ]
    }

function LiItem ({data}){
    return (
        <li className='link-item'>
            {data.name}
        </li>
    )
}

function ProductCell({data}) {
    const targetRef = useRef(null);
    const [distanceToBottom, setDistanceToBottom] = useState(null);
    const [moveClass, setMoveClass] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (targetRef.current) {
                const rect = targetRef.current.getBoundingClientRect();
                const distance = window.innerHeight - rect.bottom + rect.height;
                setDistanceToBottom(distance)
                if (distance > -10) {
                    setMoveClass('cell-move');
                } else {
                    setMoveClass('');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const name = `${moveClass} product-item`

    return (
      <div className={name} ref={targetRef} data-dis={distanceToBottom}>
          <div className='text-con'>
              <div className='con'>
                  <h4 className='title'>{data.title}</h4>
                  <p className='desc'>{data.desc}</p>
              </div>
          </div>
          <div className='img-box'>
              <img src={data.img} alt="name"/>
          </div>
      </div>
    )
}

function ProductsAndServices () {
    function mailTo() {
        const email = 'contact@mvgx.com';

        window.location.href = `mailto:${email}`;
    }
    return (
        <div className='products-services-box'>
            <div className='banner-box'>
                <Banner/>
            </div>
            <div className='carbon-conect-suite'>
                <h2 className="title">Carbon Connect Suite</h2>
                <p className="description">We provide end-to-end climate SaaS solutions for governments and enterprises to tackle real economy issues in their net zero transition</p>
            </div>
            <div className='color-block'>
                <div className='block-item'>
                    <span className='left'>Products</span>
                    <ul className='con link-list'>
                        {menuLinkList.map((item, index) => (                
                            <LiItem data={item} key={index} />
                        ))}
                    </ul>
                </div>
                <div className='block-item'>
                    <span className='left'>Target <br />Clients</span>
                    <div className='con targetClient'>
                        <div className='text-con'>
                            <p>Governments & Corporations</p>
                        </div>
                    </div>
                </div>
                <div className='block-item'>
                    <span className='left'>MVGX Tech Professional Servicesand Support</span>  
                    <div className='con mvgxtech'>
                        <div className='text-con'>
                            <p>Software Development and Solutioning, ESG and blockchain consultancy,</p>
                            <p>Multi-Cloud Hosting, On-premise Deployment and supportt</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gray-block'>
                <div className='title-box'>
                    <h2 className='title'>{productsServicesList.title}</h2>
                    <p className='desc'>{productsServicesList.description}</p>
                    <p className='desc'>{productsServicesList.description2}<strong>{productsServicesList.spec}</strong>{productsServicesList.description3}</p>
                </div>
                <div className='products-box'>
                    {productsServicesList.contentList.map((item,index) => (
                      <ProductCell data={item} key={index}/>
                    ))}
                </div>
            </div>

            <div className='contact-us'>
                <h3>Contact Us Today</h3>
                <p>Connect with us by emailing us at </p>
                <button onClick={() => mailTo()}>Email</button>
            </div>
        </div>
    )
}
export default ProductsAndServices