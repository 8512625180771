import React, { useState, useEffect } from 'react';
import './Home.scss'
// import Banner from './Banner/Banner'
// import Feature from "./Feature/Feature";
// import Trust from "./Trust/Trust"
import Presentation from "./Presentation/Presentation"
import Book from "./Book/Book"

function Home() {
  const [distanceToBottom, setDistanceToBottom] = useState(0);

  // 创建一个滚动事件处理函数
  const handleScroll = () => {
    // 获取页面高度
    const pageHeight = document.documentElement.scrollHeight;
    // 获取窗口视口高度
    const windowHeight = window.innerHeight;
    // 获取当前滚动位置
    const scrollPosition = window.scrollY;
    // 计算滚动条距离底部的距离
    const distance = pageHeight - (windowHeight + scrollPosition);
    setDistanceToBottom(distance);
  };

  useEffect(() => {
    document.title = 'Most Valuable Green eXpert';
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.title = 'Most Valuable Green eXpert';
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className='home-page'>
      <div className='bg'>
        <img style={{opacity: distanceToBottom > 300 ? '1' : '0'}} className='shadow-img' src={require('../image/shadow.png')} alt="shadow"/>
        <Book />
        <Presentation />
      </div>
      {/*<Banner />*/}
      {/*<Feature />*/}
      {/*<Trust />*/}
    </div>
  );
}

export default Home;