import React from "react";
import './ComeSoon.scss'

function ComeSoon() {
  return (
    <div className='come-soon'>
      <div>
        <img src={require('../image/Launching a project.png')} alt="come soon"/>
        <p>Coming Soon</p>
      </div>
    </div>
  )
}

export default ComeSoon