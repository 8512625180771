import React from "react";
import './CNCarbonReport.scss'
import { RightOutlined } from "@ant-design/icons";
import { useNavigate  } from 'react-router-dom';

const dataList = [
  {
    title: 'China Carbon Monthly Market Update – April 2023',
    time: 'April 2023',
    content: 'Monthly carbon market update (April 2023) Trading in China’s national carbon emissions allowance (CEA) market totaled 1.05 million tons in April, a decrease of nearly 20% from March. Block trades accounted for 95% of April’s total volume.  There was not any noteworthy policy news during the month. While the formal passage of the European Union’s Carbon Border Adjustment Mechanism (CBAM) on the 25th grabbed the attention of many market participants, it understandably did not spark any real...'
  }
]

function CNCarbonReport() {
  const navigate = useNavigate();
  const toDetail = (data) => {
    navigate('/CNCarbon-report-detail', {
      state: {
        data: data
      }
    })
  }
  const iconStyle = {
    fontWeight: 700
  }
  return (
    <div className='carbon-report'>
      <h1>China Carbon Reports</h1>
      {dataList.map((item, index) => (
        <section className='list-cell' key={index}>
          <h2>{item.title}</h2>
          <div className='time'>{item.time}</div>
          <hr/>
          <p>{item.content}</p>
          <span onClick={() => toDetail(item)}>Download Full Report <RightOutlined style={iconStyle}/></span>
        </section>
      ))}
    </div>
  )
}

export default CNCarbonReport;