import React from "react";
import { useLocation  } from 'react-router-dom';
import './CNCarbonReport.scss'

const dataList = [
  'Activity in China’s national Carbon Emissions Allowance (CEA) market dropped nearly 60% from the 2023 high set in the previous week, ' +
  'closing the week with 5.9 million tons of total volume. Block trades accounted for all of the decrease. At the same time, however, ' +
  'open transactions increased substantially. Among the 5.9 million tons of weekly total volume, 0.7 million came from the 2019-2020 vintage,' +
  ' 2.6 million from the 2021 vintage, and 2.6 million from the 2022 vintage. The 2021 vintage saw the biggest decline in volume among the three vintages.',

  'Every market price indicator climbed to its all-time high during the week, despite the steep drop in total volume. ' +
  'The closing price of open market transactions for the week was 74.76 yuan ($10.41) per ton, 3.5% higher than the previous week. ' +
  'The volume-weighted average price for all of the week’s trades was 70.65 yuan ($9.84) per ton, breaking the 70-yuan mark for the first time.'
]

function CNCarbonReportDetail() {
  const location =useLocation();
  return (
    <div className='report-detail'>
      <h1>{location.state.data.title}</h1>
      <p className='glance'><strong>Week at a glance{location.state.data.time}</strong></p>
      <section>
        <ul>
          {dataList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>
    </div>
  )
}

export default CNCarbonReportDetail;